.hero-section {
  min-height:120px;
  margin-top:0;
  padding-top:38px!important; padding-bottom:42px!important;
  background-color:color(main);
  background-position:center top; background-repeat:no-repeat; background-size:cover;

  @include media-breakpoint-up(xs-375) {
    min-height:calc(120px + 20 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(lg) {
    padding-top:25px!important;
  }
  @include media-breakpoint-up(xl-1440) {
    min-height:140px;
  }

  &.pb-more {
    padding-bottom:62px!important;

    @include media-breakpoint-up(xs-375) {
      padding-bottom:calc(62px + 80 * ((100vw - 375px) / 1065))!important;
    }
    @include media-breakpoint-up(xl-1440) {
      padding-bottom:142px!important;
    }
  }

  .container {
    position:relative;
    z-index:2;
  }
  .hero-color {
    position:absolute; right:-150px; bottom:-430px;
    width:335px;
    @include prefix(transform, rotate(18deg), webkit moz o ms);
    z-index:1;

    @include media-breakpoint-up(xs-375) {
      bottom:calc(-410px + 360 * ((100vw - 375px) / 1065));
      width:calc(335px + 300 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(lg) {
      right:-320px;
      @include prefix(transform, rotate(22deg), webkit moz o ms);
    }
    @include media-breakpoint-up(xl-1440) {
      right:calc(-320px + 180 * ((100vw - 1440px) / 480)); bottom:-50px;
      width:635px;
    }
    @include media-breakpoint-up(xxl-1920) {
      right:-140px;
    }
  }
  
  h1, .h1 {
    @include media-breakpoint-up(xl) {
      padding-left:49px;
    }
  }

  p {
    &.abstract {
      min-height:52px;
      margin-top:18px;
      font-size:15px; line-height:26px; letter-spacing:0.85px; font-weight:600; color:color(white);

      @include media-breakpoint-up(xs-375) {
        min-height:calc(52px + 4 * ((100vw - 375px) / 1065));
        margin-top:calc(18px + 8 * ((100vw - 375px) / 1065));
        font-size:calc(15px + 2 * ((100vw - 375px) / 1065)); line-height:calc(26px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl) {
        padding-left:49px;
      }
      @include media-breakpoint-up(xl-1440) {
        min-height:56px;
        margin-top:26px;
        font-size:17px; line-height:28px;
      }

      &.big {
        margin-top:22px; padding-left:0;
        font-size:17px; line-height:27px; letter-spacing:1.2px; font-weight:400;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(22px + 6 * ((100vw - 375px) / 1065));
          font-size:calc(17px + 7 * ((100vw - 375px) / 1065)); line-height:calc(27px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:28px;
          font-size:24px; line-height:35px;
        }
      }
      &.indent {
        position:relative;

        @include media-breakpoint-up(lg) {
          padding-left:12.5%;
        }

        .line {
          position:absolute; right:inherit; top:-415px; left:calc(100% + 18px);
          display:block;
          width:804px;

          @include media-breakpoint-up(lg) {
            right:calc(87.5% + 24px); top:50%; left:inherit;
          }
        }
      }
    }
  }

  &.img-bkg {
    position:relative;
    min-height:288px;
    padding-bottom:74px!important;

    @include media-breakpoint-up(xs-375) {
      min-height:calc(288px + 112 * ((100vw - 375px) / 1065));
      padding-bottom:calc(74px + 24 * ((100vw - 375px) / 1065))!important;
    }
    @include media-breakpoint-up(xl-1440) {
      min-height:400px;
      padding-bottom:98px!important;
    }

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      background:rgb(0,0,0);
      background:linear-gradient(87deg, rgba(0,0,0,0.6) 14.35%, rgba(0,0,0,0.25) 77.4%);
      z-index:1;
    }

    .breadcrumb, .btn-back {
      margin-bottom:70px;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(70px + 50 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:120px;
      }
    }

    &.no-content {
      min-height:260px;

      @include media-breakpoint-up(xs-375) {
        min-height:calc(260px + 90 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        min-height:350px;
      }
      @include media-breakpoint-up(xl-1441) {
        min-height:380px;
      }

      &:before {
        content:none;
      }
    }

    &.high {
      min-height:300px;
      padding-bottom:96px!important;

      @include media-breakpoint-up(xs-375) {
        min-height:calc(300px + 130 * ((100vw - 375px) / 1065));
        padding-bottom:calc(96px + 32 * ((100vw - 375px) / 1065))!important;
      }
      @include media-breakpoint-up(xl-1440) {
        min-height:430px;
        padding-bottom:128px!important;
      }
    }
  }

  &.detail {
    padding-top:0!important; padding-bottom:42px!important;

    @include media-breakpoint-up(xs-375) {
      padding-bottom:calc(42px + 18 * ((100vw - 375px) / 1065))!important;
    }
    @include media-breakpoint-up(xl-1440) {
      padding-bottom:60px!important;
    }

    > .image {
      position:relative;
      width:calc(100% + 16px); height:160px;
      margin-left:-8px; margin-right:-8px; margin-bottom:24px;
      padding-left:8px; padding-right:8px;
      background-position:center top; background-repeat:no-repeat; background-size:cover;

      @include media-breakpoint-up(xs-375) {
        height:calc(160px + 240 * ((100vw - 375px) / 1065));
        margin-bottom:calc(24px + 16 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        height:400px;
        margin-bottom:40px;
      }
      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        background:rgb(0,0,0);
        background:linear-gradient(87deg, rgba(0,0,0,0.6) 14.35%, rgba(0,0,0,0.25) 77.4%);
        z-index:1;
      }

      > .container {
        position:relative;
        padding-top:24px;
        z-index:2;

        @include media-breakpoint-up(xs-375) {
          padding-top:calc(24px + 1 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-top:25px;
        }
      }
    }

    .label {
      position:relative;
      width:auto;
      padding:6px 0;
      margin-bottom:10px; margin-top:0;
      font-size:11px; line-height:15px; color:color(white); letter-spacing:1.1px; font-weight:700; text-transform:uppercase;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(10px + 3 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl) {
        padding-left:49px;
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:13px;
      }
    }
    .reading-time {
      position:relative;
      width:auto;
      padding-left:20px; margin-top:12px;
      font-size:10px; line-height:18px; color:color(white); letter-spacing:1.2px; font-weight:500; text-transform:uppercase;

      @include media-breakpoint-up(xs-375) {
        padding-left:calc(20px + 5 * ((100vw - 375px) / 1065));
        font-size:calc(10px + 2 * ((100vw - 375px) / 1065)); line-height:calc(18px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(lg) {
        margin-top:0; margin-bottom:-15px;
      }
      @include media-breakpoint-up(xl-1440) {
        padding-left:25px;
        font-size:12px; line-height:20px;
      }

      &:before {
        content:$icon-ibsa-time;
        position:absolute; left:0; top:3px;
        width:12px; height:12px;
        font-size:12px; line-height:12px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;

        @include media-breakpoint-up(xs-375) {
          top:calc(3px - 1 * ((100vw - 375px) / 1065));
          width:calc(12px + 3 * ((100vw - 375px) / 1065)); height:calc(12px + 3 * ((100vw - 375px) / 1065));
          font-size:calc(12px + 3 * ((100vw - 375px) / 1065)); line-height:calc(12px + 3 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          top:2px;
          width:15px; height:15px;
          font-size:15px; line-height:15px;
        }
      }

      br {
        display:none;

        @include media-breakpoint-up(lg) {
          display:block;
        }
      }
    }
    time {
      position:relative;
      width:auto;
      padding:6px 0;
      font-size:12px; line-height:15px; color:color(white); letter-spacing:1.2px; font-weight:500;
    }

    &.no-content {
      > .image {
        &:before {
          content:none;
        }
      }
    }
  }
}

.breadcrumb {
  display:none; float:left;
  width:100%;
  margin-bottom:40px;

  @include media-breakpoint-up(md) {
    display:block;
    margin-bottom:calc(40px + 10 * ((100vw - 768px) / 672));
  }
  @include media-breakpoint-up(xl) {
    padding-left:49px;
  }
  @include media-breakpoint-up(xl-1440) {
    margin-bottom:50px;
  }

  ul {
    li {
      position:relative;
      width:auto;
      padding-right:15px; margin-right:10px;

      @include media-breakpoint-up(md) {
        padding-right:calc(15px + 3 * ((100vw - 768px) / 672)); margin-right:calc(10px + 3 * ((100vw - 768px) / 672));
      }
      @include media-breakpoint-up(xl-1440) {
        padding-right:18px; margin-right:13px;
      }

      &:after {
        content:$icon-ibsa-breadcrumb-arrow;
        position:absolute; right:0; top:4px;
        width:10px; height:10px;
        font-size:10px; line-height:10px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;

        @include media-breakpoint-up(md) {
          top:calc(4px - 1 * ((100vw - 768px) / 672));
          width:calc(7px + 3 * ((100vw - 768px) / 672)); height:calc(7px + 3 * ((100vw - 768px) / 672));
          font-size:calc(7px + 3 * ((100vw - 768px) / 672)); line-height:calc(7px + 3 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
          top:3px;
          width:10px; height:10px;
          font-size:10px; line-height:10px;
        }
      }
      &:last-child {
        padding-right:0; margin-right:0;

        &:after {
          content:none;
        }
      }

      a, span {
        display:block; float:left;
        width:auto;
        font-size:12px; line-height:15px; color:color(white); white-space:600;
        opacity:1;
      }
      a {
        font-weight:500;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          &:hover {
            opacity:0.7!important;
          }
        }
      }
      span {
        font-weight:700;
      }
    }
  }
}

.hero-image {
  float:left;
  width:100%; max-width:320px;
  margin-right:18%; margin-top:24px; margin-bottom:-64px;

  @include media-breakpoint-up(md) {
    float:right;
    width:100%;
    padding-left:38px; margin-top:calc(-64px - 60 * ((100vw - 768px) / 672)); margin-bottom:-64px; margin-right:inherit;
  }
  @include media-breakpoint-up(lg) {
    padding-left:calc(28px + 20 * ((100vw - 992px) / 448)); margin-top:-44px; margin-bottom:-64px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom:calc(-64px - 70 * ((100vw - 1200px) / 240));
  }
  @include media-breakpoint-up(xl-1440) {
    padding-left:48px; margin-bottom:-134px;
  }
}

.intro-section {
  margin-top:36px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(36px + 14 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:50px;
  }

  .breadcrumb {
    display:none; float:left;
    width:100%;
    margin-bottom:26px; padding-left:0;

    @include media-breakpoint-up(md) {
      display:block;
      margin-bottom:calc(26px + 10 * ((100vw - 768px) / 672));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:36px;
    }

    ul {
      li {
        position:relative;
        width:auto;
        padding-right:15px; margin-right:10px;

        @include media-breakpoint-up(md) {
          padding-right:calc(15px + 3 * ((100vw - 768px) / 672)); margin-right:calc(10px + 3 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-right:18px; margin-right:13px;
        }

        &:after {
          content:$icon-ibsa-breadcrumb-arrow;
          position:absolute; right:0; top:4px;
          width:10px; height:10px;
          font-size:10px; line-height:10px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;

          @include media-breakpoint-up(md) {
            top:calc(4px - 1 * ((100vw - 768px) / 672));
            width:calc(7px + 3 * ((100vw - 768px) / 672)); height:calc(7px + 3 * ((100vw - 768px) / 672));
            font-size:calc(7px + 3 * ((100vw - 768px) / 672)); line-height:calc(7px + 3 * ((100vw - 768px) / 672));
          }
          @include media-breakpoint-up(xl-1440) {
            top:3px;
            width:10px; height:10px;
            font-size:10px; line-height:10px;
          }
        }
        &:last-child {
          padding-right:0; margin-right:0;

          &:after {
            content:none;
          }
        }

        a, span {
          display:block; float:left;
          width:auto;
          font-size:12px; line-height:15px; color:color(main); white-space:600;
          opacity:1;
        }
        a {
          font-weight:500;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(lg) {
            &:hover {
              opacity:0.7!important;
            }
          }
        }
        span {
          font-weight:700;
        }
      }
    }
  }

  h1, .h1 {
    color:color(main);
  }

  p {
    &.abstract {
      margin-top:18px;
      font-size:15px; line-height:26px; letter-spacing:0.85px; font-weight:600; color:color(main);

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(18px + 8 * ((100vw - 375px) / 1065));
        font-size:calc(15px + 2 * ((100vw - 375px) / 1065)); line-height:calc(26px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:26px;
        font-size:17px; line-height:28px;
      }
    }
  }

  .btn-back {
    margin-bottom:26px; margin-left:0;
    color:color(main);

    @include media-breakpoint-up(md) {
      margin-bottom:calc(26px + 10 * ((100vw - 768px) / 672));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:36px;
    }

    &:after {
      color:color(main);
    }
  }
}

.hero-slider-wrapper {
  position:relative;
  margin-top:0;

  .breadcrumb-wrapper {
    position:absolute; left:0; top:16px;
    width:100%;
  }
}
