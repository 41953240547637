.careers-list, .search-results-list {
  margin-top:-16px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(-16px - 10 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-26px;
  }

  li {
    margin-top:16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 10 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:26px;
    }

    .career, .search-results {
      position:relative;
      display:block; float:left;
      width:100%;
      border-radius:8px;
      overflow:hidden;

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        background-color:color(grey-light);
        opacity:0.6;
        z-index:1;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }
      a {
        position:relative;
        display:flex; flex-direction:column; justify-content:flex-start; align-items:flex-start;
        width:100%;
        padding:16px 20px 20px 20px;
        z-index:2;

        @include media-breakpoint-up(md) {
          flex-direction:row; justify-content:space-between; align-items:center;
          padding:calc(16px + 2 * ((100vw - 768px) / 672)) calc(20px + 26 * ((100vw - 768px) / 672)) calc(20px + 4 * ((100vw - 768px) / 672)) calc(20px + 20 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:18px 46px 24px 40px;
        }

        > div {
          display:flex; flex-direction:column; align-items:flex-start; justify-content:flex-start;

          @include media-breakpoint-up(md) {
            padding-right:30px;
          }

          time {
            display:block; float:left;
            width:100%;
            margin-bottom:8px;
            font-size:12px; line-height:20px; letter-spacing:1.44px; color:color(main); font-weight:500;

            @include media-breakpoint-up(xs-375) {
              margin-bottom:calc(8px + 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-bottom:10px;
            }
          }
          h3, .h3 {
            position:relative;
            width:auto;
            padding-bottom:10px;

            @include media-breakpoint-up(xs-375) {
              padding-bottom:calc(10px + 3 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              padding-bottom:13px;
            }

            &:before {
              content:'';
              position:absolute; left:0; bottom:0;
              width:100%; height:2px;
              background-color:color(main-light);
              z-index:1;

              @include media-breakpoint-up(xs-375) {
                height:calc(2px + 1 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                height:3px;
              }
            }
            &:after {
              content:'';
              position:absolute; left:0; bottom:0;
              width:0%; height:2px;
              background-color:color(main);
              z-index:2;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              @include media-breakpoint-up(xs-375) {
                height:calc(2px + 1 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                height:3px;
              }
            }
          }
          p {
            margin-top:8px;
            font-size:12px; line-height:20px; letter-spacing:0.42px; font-weight:500;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(8px + 4 * ((100vw - 375px) / 1065));
              font-size:calc(12px + 2 * ((100vw - 375px) / 1065)); line-height:calc(20px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:12px;
              font-size:14px; line-height:24px;
            }
          }
        }

        > .btn {
          margin-top:16px;

          @include media-breakpoint-up(md) {
            margin-top:0;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          &:before {
            opacity:0.9;
          }
          a {
            > div {
              h3, .h3 {
                &:after {
                  width:100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.search-results-list {
  margin-top:6px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(6px + 12 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:18px;
  }

  li {
    margin-top:16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 26 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:42px;
    }

    .search-results {
      a {
        padding:24px 20px 20px 20px;

        @include media-breakpoint-up(md) {
          padding:calc(24px + 6 * ((100vw - 768px) / 672)) calc(20px + 26 * ((100vw - 768px) / 672)) calc(20px + 6 * ((100vw - 768px) / 672)) calc(20px + 20 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:30px 46px 26px 40px;
        }

        > div {
          p {
            margin-top:10px;
            font-size:13px; line-height:22px; letter-spacing:0.45px;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(10px + 5 * ((100vw - 375px) / 1065));
              font-size:calc(13px + 2 * ((100vw - 375px) / 1065)); line-height:calc(22px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:15px;
              font-size:15px; line-height:26px;
            }
          }
        }
      }
    }
  }
}

.search-results-string {
  //margin-top:10px;
  font-size:13px; line-height:22px; letter-spacing:0.3px; font-weight:500; color:color(main);

  @include media-breakpoint-up(xs-375) {
    //margin-top:calc(10px + 5 * ((100vw - 375px) / 1065));
    font-size:calc(13px + 2 * ((100vw - 375px) / 1065)); line-height:calc(22px + 4 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    //margin-top:15px;
    font-size:15px; line-height:26px;
  }

  span {
    color:color(main-med); font-weight:700; text-decoration:underline;
  }
}
