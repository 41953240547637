// Color variables

$color-list:(
  white:#FFFFFF,
  white10:#FFFFFF1A,
  white60:#FFFFFF99,
  black:#000000,
  main:#002F6C,
  main60:#6682A7,
  main-dark:#001837,
  main-med:#4F71B6,
  main-light:#B7C5FF,
  main-lightest:#F1F3FF,
  main-alt:#6A90CC,
  main-alt2:#6D81A3,
  main-section:#E8EBF2,
  main-utility:#315687,
  main-scroller:#ABC2DF,
  azure:#C9E0F4,
  grey-light:#F4F1F4,
  grey-light60:#F4F1F499,
  grey:#E5E4E0,
  grey-med:#D9D9D9,
  area-color-01:#B7C5FF,
  area-color-02:#E6007E,
  area-color-03:#BE60A1,
  area-color-04:#7958A1,
  area-color-05:#5A87BA,
  area-color-06:#00A4E2,
  area-color-07:#85898F,
  area-color-08:#E63027,
  area-color-09:#008137,
  area-color-10:#F8A900,
  product-color-01:#2270CF, // solmucalm
  product-color-02:#FF8610, // solmucol
  product-color-03:#99CBDA,
  product-color-04:#1FA279, // schnupfen
  product-color-05:#8DC6C7,
  product-color-06:#5C90CE,
  product-color-07:#68C8F3,
  product-color-08:#004A85,
  product-color-09:#D8503D, // flector dolo forte
  product-color-10:#8FCABF, // artrosi
  product-color-11:#f9d037,
  product-color-12:#bf4fbd, // ialuril / sinovial
  product-color-13:#be1035,
  product-color-14:#a78cda,
  product-color-15:#43b02a,
  product-color-16:#9cd4ef, // syrel
  product-color-17:#c9e5f3, // syrel chiaro
  product-color-18:#9ec6bc, // thirodium
  product-color-19:#d2e0dc, // thirodium chiaro
  product-color-20:#c2363a, // biovigor
  product-color-21:#ca1d20, // vitaminad3
  product-color-22:#4984ba, // ialugencalm
  product-color-23:#f8d037, // silvir
  product-color-24:#a78cbb, // ialuna
  product-color-25:#abd3e1, // bronchoprotect
  product-color-26:#48a8a5, // rhinogen
  product-color-27:#7e8fad, // aliamare
  product-color-28:#68c8f2, // flector ep
  product-color-29:#014a85, // flector plus
  product-color-30:#44b02b, // ialugen plus akut
  product-color-31:#ad3b26, // ferro
  product-color-32:#61C166, // sinovial 08
  product-color-33:#1E6145, // sinovial mini
  product-color-34:#8288B2, // sinovial hl
  product-color-35:#253069, // blu ibsa
);

// Color class loop

@each $color, $value in $color-list {
  .txt-#{$color} { color:$value!important; }
  .bkg-#{$color} { background-color:$value!important; }

  .hero-box.bkg-#{$color} {
    > div {
      background-color:$value!important;
    }
  }
  .area-teaser.#{$color} {
    > a {
      .image {
        &:before {
          background-color:$value!important;
        }
      }
    }
  }
  .minisite.#{$color} {
    .section-menu-wrapper {
      border-color:$value!important;
    }
    .hero-section {
      .image-full {
        &:after {
          background-color:$value!important;
        }
      }
    }
  }
  .product-box.#{$color} {
    > a, > div {
      .image {
        background-color:$value!important;
      }
      .content {
        &.line {
          > div {
            &:before {
              background-color:$value!important;
            }
          }
          ul {
            li {
              &:before {
                color:$value!important;
              }
            }
          }
          .btn-link {
            &:before {
              background-color:$value!important;
            }
          }
        }
      }
    }
  }
  .related-teaser.#{$color} {
    a {
      .content {
        .btn-link {
          &:before {
            background-color:$value!important;
          }
        }
      }
    }
  }
}

// Color var function

@function color($color) {
  @return map-get($color-list, $color);
}

// Transparent class

.transparent {
  background-color:transparent!important;
}

// Gradient backgrounds

.bkg-gradient {
  background:rgb(0,47,108);
  background:linear-gradient(104deg, rgba(0,47,108,0.9) 48%, rgba(183,197,255,0.9) 140%);
}

.bkg-gradient-light {
  background:rgb(183,197,255);
  background:linear-gradient(90deg, rgba(183,197,255,0.9) -14%, rgba(201,224,244,0.9) 60%);
}

.bkg-image {
  position:relative;
  background-position:center; background-repeat:no-repeat; background-size:cover;

  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    background:rgb(0,47,108);
    background:linear-gradient(104deg, rgba(0,47,108,0.7) 48%, rgba(183,197,255,0.7) 140%);
    -webkit-backdrop-filter:blur(5px); -moz-backdrop-filter:blur(5px); -o-backdrop-filter:blur(5px); -ms-backdrop-filter:blur(5px); backdrop-filter:blur(5px);
  }
  .row {
    position:relative;
    z-index:2;
  }
}

// Color variations

.bkg-main, .bkg-gradient, .bkg-image, .hero-section {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p {
    color:color(white)!important;
  }
}
