.text-article {
  display:block; float:left;
  width:100%;

  > * {
    &:first-child {
      margin-top:0!important;
    }
    &:last-child {
      margin-bottom:0!important;
    }
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    float:none;
    margin-top:26px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(26px + 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:34px;
    }
  }
  h4, .h4 {
    font-size:18px; line-height:26px; font-weight:400; letter-spacing:1.1px;

    @include media-breakpoint-up(xs-375) {
      font-size:calc(18px + 4 * ((100vw - 375px) / 1065)); line-height:calc(26px + 6 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:22px; line-height:32px;
    }
  }
  p {
    float:none;
    margin-top:16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:20px;
    }

    &.note {
      position:relative;
      padding-top:14px; margin-top:36px;
      font-size:11px; line-height:19px; letter-spacing:0.39px;

      @include media-breakpoint-up(xs-375) {
        padding-top:calc(14px + 2 * ((100vw - 375px) / 1065)); margin-top:calc(36px + 34 * ((100vw - 375px) / 1065));
        font-size:calc(11px + 2 * ((100vw - 375px) / 1065)); line-height:calc(19px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding-top:16px; margin-top:70px;
        font-size:13px; line-height:23px;
      }

      &:before {
        content:'';
        position:absolute; top:0; left:0;
        width:100%; height:2px;
        background-color:color(azure);
      }
    }
    &.note-small {
      margin-top:26px;
      font-size:10px; line-height:17px; letter-spacing:0.36px; text-decoration:underline;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(26px + 16 * ((100vw - 375px) / 1065));
        font-size:calc(10px + 2 * ((100vw - 375px) / 1065)); line-height:calc(17px + 3 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:42px;
        font-size:12px; line-height:20px;
      }
    }
  }
  .btn {
    float:none;
    margin-top:24px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(24px + 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:32px;
    }
  }
  .btn-wrapper {
    display:table;
    float:none;
    margin-top:12px;

    .btn {
      display:block;
      margin-top:12px;
    }
  }
  ul, ol {
    display:grid; float:none;
    width:auto;
    margin-top:12px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(12px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:16px;
    }

    li {
      position:relative;
      float:none;
      margin-top:3px; padding-left:20px;
      font-size:14px; line-height:24px; letter-spacing:0.45px; font-weight:500; color:color(main);

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(3px + 2 * ((100vw - 375px) / 1065)); padding-left:calc(20px + 3 * ((100vw - 375px) / 1065));
        font-size:calc(14px + 1 * ((100vw - 375px) / 1065)); line-height:calc(24px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:5px; padding-left:23px;
        font-size:15px; line-height:26px;
      }

      strong {
        font-weight:700;
      }
      a {
        color:color(main-med); font-weight:700;
        text-decoration:underline!important;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(main);
          }
        }
      }

      &:first-child {
        margin-top:0;
      }

      ul, ol {
        margin-bottom:14px;
      }
    }
  }
  ul {
    > li {
      &:before {
        content:'';
        position:absolute; left:0; top:8px;
        width:8px; height:8px;
        border:2px solid color(main-med);
        border-radius:50%;

        @include media-breakpoint-up(xs-375) {
          top:calc(8px + 1 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          top:9px;
        }
      }
    }
  }
  ol {
    counter-reset:olli;

    > li {
      &:before {
        content:counter(olli, decimal)'.'; counter-increment:olli;
        position:absolute; left:0; top:0;
        font-size:14px; line-height:24px; letter-spacing:0.45px; font-weight:700; color:color(main-med);

        @include media-breakpoint-up(xs-375) {
          font-size:calc(14px + 1 * ((100vw - 375px) / 1065)); line-height:calc(24px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:15px; line-height:26px;
        }
      }
    }
  }
  figure {
    display:block;
    margin-top:16px; margin-bottom:24px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 4 * ((100vw - 375px) / 1065)); margin-bottom:calc(24px + 16 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:20px; margin-bottom:40px;
    }

    .video {
      position:relative;
      float:left;
      width:100%;
      padding-bottom:56.25%;

      iframe {
        position:absolute; left:0; top:0;
        width:100%; height:100%;
      }
    }

    &.full {
      float:left;
      width:100%;
      margin-top:20px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(20px + 6 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:26px;
      }
    }
    &.half-left {
      float:left;
      width:100%;

      @include media-breakpoint-up(md) {
        width:50%;
        margin-right:18px;
      }
      @include media-breakpoint-up(xl) {
        width:62%;
        margin-right:24px; margin-left:-12.5%;
      }

      &.side-video {
        @include media-breakpoint-up(xl) {
          width:50%;
          margin-left:0;
        }
      }
    }
    &.half-right {
      float:right;
      width:100%;

      @include media-breakpoint-up(md) {
        width:50%;
        margin-left:18px;
      }
      @include media-breakpoint-up(xl) {
        width:62%;
        margin-left:24px; margin-right:-12.5%;
      }

      &.side-video {
        @include media-breakpoint-up(xl) {
          width:50%;
          margin-right:0;
        }
      }
    }
    &.small-left {
      float:left;
      width:100%;

      @include media-breakpoint-up(sm) {
        width:30%;
        margin-right:18px;
      }
      @include media-breakpoint-up(md) {
        width:50%;
      }
      @include media-breakpoint-up(lg) {
        width:44%;
      }
      @include media-breakpoint-up(xl) {
        margin-right:24px;
      }
    }
    &.small-right {
      float:right;
      width:100%;

      @include media-breakpoint-up(sm) {
        width:30%;
        margin-left:18px;
      }
      @include media-breakpoint-up(md) {
        width:50%;
      }
      @include media-breakpoint-up(lg) {
        width:44%;
      }
      @include media-breakpoint-up(xl) {
        margin-left:24px;
      }
    }

    figcaption {
      position:relative;
      display:block; float:left;
      width:100%;
      padding:10px 0 0 0;
      font-size:10px; line-height:16px; color:color(main); letter-spacing:0.39px; font-weight:500;

      @include media-breakpoint-up(xs-375) {
        font-size:calc(10px + 3 * ((100vw - 375px) / 1065)); line-height:calc(16px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(lg) {
        padding:calc(10px + 12 * ((100vw - 992px) / 448)) 0 0 calc(10px + 16 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        padding:22px 0 0 26px;
        font-size:13px; line-height:20px;
      }
    }

    &.video {
      position:relative;
      width:100%;
      padding-bottom:56.25%;
      margin-top:26px; margin-bottom:26px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(26px + 18 * ((100vw - 375px) / 1065)); margin-bottom:calc(26px + 18 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:44px; margin-bottom:44px;
      }

      iframe {
        position:absolute; left:0; top:0;
        width:100%; height:100%;
      }
    }
  }

  .accordion-wrapper {
    margin-top:24px!important;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(24px + 16 * ((100vw - 375px) / 1065))!important;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:40px!important;
    }
  }
}

.side-figure {
  position:relative;
  float:left;
  width:100%;

  iframe {
    position:absolute; left:0; top:0;
    width:100%; height:100%;
  }

  &.video-4x3 {
    padding-bottom:75%;
  }
  &.video-16x9 {
    padding-bottom:56.25%;
  }

  @include media-breakpoint-down(lg) {
    margin-top:28px!important;
  }
}

.scroll-anchor {
  position:relative;
  float:left;
  width:1px; height:240px;
  margin-top:-240px;

  @include media-breakpoint-up(xl) {
    height:180px;
    margin-top:-180px;
  }

  & + .row {
    .section-intro {
      margin-bottom:20px;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(20px + 5 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:25px;
      }

      h2, .h2 {
        padding:8px 12px;
        border:2px solid color(main-light);

        @include media-breakpoint-up(xs-375) {
          padding:8px calc(12px + 22 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(lg) {
          width:auto!important; min-width:85%;
        }
        @include media-breakpoint-up(xl-1440) {
          padding:8px 34px;
        }
      }
    }
  }
}
