.header {
  position:fixed; left:0; top:0;
  width:100%;
  padding-bottom:9px; padding-left:8px; padding-right:8px;
  background-color:color(white);
  z-index:99;
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  @include media-breakpoint-up(xl) {
    padding-bottom:24px;
  }

  .container {
    .row {
      position:relative;

      .col-12 {
        display:flex; flex-direction:row; justify-content:space-between; align-items:center;

        @include media-breakpoint-up(xl) {
          justify-content:flex-start; align-items:flex-start;
        }
      }
    }
    > .row {
      &.d-xl-none {
        z-index:3!important;
      }
    }
  }

  .header-logo {
    display:block; float:left;
    height:68px;
    margin-top:13px;
    flex-shrink:0;

    img, picture {
      height:100%; width:auto;
    }

    @include media-breakpoint-up(xl) {
      width:98px; height:auto;
      margin-right:calc(24px + 10 * ((100vw - 1200px) / 240)); margin-top:16px;

      img, picture {
        width:100%; height:auto;
      }
    }
    @include media-breakpoint-up(xl-1440) {
      margin-right:34px;
    }
  }
  .header-content {
    width:100%;

    .utility-menu {
      display:block; float:left;
      width:100%;

      ul {
        float:right;
        width:auto;

        li {
          width:auto;
          margin-left:27px;

          &:first-child {
            margin-left:0;
          }

          a {
            position:relative;
            display:block; float:left;
            width:auto;
            margin:15px 0;
            font-size:12px; line-height:18px; font-weight:600; color:color(main); letter-spacing:0.36px;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(main-alt);
              }
            }
          }

          &.countries {
            position:relative;

            a {
              position:relative;
              margin:0; padding:15px 32px 15px 45px;
              font-size:11px; letter-spacing:0.33px; text-transform:uppercase;
              z-index:2;

              i {
                position:absolute; left:16px; top:50%;
                width:21px; height:21px;
                font-size:21px; line-height:21px; color:color(main);
                @include prefix(transform, translateY(-50%), webkit moz o ms);
              }
              &:before {
                content:$icon-ibsa-chevron-down;
                position:absolute; right:16px; top:50%;
                width:9px; height:9px;
                font-size:9px; line-height:9px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
                @include prefix(transform, translateY(-50%), webkit moz o ms);
              }
              &:after {
                content:none;
              }

              &.show {
                &:before {
                  content:$icon-ibsa-chevron-up;
                }
              }
            }
            &:before {
              content:'';
              position:absolute; left:0; top:0;
              width:100%; height:100%;
              background-color:color(main-light);
              opacity:0.2;
              z-index:1;
            }

            ul {
              left:50%!important;
              width:490px;
              padding:28px 18px 0 18px;
              border-radius:0; border:0 none;
              background-color:color(white);
              @include prefix(transform, translate(-50%,48px)!important, webkit moz o ms);

              &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:3px;
                background-color:color(main-light);
                opacity:0.2;
              }
              &:after {
                content:'';
                position:absolute; left:0; bottom:0;
                width:100%; height:2px;
                background-color:color(white);
                z-index:2;
              }

              li {
                position:relative;
                float:left;
                width:50%;
                margin:0;
                border-bottom:1px solid color(main-lightest);
                z-index:1;

                /*&:nth-last-child(1), &:nth-last-child(2) {
                  border-bottom:0 none;
                }*/

                a {
                  display:block; float:left;
                  width:100%;
                  padding:17px 40px 17px 15px;
                  text-align:left;

                  &:before {
                    content:$icon-ibsa-arrow-ext;
                    right:15px;
                    width:12px; height:12px;
                    font-size:12px; line-height:12px;
                  }

                  &.global {
                    padding:17px 40px 17px 46px;

                    &:after {
                      content:$icon-ibsa-world;
                      position:absolute; left:15px; top:50%;
                      width:21px; height:21px;
                      font-size:21px; line-height:21px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
                      @include prefix(transform, translateY(-50%), webkit moz o ms);
                    }
                  }
                }
              }
            }
          }
          &.search {
            margin-left:20px;

            a {
              position:relative;
              width:24px; height:24px;
              margin:12px 0;

              i {
                position:absolute; left:50%; top:50%;
                width:18px; height:18px;
                font-size:18px; line-height:18px; color:color(main-alt);
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &.icon-ibsa-search {
                  display:block;
                }
                &.icon-ibsa-close {
                  display:none;
                }
              }

              @include media-breakpoint-up(lg) {
                &:hover {
                  i {
                    color:color(main);
                  }
                }
              }
            }
          }
          &.lang {
            position:relative;
            margin-left:25px;

            a {
              position:relative;
              width:50px;
              margin:0; padding:15px 25px 15px 13px;
              font-size:11px; letter-spacing:0.55px; text-transform:uppercase;

              &:before {
                content:$icon-ibsa-chevron-down;
                position:absolute; right:8px; top:50%;
                width:9px; height:9px;
                font-size:9px; line-height:9px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
                @include prefix(transform, translateY(-50%), webkit moz o ms);
              }
              &:after  {
                content:none;
              }

              &.show {
                color:color(white);
                background-color:color(main);

                &:before {
                  content:$icon-ibsa-chevron-up;
                  color:color(white);
                }
              }
            }

            ul {
              flex-direction:column; align-items:flex-start; justify-content:flex-start;
              width:50px; min-width:50px;
              padding:0 0 11px 0; margin-top:-10px!important;
              border-radius:0; border:0 none;
              background-color:color(main);

              &.show {
                display:flex;
              }

              li {
                margin-left:0;

                a {
                  padding:7px;
                  color:color(white); line-height:13px;
                  background-color:color(main)!important;

                  &:before  {
                    content:none;
                  }
                }
              }
            }
          }

          &.active {
            a {
              &:before {
                content:'';
                position:absolute; left:50%; bottom:-6px;
                width:calc(100% + 8px); height:3px;
                background-color:color(main-med);
                @include prefix(transform, translateX(-50%), webkit moz o ms);
              }
            }
          }
        }
      }
    }
    .main-menu {
      display:block; float:left;
      width:100%;
      margin-top:21px;

      ul {
        li {
          width:auto;
          margin-left:20px;

          @include media-breakpoint-up(xl) {
            margin-left:calc(20px + 4 * ((100vw - 1200px) / 240));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-left:24px;
          }

          a {
            position:relative;
            display:block; float:left;
            width:auto;
            font-size:16px; line-height:22px; font-weight:600; color:color(main); letter-spacing:0.54px;

            @include media-breakpoint-up(xl) {
              font-size:calc(16px + 2 * ((100vw - 1200px) / 240));
            }
            @include media-breakpoint-up(xl-1440) {
              font-size:18px;
            }
          }

          > a {
            &:before {
              content:'';
              position:absolute; left:-6px; bottom:0;
              width:0%; height:6px;
              background-color:color(main-light);
              opacity:0.2;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }

            @include media-breakpoint-up(lg) {
              &:hover {
                &:before {
                  width:calc(100% + 12px);
                }
              }
            }
          }

          &.highlight {
            a {
              color:color(main-med);
            }
          }
          &.sub {
            position:relative;

            a {
              &:after {
                content:none;
              }
              &.show {
                &:before {
                  width:calc(100% + 12px);
                }
              }
            }
            ul {
              position:absolute; left:-32px; top:100%;
              display:none;
              padding:22px 0 10px 0; /*margin-left:-32px!important;*/
              width:292px;
              border:0 none; border-radius:0;
              background-color:color(white);

              li {
                width:100%;
                margin:0; padding:11px 26px;
                border-bottom:1px solid color(main-lightest);

                &:last-child {
                  border-bottom:0 none;
                }
                a {
                  display:block; float:left;
                  width:auto;
                  font-size:15px; line-height:22px; font-weight:500; /*white-space:nowrap;*/ letter-spacing:0.45px;
                  @include prefix(transition, all .3s ease-out, webkit moz o ms);

                  &:before {
                    content:none;
                  }

                  @include media-breakpoint-up(lg) {
                    &:hover {
                      color:color(main-alt);
                    }
                  }
                }
              }

              &.show {
                display:block;
              }
            }

            &.focus {
              a {
                &:before {
                  width:calc(100% + 12px);
                }
              }
              ul {
                display:block;
              }
            }
            @include media-breakpoint-up(lg) {
              &:hover {
                a {
                  &:before {
                    width:calc(100% + 12px);
                  }
                }
                ul {
                  display:block;
                }
              }
            }

            &.active {
              > a {
                &:after {
                  content:'';
                  position:absolute; left:-6px; bottom:-4px;
                  width:calc(100% + 12px); height:2px;
                  background-color:color(main);
                  opacity:1;
                  @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }
              }
            }
          }
          &.search-menu {
            display:none;
            float:right;
            margin-top:-5px; margin-bottom:-6px;
            width:258px;

            input {
              width:100%;
              padding:8px 30px 8px 0;
              border:0 none; border-bottom:2px solid color(main-light);
              font-size:12px; line-height:15px; color:color(main); font-weight:600; letter-spacing:0.36px; text-align:left;
              background-color:transparent;

              &::-webkit-input-placeholder { color:color(main-med); }
              &::-moz-placeholder { color:color(main-med); }
              &:-ms-input-placeholder { color:color(main-med); }
              &:-moz-placeholder { color:color(main-med); }
            }
            button {
              position:absolute; right:0; top:0;
              width:30px; height:30px;
              border:0 none; border-radius:0;
              background-color:transparent;

              i {
                position:absolute; right:0; top:50%;
                width:18px; height:18px;
                font-size:18px; line-height:18px; color:color(main);
                @include prefix(transform, translateY(-50%), webkit moz o ms);
              }
            }
          }
        }
      }
    }
  }

  .countries-mobile {
    position:relative;
    float:left;
    width:calc(100% + 40px);
    margin-left:-20px; margin-right:-20px;

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      background-color:color(main-light);
      opacity:0.2;
      z-index:1;
    }

    a {
      position:relative;
      display:block; float:left;
      width:100%;
      margin:0; padding:16px 50px 16px 20px;
      font-size:13px; line-height:17px; letter-spacing:0.52px; text-transform:uppercase; font-weight:500;
      z-index:2;

      &:before {
        content:$icon-ibsa-chevron-down;
        position:absolute; right:20px; top:50%;
        width:12px; height:12px;
        font-size:12px; line-height:12px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
      }
      &:after {
        content:none;
      }

      &.show {
        &:before {
          content:$icon-ibsa-chevron-up;
        }
      }
    }

    ul {
      left:0!important;
      width:100%; max-height:calc(100vh - 49px);
      padding:0 20px 80px 20px;
      border-radius:0; border:0 none;
      background-color:color(white);
      overflow:auto;

      @include media-breakpoint-up(xs-375) {
        padding:0 40px 80px 40px;
      }

      li {
        float:left;
        width:100%;
        margin:0;
        border-bottom:1px solid color(main-lightest);

        &:last-child {
          border-bottom:0 none;
        }

        a {
          display:block; float:left;
          width:100%;
          padding:17px 30px 17px 0;
          text-align:left; font-size:14px; line-height:17px;

          &:before {
            content:$icon-ibsa-arrow-ext;
            right:0;
            width:12px; height:12px;
            font-size:12px; line-height:12px;
          }

          &.global {
            padding:17px 30px 17px 35px;

            &:after {
              content:$icon-ibsa-world;
              position:absolute; left:0; top:50%;
              width:23px; height:23px;
              font-size:23px; line-height:23px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
              @include prefix(transform, translateY(-50%), webkit moz o ms);
            }
          }
        }
      }
    }
  }

  .mobile-menu {
    margin-left:-8px;

    a {
      position:relative;
      display:block; float:left;
      width:44px; height:44px;

      i {
        position:absolute; left:50%; top:50%;
        width:26px; height:26px;
        font-size:26px; line-height:26px; color:color(main);
        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);

        &.icon-ibsa-menu {
          display:block;
        }
        &.icon-ibsa-close {
          display:none;
          width:18px; height:18px;
          font-size:18px; line-height:18px;
        }
      }
    }
  }
  .mobile-search {
    margin-right:-8px;

    a {
      position:relative;
      display:block; float:left;
      width:44px; height:44px;

      i {
        position:absolute; left:50%; top:50%;
        width:21px; height:21px;
        font-size:21px; line-height:21px; color:color(main-alt);
        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
      }
    }
  }

  .mobile-search-layer {
    position:absolute; right:-100vw; top:0;
    width:calc(100% + 16px); max-width:calc(100% + 16px); height:calc(100% + 9px);
    padding:18px 20px!important; margin-left:-8px; margin-right:-8px;
    background-color:color(white);
    z-index:1;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    form {
      &:before {
        content:$icon-ibsa-chevron-right;
        position:absolute; left:0; top:50%;
        width:12px; height:12px;
        font-size:12px; line-height:12px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        z-index:1;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
      }

      .close-mobile-search {
        position:absolute; left:-16px; top:50%;
        width:44px; height:44px;
        z-index:2;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
      }
    }

    input {
      width:100%;
      padding:14px 44px;
      border:0 none; border-bottom:2px solid color(main-light);
      font-size:16px; line-height:18px; color:color(main); font-weight:600; letter-spacing:0.45px; text-align:center;
      background-color:transparent;

      &::-webkit-input-placeholder { color:color(main-med); }
      &::-moz-placeholder { color:color(main-med); }
      &:-ms-input-placeholder { color:color(main-med); }
      &:-moz-placeholder { color:color(main-med); }
    }
    button {
      position:absolute; right:0; top:50%;
      width:44px; height:44px;
      padding:0;
      border:0 none; border-radius:0;
      background-color:transparent;
      @include prefix(transform, translateY(-50%), webkit moz o ms);

      i {
        position:absolute; right:0; top:50;
        width:28px; height:28px;
        font-size:28px; line-height:28px; color:color(main);
        @include prefix(transform, translateY(-50%), webkit moz o ms);
      }
    }
  }

  .mobile-menu-layer {
    position:absolute; left:0; top:139px;
    display:none;
    width:100%; max-height:calc(100vh - 139px);
    padding:25px 20px 80px 20px;
    background-color:color(white);
    overflow:auto;

    @include media-breakpoint-up(xl) {
      display:none!important;
    }

    > div {
      position:relative;
      float:left;
      width:100%;
      margin-top:34px; padding-top:34px;

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:1px;
        background-color:color(main);
      }

      &:first-child {
        margin-top:0;
      }

      .mobile-menu-main {
        li {
          position:relative;
          margin-top:26px;
          padding-left:22px;

          &:first-child {
            margin-top:0;
          }

          a {
            position:relative;
            display:block; float:left;
            width:auto;
            font-size:18px; line-height:22px; color:color(main); font-weight:600; letter-spacing:0.54px;
          }

          &.active {
            > a {
              &:after {
                content:'';
                position:absolute; left:-2px; bottom:-4px;
                width:calc(100% + 4px); height:2px;
                background-color:color(main);
                opacity:1;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }
            }
          }

          .accordion-button {
            position:absolute; left:-20px; top:-10px;
            display:block;
            width:42px; height:44px;

            &:before {
              content:$icon-ibsa-plus;
              position:absolute; left:50%; top:50%;
              width:12px; height:12px;
              font-size:12px; line-height:12px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
              @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            }
          }
          .accordion-content {
            display:none; float:left;
            width:100%;

            .mobile-submenu {
              width:calc(100% + 22px);
              margin:16px 0 0 -22px;

              li {
                margin:0; padding:0;
                border-top:1px solid color(main-section);

                &:first-child {
                  border-top:0 none;
                }

                a {
                  padding:11px 22px;
                  font-size:15px; font-weight:500; letter-spacing:0.45px;
                }

                &.main {
                  padding-top:16px;

                  a {
                    padding:11px 28px 11px 22px;
                    font-size:16px; font-weight:600;

                    &:after {
                      content:$icon-ibsa-arrow-right;
                      position:absolute; right:0; top:16px;
                      width:13px; height:13px;
                      font-size:13px; line-height:13px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
                      @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    }
                  }

                  &:first-child {
                    padding-top:0;
                  }
                }
              }
            }
          }

          &.open {
            .accordion-button {
              &:before {
                content:$icon-ibsa-minus;
              }
            }
          }

          &.highlight {
            a {
              color:color(main-med);
            }
          }
        }
      }

      .mobile-menu-utility {
        li {
          position:relative;
          margin-top:26px;

          &:first-child {
            margin-top:0;
          }

          a {
            position:relative;
            display:block; float:left;
            width:auto;
            font-size:16px; line-height:20px; color:color(main); font-weight:500; letter-spacing:0.48px;
          }

          &.active {
            a {
              &:after {
                content:'';
                position:absolute; left:-2px; bottom:-4px;
                width:calc(100% + 4px); height:2px;
                background-color:color(main);
                opacity:1;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }
            }
          }
        }
      }

      .mobile-menu-lang {
        li {
          position:relative;
          width:auto;
          margin-right:18px;

          a {
            display:block; float:left;
            width:auto;
            font-size:15px; line-height:22px; color:color(main); font-weight:500; letter-spacing:0.9px;
            opacity:0.6;
          }

          &.active {
            a {
              opacity:1;
            }
          }
        }
      }
    }
  }

  &.dark {
    background-color:color(main-lightest);

    .header-content {
      .utility-menu {
        ul {
          li {
            &.countries {
              > a {
                color:color(white);

                i {
                  color:color(white);
                }
                &:before {
                  color:color(white);
                }
              }
              &:before {
                opacity:1;
                background-color:color(main);
              }

              ul {
                &:before {
                  opacity:1;
                  background-color:color(main);
                }
              }
            }
          }
        }
      }
      .main-menu {
        ul {
          li {
            > a {
              &:before {
                opacity:0.4;
              }
            }
          }
        }
      }
    }
    .countries-mobile {
      &:before {
        background-color:color(main);
        opacity:1;
      }

      a {
        color:color(white);

        &:before {
          color:color(white);
        }
      }

      ul {
        li {
          a {
            color:color(main);

            &:before {
              color:color(main);
            }
          }
        }
      }
    }
    .mobile-search-layer {
      background-color:color(main-lightest);
    }
  }
}

.search-open {
  .header {
    .header-content {
      .utility-menu {
        ul {
          li {
            &.search {
              a {
                i {
                  &.icon-ibsa-search {
                    display:none;
                  }
                  &.icon-ibsa-close {
                    display:block;
                  }
                }
              }
            }
          }
        }
      }
      .main-menu {
        ul {
          li {
            &.search-menu {
              display:block;
            }
          }
        }
      }
    }
    .mobile-search-layer {
      right:0;
    }
  }
}

.mobile-menu-open {
  .header {
    .mobile-menu {
      a {
        i {
          &.icon-ibsa-menu {
            display:none;
          }
          &.icon-ibsa-close {
            display:block;
          }
        }
      }
    }
    .mobile-menu-layer {
      display:block;
    }
  }
}
