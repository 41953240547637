.btn {
  position:relative;
  display:inline-block; float:none;
  width:auto;
  padding:16px 44px;
  border-radius:0!important; /*border:0 none;*/
  font-size:12px; line-height:15px; color:color(main)!important; font-family:$font_montserrat; font-weight:700; letter-spacing:0.6px; text-transform:uppercase;
  background-color:transparent;
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  &:focus {
    outline:solid;
  }
  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    border:3px solid color(main-med);
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
  }
  i {
    position:relative;
    display:block; float:left;
    width:17px; height:15px;
    margin-right:10px;
    font-size:17px; line-height:15px; color:color(main-med)!important;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    &:before {
      position:absolute; left:0; top:50%;
      margin-top:-2px;
      @include prefix(transform, translateY(-50%), webkit moz o ms);
    }

    @include media-breakpoint-up(xs-375) {
      margin-right:calc(10px + 5 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-right:15px;
    }

    &.icon-ibsa-login {
      font-size:20px;
    }
  }

  &.x-small {
    padding-left:26px; padding-right:26px;
    letter-spacing:0.48px;

    i {
      margin-right:8px;

      @include media-breakpoint-up(xs-375) {
        margin-right:calc(8px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-right:10px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      color:color(white)!important;
      background-color:color(main-med)!important;

      i {
        color:color(white)!important;
      }
    }
  }

  &.btn-white {
    color:color(white)!important;

    &:before {
      border:3px solid color(white);
    }
    i {
      color:color(white)!important;
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        color:color(main)!important;
        background-color:color(white)!important;

        i {
          color:color(main)!important;
        }
      }
    }

    &.btn-full {
      color:color(main)!important;
      background-color:color(white)!important;

      i {
        color:color(main)!important;
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          background-color:transparent!important;

          &:before {
            border:3px solid color(main);
          }
        }
      }
    }
  }
  &.btn-small {
    padding:9px 37px;
    border-radius:3px!important;
    font-size:10px; line-height:14px; letter-spacing:0.8px;

    &:before {
      border:1px solid color(main);
      border-radius:3px!important;
    }
  }
}

.btn-link {
  position:relative;
  display:block; float:left;
  width:auto;
  padding:5px 0;
  font-size:11px; line-height:14px; letter-spacing:0.92px; font-weight:700; text-transform:uppercase; text-decoration:none; color:color(main)!important;
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  &:before, &:after {
    content:'';
    position:absolute; left:0; bottom:0;
    height:2px;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
  }
  &:before {
    width:100%;
    background-color:color(main-light);
    z-index:1;
  }
  &:after {
    width:0%;
    background-color:color(main);
    z-index:2;
  }

  @include media-breakpoint-up(xs-375) {
    padding:calc(5px + 1 * ((100vw - 375px) / 1065)) 0;
    font-size:calc(11px + 1 * ((100vw - 375px) / 1065)); line-height:calc(14px + 1 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(lg) {
    letter-spacing:1.92px;

    &:hover {
      &:after {
        width:100%;
      }
    }
  }
  @include media-breakpoint-up(xl-1440) {
    padding:6px 0;
    font-size:12px; line-height:15px;
  }
}

.btn-link-alt {
  position:relative;
  display:block; float:left;
  width:auto;
  padding:4px 0;
  font-size:11px; line-height:15px; color:color(main-med)!important; font-family:$font_montserrat; font-weight:700; letter-spacing:0.6px; text-transform:uppercase;
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  @include media-breakpoint-up(xs-375) {
    font-size:calc(11px + 1 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:12px;
  }

  i {
    position:relative;
    display:inline-block; float:left;
    width:15px; height:15px;
    margin-right:8px;
    font-size:15px; line-height:15px; color:color(main-med)!important;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    &:before {
      position:absolute; left:0; top:50%;
      margin-top:-2px;
      @include prefix(transform, translateY(-50%), webkit moz o ms);
    }

    @include media-breakpoint-up(xs-375) {
      width:calc(15px + 2 * ((100vw - 375px) / 1065));
      margin-right:calc(8px + 4 * ((100vw - 375px) / 1065));
      font-size:calc(15px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      width:17px;
      margin-right:12px;
      font-size:17px;
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      color:color(main)!important;

      i {
        color:color(main)!important;
      }
    }
  }
}

.btn-back {
  position:relative;
  display:block; float:left;
  width:auto;
  margin-bottom:40px; padding-left:20px;
  font-size:11px; line-height:14px; letter-spacing:0.39px; font-weight:600; text-transform:uppercase; text-decoration:none; color:color(white);
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  @include media-breakpoint-up(xs-375) {
    padding-left:calc(20px + 5 * ((100vw - 375px) / 1065));
    font-size:calc(13px + 2 * ((100vw - 375px) / 1065)); line-height:calc(14px + 2 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(md) {
    margin-bottom:calc(40px + 10 * ((100vw - 768px) / 672));
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      opacity:0.7;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-left:49px;
  }
  @include media-breakpoint-up(xl-1440) {
    margin-bottom:50px; padding-left:25px;
    font-size:13px; line-height:16px;
  }

  &:after {
    content:$icon-ibsa-arrow-left;
    position:absolute; left:0; top:2px;
    width:10px; height:10px;
    font-size:10px; line-height:10px; font-family:'icomoon'!important; font-weight:normal; font-style:normal;
    color:color(white);
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    @include media-breakpoint-up(xs-375) {
      width:calc(10px + 2 * ((100vw - 375px) / 1065)); height:calc(10px + 2 * ((100vw - 375px) / 1065));
      font-size:calc(10px + 2 * ((100vw - 375px) / 1065)); line-height:calc(10px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      width:12px; height:12px;
      font-size:12px; line-height:12px;
    }
  }
}

.top-btn {
  position:fixed; right:-300px; bottom:20px;
  display:flex; flex-direction:column; align-items:center; justify-content:center;
  color:color(main-med);
  opacity:0;
  @include prefix(transition, opacity .3s ease-out, webkit moz o ms);
  z-index:98;

  @include media-breakpoint-up(xs-375) {
    bottom:calc(20px + 20 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    bottom:40px;
  }

  span {
    position:relative;
    width:34px; height:34px;
    border-radius:50%;
    background-color:color(white);

    @include media-breakpoint-up(xl) {
      width:36px; height:36px;
    }

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      border-radius:50%; border:1px solid color(main-light);
      z-index:1;

      @include media-breakpoint-up(xs-375) {
        border:calc(1px + 1 * ((100vw - 375px) / 1065)) solid color(main-light);
      }
      @include media-breakpoint-up(xl-1440) {
        border:2px solid color(main-light);
      }
    }
    i {
      position:absolute; left:50%; top:50%;
      width:14px; height:14px;
      font-size:14px; line-height:14px; color:color(main-med);
      @include prefix(transform, translate(-50%,-50%), webkit moz o ms);

      @include media-breakpoint-up(xl) {
        width:15px; height:15px;
        font-size:15px; line-height:15px;
      }
    }
  }
  p {
    display:none;
    margin-top:4px;
    font-size:12px; line-height:18px; color:color(main-med); text-align:center; font-weight:700; text-transform:uppercase;
    opacity:0;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    @include media-breakpoint-up(xl) {
      display:block;
    }
  }

  &.light {
    p {
      color:color(white);
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      p {
        opacity:1;
      }
    }
  }
}

.top-btn-scroll {
  .top-btn {
    right:20px;
    opacity:1;

    @include media-breakpoint-up(xs-375) {
      right:calc(20px + 40 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      right:60px;
    }
  }
}

a {
  @include media-breakpoint-up(lg) {
    &:hover {
      .btn {
        color:color(white)!important;
        background-color:color(main-med);

        &.btn-white {
          color:color(main)!important;
          background-color:color(white);
        }
      }
      .btn-link {
        &:after {
          width:100%;
        }
      }
    }
  }
}
