.modal-dialog {
  &.modal-xl {
    @include media-breakpoint-up(xl) {
      --bs-modal-width:1200px;
    }
  }
  &.modal-lg {
    @include media-breakpoint-up(lg) {
      --bs-modal-width:782px;
    }
  }
  &.modal-video {
    --bs-modal-width:calc(100% - 20px);

    @include media-breakpoint-up(xs-395) {
      --bs-modal-width:calc(100% - 40px);
    }
    @include media-breakpoint-up(lg) {
      --bs-modal-width:950px;
    }

    .modal-content {
      padding-bottom:56.25%;
      border:0 none; border-radius:0;
      background-color:transparent;

      iframe {
        position:absolute; left:0; top:0;
        width:100%; height:100%;
      }

      .btn-close {
        position:absolute; right:0; top:-36px;
        width:36px; height:36px;
        background:none;

        @include media-breakpoint-up(xl) {
          right:-36px;
        }

        &:before {
          right:0; top:50%;
          width:12px; height:12px;
          font-size:12px; line-height:12px; color:color(white);
          @include prefix(transform, translate(0%,-50%), webkit moz o ms);

          @include media-breakpoint-up(xl) {
            left:50%; right: inherit;
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
          }
        }
      }
    }
  }
  .lightbox-carousel {
    &.image {
      .carousel-inner {
        display:flex; align-items:center;
      }
      .ratio {
        display:flex; justify-content:center; align-items:center;
        background-color:transparent!important;
        --bs-aspect-ratio:0!important;

        > img {
          position:relative!important;
        }
      }
    }
    &.text {
      .ratio {
        --bs-aspect-ratio:0!important;

        > .content-modal {
          position:relative;
          float:left;
          width:100%;
          padding:26px 20px 36px 20px;
          text-align:center;
          background:rgb(26,68,123);
          background:linear-gradient(35deg, rgba(26,68,123,1) 55%, rgba(64,99,154,1) 100%);

          @include media-breakpoint-up(xs-375) {
            padding:calc(26px + 30 * ((100vw - 375px) / 1065)) calc(20px + 50 * ((100vw - 375px) / 1065)) calc(36px + 40 * ((100vw - 375px) / 1065)) calc(20px + 50 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding:56px 70px 76px 70px;
          }

          h3, .h3 {
            font-size:22px; line-height:32px; letter-spacing:0.96px; font-weight:400; color:color(white);

            @include media-breakpoint-up(xs-375) {
              font-size:calc(22px + 10 * ((100vw - 375px) / 1065)); line-height:calc(32px + 14 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              font-size:32px; line-height:46px;
            }

            strong {
              font-weight:700;
            }
          }
          p {
            margin-top:20px;
            font-size:14px; line-height:22px; letter-spacing:0.51px; font-weight:400; color:color(white);

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(20px + 10 * ((100vw - 375px) / 1065));
              font-size:calc(14px + 3 * ((100vw - 375px) / 1065)); line-height:calc(22px + 6 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:30px;
              font-size:17px; line-height:28px;
            }
          }
          .btn {
            margin-top:30px;
            padding:16px 50px;
            font-size:13px; line-height:16px; letter-spacing:0.65px;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(30px + 10 * ((100vw - 375px) / 1065));
              padding:calc(16px + 2 * ((100vw - 375px) / 1065)) calc(50px + 8 * ((100vw - 375px) / 1065));
              font-size:calc(13px + 2 * ((100vw - 375px) / 1065)); line-height:calc(16px + 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(md) {
              letter-spacing:0.75px;
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:40px;
              padding:18px 58px;
              font-size:15px; line-height:18px;
            }

            &:before {
              border-width:3px;
            }
          }
        }
      }
      .spinner-border {
        display:none;
      }
    }

    .carousel-control-prev, .carousel-control-next {
      width:50%; max-width:180px; height:100%!important;
      opacity:1!important;

      .carousel-control-prev-icon, .carousel-control-next-icon {
        width:38px; height:38px;
        background-image:none;

        @include media-breakpoint-up(xs-375) {
          width:calc(38px + 20 * ((100vw - 375px) / 1065)); height:calc(38px + 20 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          width:58px; height:58px;
        }

        &:before {
          content:'';
          position:absolute; top:50%;
          width:0; height:0;
          border-style:solid;
          z-index:1;
          @include prefix(transform, translateY(-50%), webkit moz o ms);
        }
        &:after {
          position:absolute; top:50%;
          width:10px; height:10px;
          font-size:10px; line-height:10px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          z-index:2;
          @include prefix(transform, translateY(-50%), webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            width:calc(10px + 6 * ((100vw - 375px) / 1065)); height:calc(10px + 6 * ((100vw - 375px) / 1065));
            font-size:calc(10px + 6 * ((100vw - 375px) / 1065)); line-height:calc(10px + 6 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            width:16px; height:16px;
            font-size:16px; line-height:16px;
          }
        }
      }
    }
    .carousel-control-prev {
      display:none;
      justify-content:flex-start;

      @include media-breakpoint-up(lg) {
        display:block;
        left:-70px;
      }
      @include media-breakpoint-up(xl) {
        left:10px;
      }
      @include media-breakpoint-up(xl-1440) {
        left:-70px;
      }

      .carousel-control-prev-icon {
        &:before {
          left:0;
          border-width:19px 0 19px 38px;
          border-color:transparent transparent transparent color(white);

          @include media-breakpoint-up(xs-375) {
            border-width:calc(19px + 10 * ((100vw - 375px) / 1065)) 0 calc(19px + 10 * ((100vw - 375px) / 1065)) calc(38px + 20 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            border-width:29px 0 29px 58px;
          }
        }
        &:after {
          content:$icon-ibsa-chevron-left;
          left:6px;

          @include media-breakpoint-up(xs-375) {
            left:calc(6px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            left:10px;
          }
        }
      }
    }
    .carousel-control-next {
      display:none;
      justify-content:flex-end;

      @include media-breakpoint-up(lg) {
        display:block;
        right:-70px;
      }
      @include media-breakpoint-up(xl) {
        right:10px;
      }
      @include media-breakpoint-up(xl-1440) {
        right:-70px;
      }

      .carousel-control-next-icon {
        &:before {
          right:0;
          border-width:19px 38px 19px;
          border-color:transparent color(white) transparent transparent;

          @include media-breakpoint-up(xs-375) {
            border-width:calc(19px + 10 * ((100vw - 375px) / 1065)) calc(38px + 20 * ((100vw - 375px) / 1065)) calc(19px + 10 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            border-width:29px 58px 29px;
          }
        }
        &:after {
          content:$icon-ibsa-chevron-right;
          right:6px;

          @include media-breakpoint-up(xs-375) {
            right:calc(6px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            right:10px;
          }
        }
      }
    }
  }
  .btn-close {
    width:44px; height:44px;
    padding:0!important;
    opacity:1;

    @include media-breakpoint-up(xs-375) {
      width:calc(44px + 4 * ((100vw - 375px) / 1065)); height:calc(44px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      width:48px; height:48px;
    }

    &:before {
      content:$icon-ibsa-close;
      position:absolute; right:0; top:50%;
      width:14px; height:14px;
      font-size:14px; line-height:14px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
      @include prefix(transform, translate(0%,-50%), webkit moz o ms);

      @include media-breakpoint-up(xs-375) {
        width:calc(14px + 2 * ((100vw - 375px) / 1065)); height:calc(14px + 2 * ((100vw - 375px) / 1065));
        font-size:calc(14px + 2 * ((100vw - 375px) / 1065)); line-height:calc(14px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        right:50%;
        width:16px; height:16px;
        font-size:16px; line-height:16px;
        @include prefix(transform, translate(50%,-50%), webkit moz o ms);
      }
    }

    svg {
      display:none;
    }

    &.top-0 {
      top:-44px!important;
    }
    &.end-0 {
      @include media-breakpoint-up(xl-1440) {
        right:-44px!important;
      }
    }
  }
}
