.section-intro {
  position:relative;
  display:block; float:left;
  width:100%;

  &.mb {
    margin-bottom:26px;

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(26px + 14 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:40px;
    }
  }
  &.mb-small {
    margin-bottom:22px;

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(22px + 10 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:32px;
    }
  }

  .line {
    position:absolute; left:calc(100% + 8px); bottom:66px;
    display:block;
    width:804px;

    @include media-breakpoint-up(lg) {
      right:calc(100% + 40px); top:36px; left:inherit; bottom:inherit;
    }
  }

  h1, .h1 {
    color:color(main);
  }
  p {
    margin-top:8px;
    font-size:15px; line-height:25px; letter-spacing:1px; font-weight:500; color:color(main-med);

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(8px + 10 * ((100vw - 375px) / 1065));
      font-size:calc(15px + 5 * ((100vw - 375px) / 1065)); line-height:calc(25px + 5 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:18px;
      font-size:20px; line-height:30px;
    }

    &.small {
      margin-top:20px;
      font-size:14px; line-height:24px; letter-spacing:0.45px; font-weight:500; color:color(main);

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(20px + 10 * ((100vw - 375px) / 1065));
        font-size:calc(14px + 1 * ((100vw - 375px) / 1065)); line-height:calc(24px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:30px;
        font-size:15px; line-height:26px;
      }
    }

    &.indent {
      position:relative;

      @include media-breakpoint-up(lg) {
        padding-left:16.66%;
      }

      .line {
        right:inherit; top:-415px; left:calc(100% + 18px);

        @include media-breakpoint-up(lg) {
          right:calc(83.34% + 24px); top:50%; left:inherit;
        }
      }
    }
  }
}

.title-stripe {
  position:relative;

  &:before {
    content:'';
    position:absolute; left:50%; top:0;
    width:100vw; height:100%;
    background-color:color(white);
    @include prefix(transform, translateX(-50%), webkit moz o ms);
  }
}

.bkg-main {
  .section-intro {
    p {
      margin-top:6px;
      font-weight:400;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(6px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:8px;
      }
    }
  }
}

.side-article-wrapper {
  > div {
    position:relative;
    z-index:1;

    &.order-lg-2 {
      z-index:2;
    }

    .side-article {
      position:relative;
      display:block; float:left;
      width:79.5vw;
      padding:32px 24px 32px 0;
      margin-top:-20px;

      @include media-breakpoint-up(xs-375) {
        padding:calc(32px + 20 * ((100vw - 375px) / 1065)) 24px calc(32px + 30 * ((100vw - 375px) / 1065)) 0;
      }
      @include media-breakpoint-up(lg) {
        width:100%;
        margin-top:calc(30px + 80 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:110px;
        padding:52px 24px 62px 0;
      }

      &:before {
        content:'';
        position:absolute; left:-20px; top:0;
        width:calc(100% + 40px); height:100%;
        background:rgb(0,47,108);
        background:linear-gradient(104deg, rgba(0,47,108,1) 0%, rgba(70,105,164,1) 100%);
        z-index:1;

        @include media-breakpoint-up(lg) {
          left:-60px;
          width:calc(100% + 234px);
        }
      }
      h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, time, .btn {
        position:relative;
        z-index:2;
      }
      h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, time {
        color:color(white);
      }
      h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        @include media-breakpoint-up(xl) {
          padding-left:49px;
        }
      }
      h2, .h2 {
        font-size:20px; line-height:30px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(20px + 18 * ((100vw - 375px) / 1065)); line-height:calc(30px + 20 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:38px; line-height:50px;
        }
      }
      p {
        display:none;
        margin-top:17px;
        font-size:13px; line-height:26px; letter-spacing:0.6px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(17px + 10 * ((100vw - 375px) / 1065));
          font-size:calc(13px + 7 * ((100vw - 375px) / 1065)); line-height:calc(26px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(lg) {
          display:block;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:27px;
          font-size:20px; line-height:32px;
        }

        &.label {
          display:block;
          margin-top:0; margin-bottom:12px;
          text-transform:uppercase; font-weight:700;

          @include media-breakpoint-up(xs-375) {
            margin-bottom:calc(12px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-bottom:20px;
          }
        }
      }
      .btn {
        margin-top:22px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(22px + 26 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(lg) {
          margin-left:calc(20px + 30 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:48px; margin-left:50px;
        }
      }
    }
    .side-image {
      display:block; float:right;
      width:85.5vw;
      margin-right:-20px;

      @include media-breakpoint-up(sm) {
        width:75.5vw;
      }
      @include media-breakpoint-up(md) {
        width:65.5vw;
      }
      @include media-breakpoint-up(lg) {
        float:left;
        width:calc(100% + 60px);
        margin-right:-60px;
      }
    }
  }
}

.section-wrapper {
  float:left;
  width:calc(100% + 40px);
  padding:38px 30px 50px 30px;
  margin-left:-20px; margin-right:-20px;

  @include media-breakpoint-up(xs-375) {
    padding:calc(38px + 10 * ((100vw - 375px) / 1065)) 30px calc(50px + 10 * ((100vw - 375px) / 1065)) 30px;
  }
  @include media-breakpoint-up(lg) {
    width:100%;
    margin-left:0; margin-right:0;
  }
  @include media-breakpoint-up(xl-1440) {
    padding:48px 30px 60px 30px;
  }
}

.download-btn-wrapper {
  margin-top:15px;

  @include media-breakpoint-up(xs-360) {
    padding-left:20px; padding-right:20px;
  }
  @include media-breakpoint-up(md) {
    padding-left:0; padding-right:0;
  }
  @include media-breakpoint-up(xs-375) {
    margin-top:calc(15px + 40 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:55px;
  }

  > div {
    position:relative;
    display:flex; justify-content:center;
    text-align:center; font-size:0;
    padding-top:14px; padding-bottom:24px;

    @include media-breakpoint-up(md) {
      padding-top:6px; padding-bottom:0;
    }

    &:after {
      content:'';
      position:absolute; right:0; bottom:0;
      width:100%; height:1px;
      background-color:color(main-alt);

      @include media-breakpoint-up(md) {
        bottom:inherit; top:0;
        width:1px; height:43px;
      }
    }
    @include media-breakpoint-between(md,lg) {
      &:nth-last-child(2) {
        &:after {
          content:none;
        }
      }
    }
    &:last-child {
      padding-bottom:0;

      &:after {
        content:none;
      }
    }

    &:nth-child(even) {
      a {
        align-items:flex-end;
        text-align:right;

        h4, .h4 {
          padding-right:40px;

          i {
            right:0;
          }
        }
        span {
          margin-right:40px;
        }
      }
    }
    &:nth-child(odd) {
      a {
        align-items:flex-start;
        text-align:left;

        h4, .h4 {
          padding-left:40px;

          i {
            left:0;
          }
        }
        span {
          margin-left:40px;
        }
      }
    }

    a {
      position:relative;
      display:flex; flex-direction:column; justify-content:flex-start;
      width:100%;

      @include media-breakpoint-up(md) {
        align-items:center!important;
        max-width:80%;
        text-align:center!important;
      }

      h4, .h4 {
        position:relative;
        width:auto;

        @include media-breakpoint-up(md) {
          padding:0 38px!important;
        }

        i {
          position:absolute; top:0;
          width:24px; height:24px;
          font-size:24px; line-height:24px; color:color(white);

          @include media-breakpoint-up(md) {
            left:0; right:inherit; top:2px;
          }
        }
      }

      span {
        position:relative;
        display:block; float:none;
        width:auto;
        margin-top:10px; padding-bottom:7px;
        font-size:11px; line-height:13px; font-weight:700; color:color(white); letter-spacing:1.3px; text-transform:uppercase;

        @include media-breakpoint-up(md) {
          margin:8px 0 0 0!important;
          font-size:10px; line-height:12px;
        }

        &:after {
          content:'';
          position:absolute; left:0; bottom:0;
          width:100%; height:2px;
          background-color:color(main-light);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          span {
            &:after {
              left:-16px;
              width:calc(100% + 32px);
              background-color:color(white);
            }
          }
        }
      }
    }
  }
}

.modal-gallery {
  position:relative;
  display:block; float:left;
  width:100%;

  span {
    position:absolute; left:50%; top:50%;
    width:54px; height:54px;
    z-index:3;
    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);

    @include media-breakpoint-up(xs-375) {
      width:calc(54px + 30 * ((100vw - 375px) / 1065)); height:calc(54px + 30 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      width:84px; height:84px;
    }

    &:before, &:after {
      content:'';
      position:absolute; left:50%; top:50%;
      width:100%; height:100%;
      border-radius:50%;
      @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
    }
    &:before {
      background-color:color(black);
      opacity:0.6;
      z-index:1;
    }
    &:after {
      border:2px solid color(white);
      z-index:2;

      @include media-breakpoint-up(xs-375) {
        border:calc(2px + 1 * ((100vw - 375px) / 1065)) solid color(white);
      }
      @include media-breakpoint-up(xl-1440) {
        border:3px solid color(white);
      }
    }
    i {
      position:absolute; left:50%; top:50%;
      width:20px; height:20px;
      font-size:20px; line-height:20px; color:color(white);
      @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
      z-index:3;

      @include media-breakpoint-up(xs-375) {
        width:calc(20px + 14 * ((100vw - 375px) / 1065)); height:calc(20px + 14 * ((100vw - 375px) / 1065));
        font-size:calc(20px + 14 * ((100vw - 375px) / 1065)); line-height:calc(20px + 14 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        width:34px; height:34px;
        font-size:34px; line-height:34px;
      }
    }
  }
  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    background-color:color(black);
    opacity:0.2;
    z-index:2;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
  }
  .image {
    position:relative;
    display:block; float:left;
    width:100%;
    z-index:1;
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      &:before {
        opacity:0.5;
      }
    }
  }
}

.loader {
  float:left;
  width:100%;
  text-align:center; font-size:0;
  margin-top:30px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(30px + 4 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:34px;
  }

  span {
    display:inline-block; float:none;
    width:35px;
  }
}

.download-list {
  padding:4px 20px;

  @include media-breakpoint-up(md) {
    padding:calc(4px + 4 * ((100vw - 768px) / 672)) calc(20px + 68 * ((100vw - 768px) / 672));
  }
  @include media-breakpoint-up(xl-1440) {
    padding:8px 88px;
  }

  &.brd-top {
    position:relative;

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:1px;
      background-color:color(main);
      opacity:0.1;
    }

    li {
      a {
        > span {
          @include media-breakpoint-up(md) {
            margin-top:0!important;
          }
        }
      }
    }
  }

  li {
    position:relative;
    padding:20px 0 24px 0;

    @include media-breakpoint-up(md) {
      padding:20px calc(0px + 20 * ((100vw - 768px) / 672)) 24px calc(0px + 20 * ((100vw - 768px) / 672));
    }
    @include media-breakpoint-up(xl-1440) {
      padding:20px 20px 24px 20px;
    }

    &:after {
      content:'';
      position:absolute; left:0; bottom:0;
      width:100%; height:1px;
      background-color:color(main);
      opacity:0.1;
    }

    a {
      display:flex; flex-direction:row; align-items:flex-start; flex-wrap:wrap;
      width:100%;

      @include media-breakpoint-up(md) {
        align-items:flex-end; flex-wrap:nowrap;
      }

      > i {
        width:22px; height:22px;
        margin:2px 18px 2px 0;
        font-size:22px; line-height:22px; color:color(main);
        flex-shrink:0;

        @include media-breakpoint-up(md) {
          width:calc(22px + 6 * ((100vw - 768px) / 672)); height:calc(22px + 6 * ((100vw - 768px) / 672));
          margin:2px calc(18px + 12 * ((100vw - 768px) / 672)) 2px 0;
          font-size:calc(22px + 6 * ((100vw - 768px) / 672)); line-height:calc(22px + 6 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
          width:28px; height:28px;
          margin:2px 30px 2px 0;
          font-size:28px; line-height:28px;
        }
      }
      > div {
        width:calc(100% - 40px);

        @include media-breakpoint-up(md) {
          width:100%;
          padding-right:calc(20px + 10 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-right:30px;
        }

        time {
          display:block; float:left;
          width:100%;
          margin-bottom:0;
          font-size:12px; line-height:22px; letter-spacing:1.2px; color:color(main); font-weight:500;
        }
        h4, .h4 {
          line-height:24px; font-weight:600;

          @include media-breakpoint-up(xs-375) {
            line-height:calc(24px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            line-height:32px;
          }
        }
      }
      > span {
        display:flex; flex-direction:row; align-items:center; justify-content:flex-end;
        padding-left:40px; margin-top:8px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(8px + 16 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(md) {
          padding-left:0; margin-top:0;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:24px;
        }

        span {
          &:first-child {
            margin-left:0!important;
          }
        }

        span:not(.btn-link) {
          width:auto;
          font-size:12px; line-height:22px; letter-spacing:0.36px; color:color(main); white-space:nowrap; font-weight:400;
        }
        .btn-link {
          margin-left:30px;
          white-space:nowrap;

          @include media-breakpoint-up(xs-375) {
            margin-left:calc(30px + 40 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-left:70px;
          }
        }
      }
    }

    &:last-child {
      &:after {
        content:none;
      }
    }
  }
}

.banner-wrapper {
  position:relative;
  float:left;
  width:calc(100% + 40px);
  margin-left:-20px; margin-right:-20px;
  padding-top:26px; padding-bottom:26px;
  padding-left:20px; padding-right:20px;
  overflow:hidden;

  @include media-breakpoint-up(xs-375) {
    padding-top:calc(26px + 14 * ((100vw - 375px) / 1065)); padding-bottom:calc(26px + 14 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(md) {
    width:100%;
    margin-left:0; margin-right:0;
  }
  @include media-breakpoint-up(lg) {
    padding-left:0; padding-right:0;
  }
  @include media-breakpoint-up(xl-1440) {
    padding-top:40px; padding-bottom:40px;
  }

  h2, .h2 {

  }
  h3, .h3 {
    font-size:18px; line-height:28px; letter-spacing:1.3px; font-weight:700;

    @include media-breakpoint-up(xs-375) {
      font-size:calc(18px + 8 * ((100vw - 375px) / 1065)); line-height:calc(28px + 10 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:26px; line-height:38px;
    }

    b {
      font-weight:700;
      background-color:color(main-med);
    }
  }
  h4, .h4 {
    line-height:22px; font-weight:600; letter-spacing:0.9px;

    @include media-breakpoint-up(xs-375) {
      line-height:calc(22px + 3 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      line-height:25px;
    }
  }
  p {
    font-size:15px; line-height:24px; letter-spacing:0.36px;

    @include media-breakpoint-up(xs-375) {
      font-size:calc(15px + 3 * ((100vw - 375px) / 1065)); line-height:calc(24px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:18px; line-height:28px;
    }

    &.small {
      font-size:14px; line-height:22px; letter-spacing:0.3px;

      @include media-breakpoint-up(xs-375) {
        font-size:calc(14px + 1 * ((100vw - 375px) / 1065)); line-height:calc(22px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:15px; line-height:24px;
      }
    }
    &.mt-more {
      margin-top:10px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(10px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:12px;
      }
    }
  }
  .btn {
    margin-top:24px;

    &.mt-more {
      margin-top:24px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(24px + 20 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:44px;
      }
    }
  }

  .line-group {
    position:absolute; bottom:0; left:0;
    width:225px; height:100%;
    z-index:1;

    img {
      position:absolute;
      width:574px;

      &:first-child {
        top:50px; right:0;
      }
      &:last-child {
        top:220px; right:52px;
      }
    }
  }

  &.big {
    padding-top:45px; padding-bottom:35px;

    @include media-breakpoint-up(xs-375) {
      padding-top:calc(45px + 30 * ((100vw - 375px) / 1065)); padding-bottom:calc(35px + 15 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-top:75px; padding-bottom:50px;
    }

    p {
      margin-top:20px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(20px + 10 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:30px;
      }
    }
  }
  &.bkg-image {
    padding-bottom:45px;

    @include media-breakpoint-up(xs-375) {
      padding-bottom:calc(45px + 30 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-bottom:75px;
    }
  }
  &.full {
    width:calc(100% + 16px);
    margin-left:-8px; margin-right:-8px;
    padding-top:24px; padding-bottom:34px;

    @include media-breakpoint-up(xs-375) {
      padding-top:calc(24px + 10 * ((100vw - 375px) / 1065)); padding-bottom:calc(34px + 20 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-top:34px; padding-bottom:54px;
    }

    p {
      margin-top:14px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(14px + 6 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:20px;
      }
    }
  }
  &.full, &.big {
    p {
      font-size:16px; line-height:24px; letter-spacing:1px; font-weight:400;

      @include media-breakpoint-up(xs-375) {
        font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(24px + 8 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:20px; line-height:32px;
      }
    }
  }
}

.flipbook-wrapper {
  position:relative;
  float:left;
  width:100%;
  padding-bottom:56.25%;

  iframe {
    position:absolute; left:0; top:0;
    width:100%; height:100%;
  }
}

.branches-list {
  margin-top:-20px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(-20px - 4 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-24px;
  }

  > div {
    margin-top:20px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(20px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:24px;
    }
  }
}

.branch {
  display:block; float:left;
  width:100%; height:100%;
  background-color:color(grey-light);

  > a {
    display:block; float:left;
    width:100%; height:100%;
    padding:14px 20px 22px 20px;

    @include media-breakpoint-up(xs-375) {
      padding:calc(14px + 4 * ((100vw - 375px) / 1065)) calc(18px + 77 * ((100vw - 375px) / 1065)) calc(22px + 6 * ((100vw - 375px) / 1065)) calc(18px + 20 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding:18px 95px 28px 38px;
    }

    h3, .h3 {
      position:relative;
      padding:0 35px 10px 0;
      border-bottom:2px solid color(main-light);

      @include media-breakpoint-up(xs-375) {
        padding:0 calc(35px + 10 * ((100vw - 375px) / 1065)) calc(10px + 3 * ((100vw - 375px) / 1065)) 0;
        border-bottom:calc(2px + 1 * ((100vw - 375px) / 1065)) solid color(main-light);
      }
      @include media-breakpoint-up(xl-1440) {
        padding:0 45px 13px 0;
        border-bottom:3px solid color(main-light);
      }

      &:before {
        content:$icon-ibsa-arrow-right;
        position:absolute; right:0; top:5px;
        width:15px; height:15px;
        font-size:15px; line-height:15px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          top:calc(5px + 3 * ((100vw - 375px) / 1065));
          width:calc(15px + 2 * ((100vw - 375px) / 1065)); height:calc(15px + 2 * ((100vw - 375px) / 1065));
          font-size:calc(15px + 2 * ((100vw - 375px) / 1065)); line-height:calc(15px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          top:8px;
          width:17px; height:17px;
          font-size:17px; line-height:17px;
        }
      }
      &:after {
        content:'';
        position:absolute; left:0; bottom:-2px;
        width:0%; height:2px;
        background-color:color(main);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          bottom:calc(-2px - 1 * ((100vw - 375px) / 1065));
          height:calc(2px + 1 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          bottom:-3px;
          height:3px;
        }
      }
    }
    p {
      margin-top:10px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(10px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:12px;
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        h3, .h3 {
          &:after {
            width:100%;
          }
        }
      }
    }
  }
}

.map-wrapper {
  float:left;
  width:calc(100% + 16px);
  margin-left:-8px; margin-right:-8px; margin-bottom:26px;

  @include media-breakpoint-up(xs-375) {
    margin-bottom:calc(26px + 8 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-bottom:34px;
  }
}

.section-video {
  float:left;
  width:100%;
  margin-top:36px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(36px + 20 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:56px;
  }

  .video {
    position:relative;
    float:left;
    width:100%;
    margin-bottom:0;
    padding-bottom:56.25%;

    iframe {
      position:absolute; left:0; top:0;
      width:100%; height:100%;
    }
  }
}

.image-full {
  position:relative;
  float:left;
  width:calc(100% + 16px); height:245px;
  margin-left:-8px; margin-right:-8px;
  background-position:center bottom; background-repeat:no-repeat; background-size:cover;

  @include media-breakpoint-up(xs-375) {
    height:calc(245px + 170 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    height:415px;
  }

  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    background:rgb(0,0,0);
    background:linear-gradient(104deg, rgba(0,0,0,1) 40%, rgba(0,0,0,0.2) 140%);
    opacity:0.4;
    backdrop-filter:blur(5px);
  }
}

.section-container {
  float:left;
  width:100%;
  margin-top:50px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(50px + 40 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:90px;
  }

  .row {
    > div {
      section {
        overflow:visible;

        &:first-child {
          margin-top:0;
        }
      }
    }
  }
}

.info-box {
  position:relative;
  float:left;
  width:100%;
  background-color:color(grey-light);

  &:before, &:after {
    content:'';
    position:absolute; bottom:0;
    width:14px; height:100%;
    background-color:color(grey-light);
    z-index:2;

    @include media-breakpoint-up(xs-375) {
      width:calc(14px + 28 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      width:42px;
    }
  }
  &:before {
    left:0;
  }
  &:after {
    right:0;
  }

  ul {
    position:relative;
    display:flex; flex-direction:row; flex-wrap:wrap; justify-content:center;
    z-index:1;

    &:after {
      content:'';
      position:absolute; left:0; bottom:0;
      width:100%; height:6px;
      background-color:color(grey-light);
      z-index:2;
    }

    li {
      position:relative;
      width:100%;
      padding:34px 24px;
      z-index:1;

      @include media-breakpoint-up(xs-375) {
        padding:calc(34px + 20 * ((100vw - 375px) / 1065)) calc(24px + 30 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(sm) {
        width:50%;
      }
      @include media-breakpoint-up(lg) {
        width:33.33%;
      }
      @include media-breakpoint-up(xl-1440) {
        padding:54px;
      }

      &:after {
        content:'';
        position:absolute; left:0; bottom:0;
        width:100%; height:2px;
        background-color:color(main-alt);
        opacity:0.3;
      }

      &.wide {
        @include media-breakpoint-up(lg) {
          width:50%;
        }
        @include media-breakpoint-up(xl) {
          width:46%;
        }
      }

      .counter {
        display:flex; flex-direction:row; align-items:flex-end;
        float:left;
        width:100%;

        p {
          width:auto;
          margin-top:0;
          font-size:50px; line-height:38px; font-weight:400; color:color(main-alt); letter-spacing:0.03em;

          @include media-breakpoint-up(xs-375) {
            font-size:calc(50px + 30 * ((100vw - 375px) / 1065)); line-height:calc(38px + 20 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:80px; line-height:58px;
          }
        }
        img {
          height:36px; width:auto;
          margin-left:15px;

          @include media-breakpoint-up(xs-375) {
            height:calc(36px + 4 * ((100vw - 375px) / 1065));
            margin-left:calc(15px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            height:40px;
            margin-left:17px;
          }
        }
      }
      p {
        margin-top:20px;
        line-height:22px; font-weight:500; letter-spacing:0.03em;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(20px + 6 * ((100vw - 375px) / 1065));
          line-height:calc(22px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:26px;
          line-height:24px;
        }
      }
    }
  }
}
