.nav-wrapper {
  margin-top:0;
  background-color:color(main);
  z-index:3;
  overflow:visible;

  .nav-section {
    float:left;
    width:auto;
    border:2px solid color(main);
    margin-top:-27px; margin-left:-20px;

    @include media-breakpoint-up(xs-375) {
      border:calc(2px + 1 * ((100vw - 375px) / 1065)) solid color(main);
      margin-top:calc(-27px - 9 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(md) {
      margin-left:0;
    }
    @include media-breakpoint-up(xl-1440) {
      border:3px solid color(main);
      margin-top:-36px;
    }

    a {
      display:block; float:left;
      width:auto;
      padding:16px;
      font-size:15px; line-height:18px; color:color(main); letter-spacing:0.36px;
      background-color:color(white);
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      @include media-breakpoint-up(xs-375) {
        padding:calc(16px + 6 * ((100vw - 375px) / 1065)) calc(16px + 24 * ((100vw - 375px) / 1065));
        font-size:calc(15px + 1 * ((100vw - 375px) / 617)); line-height:calc(18px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(lg) {
        font-size:calc(16px + 2 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        padding:22px 40px;
        font-size:18px; line-height:22px;
      }

      &.active {
        color:color(white)!important;
        background-color:color(main)!important;
      }
      @include media-breakpoint-up(lg) {
        &:hover {
          background-color:color(main-lightest);
        }
      }
    }
  }

  &.alt {
    .nav-section {
      display:flex; justify-content:center; align-items:flex-start;
      width:100%;
      margin-top:0; margin-left:0;
      border:0 none;

      a {
        display:block; float:left;
        width:auto;
        padding:16px;
        margin-top:-64px;
        font-size:16px; line-height:22px; color:color(white); letter-spacing:0.2px; font-weight:500;
        background-color:color(main);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          padding:calc(16px + 9 * ((100vw - 375px) / 1065)) calc(16px + 14 * ((100vw - 375px) / 1065));
          margin-top:calc(-64px - 28 * ((100vw - 375px) / 1065));
          font-size:calc(16px + 8 * ((100vw - 375px) / 617)); line-height:calc(20px + 8 * ((100vw - 375px) / 1065)); letter-spacing:0.4px;
        }
        @include media-breakpoint-up(md) {
          letter-spacing:0.8px;
        }
        @include media-breakpoint-up(lg) {
          letter-spacing:1.2px;
        }
        @include media-breakpoint-up(xl-1440) {
          padding:25px 30px;
          margin-top:-92px;
          font-size:24px; line-height:28px;
        }

        &.active {
          color:color(main)!important; font-weight:700;
          background-color:color(white)!important;
        }
        @include media-breakpoint-up(lg) {
          &:hover {
            background-color:color(main-dark);
          }
        }
      }
    }
  }
}

.nav-list {
  float:left;
  width:calc(100% + 40px);
  margin-top:0; margin-bottom:20px; margin-left:-20px; margin-right:-20px;
  overflow:hidden; overflow-x:auto;

  @include media-breakpoint-up(xs-375) {
    margin-bottom:calc(20px + 15 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(lg) {
    width:100%;
    margin-top:-10px; margin-left:0; margin-right:0;
    overflow:visible;
  }
  @include media-breakpoint-up(xl-1440) {
    margin-bottom:35px;
  }

  ul {
    display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:flex-start; align-items:flex-start;
    width:auto;
    padding:0 20px 12px 20px;
    font-size:0;

    @include media-breakpoint-up(lg) {
      flex-wrap:wrap; justify-content:center;
      width:100%;
      padding:0;
    }

    li {
      position:relative;
      width:auto;
      margin:25px 36px 0 0;

      @include media-breakpoint-up(lg) {
        margin:calc(25px + 20 * ((100vw - 992px) / 448)) calc(18px + 18 * ((100vw - 992px) / 448)) 0 calc(18px + 18 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        margin:45px 36px 0 36px;
      }

      a {
        position:relative;
        display:block;
        width:auto;
        font-size:13px; line-height:16px; color:color(white); font-weight:500; letter-spacing:0.8px; white-space:nowrap;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          font-size:calc(13px + 1 * ((100vw - 375px) / 617)); line-height:calc(16px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(lg) {
          font-size:calc(14px + 2 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:16px; line-height:20px;
        }

        &:after {
          content:'';
          position:absolute; left:0; bottom:-8px;
          width:0%; height:2px;
          background-color:color(white);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            bottom:calc(-8px - 6 * ((100vw - 375px) / 1065));
            height:calc(2px + 1 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            bottom:-14px;
            height:3px;
          }
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            &:after {
              width:100%;
            }
          }
        }

        &.active {
          font-weight:600;

          &:after {
            width:100%;
          }
        }
      }

      &:last-child {
        margin-right:0;

        &::after {
          content:none;
        }
      }

      &.last-line {
        margin-right:0;

        &:after {
          content:none;
        }
      }
    }
  }

  &.alt {
    margin-top:0; margin-bottom:0;

    ul {
      li {
        margin:25px 20px 0 0;

        @include media-breakpoint-up(lg) {
          margin:calc(25px + 20 * ((100vw - 992px) / 448)) calc(10px + 5 * ((100vw - 992px) / 448)) 0 calc(10px + 5 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          margin:45px 15px 0 15px;
        }
      }
    }
  }
}

.nav-content-wrapper {
  float:left;
  width:calc(100% + 16px);
  margin-left:-8px; margin-right:-8px;
  background-color:color(grey-light);

  .container {
    padding-left:0; padding-right:0;

    .row {
      margin-left:0; margin-right:0;

      > div {
        padding-left:0; padding-right:0;
      }
    }
  }

  .nav-content {
    display:flex; flex-direction:row; justify-content:flex-start; align-items:flex-start;
    font-size:0;
    overflow:auto;

    @include media-breakpoint-up(lg) {
      justify-content:center;
    }

    a {
      position:relative;
      padding:17px 22px; margin-right:1px;
      font-size:11px; line-height:14px; color:color(main); font-weight:500; letter-spacing:0.45px;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      @include media-breakpoint-up(xs-375) {
        font-size:calc(11px + 1 * ((100vw - 375px) / 617)); line-height:calc(14px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(lg) {
        font-size:calc(12px + 3 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:15px; line-height:18px;
      }

      &:before {
        content:'';
        position:absolute; left:0; bottom:0;
        width:0%; height:3px;
        background-color:color(main);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }
      &:after {
        content:'';
        position:absolute; left:100%; top:50%;
        width:1px; height:18px;
        background-color:color(main-alt);
        opacity:0.2;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
      }

      &:last-child {
        margin-right:0;

        &:after {
          content:none;
        }
      }

      &.active {
        color:color(white)!important;
        background-color:color(main)!important;

        &:after {
          content:none;
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          &:before {
            width:100%;
          }
        }
      }
    }
  }
}

.nav-select {
  margin-top:20px;

  .select2-container {
    width:100% !important;

    &.select2-container--default {
      .select2-selection--single {
        height:48px;
        padding:13px 45px 15px 18px;
        border:1px solid color(grey-med); border-radius:2px;
        box-shadow:none;
        font-size:14px; line-height:20px; font-weight:600; color:color(white); letter-spacing:0.02em;
        background-color:color(main);

        .select2-selection__arrow {
          position:absolute; top:0; right:0;
          width:45px; height:50px;
          background:none;

          &:before {
            content:$icon-ibsa-chevron-down;
            position:absolute; left:50%; top:50%;
            width:12px; height:12px;
            font-size:12px; line-height:12px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
            @include prefix(transform, translate(-50%, -50%), webkit moz o ms);
          }
          b {
            display:none;
          }
        }
        .select2-selection__rendered {
          color:color(white); line-height:20px;
          padding:0;
        }
      }
      &.select2-container--open .select2-selection--single .select2-selection__arrow:before {
        transform:translate(-50%, -50%) rotate(180deg);
      }
    }
  }
}

.select2-container {
  &.select2-container--open {
    .select2-dropdown.nav-dropdown {
      border:1px solid color(grey-med);
      background-color:color(main);

      &.select2-dropdown--above {
        margin-bottom:-1px;
        border-radius:0 0 2px 2px;
        border-bottom:0 none;
      }
      &.select2-dropdown--below {
        margin-top:-1px;
        border-radius:2px 2px 0 0;
        border-top:0 none;
      }

      .select2-results {
        .select2-results__options {
          max-height:270px;
          padding-bottom:18px;
          overflow:auto;

          .select2-results__option {
            padding:8px 18px; margin-bottom:6px;
            font-size:14px; line-height:20px; font-weight:600; color:color(white); letter-spacing:0.02em;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            &.select2-results__option--selected {
              color:color(main); font-weight:700;
              background-color:color(main-lightest);
            }
          }
        }
      }
    }
  }
}
