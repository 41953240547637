.minisite {
  h1, .h1 {
    font-size:34px; line-height:40px; letter-spacing:0.68px;

    @include media-breakpoint-up(xs-375) {
      font-size:calc(34px + 12 * ((100vw - 375px) / 1065)); line-height:calc(40px + 16 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(lg) {
      letter-spacing:0.92px;
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:46px; line-height:56px;
    }
  }
  .content-wrapper {
    position:relative;
    float:left;
    width:100%;
    padding-top:0;

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:0;
      opacity:0;
      background-color:color(black);
      z-index:90;
      @include prefix(transition, opacity .3s ease-out, webkit moz o ms);
    }
  }
  .header {
    position:sticky;
    padding-bottom:10px; padding-left:8px; padding-right:8px;
    background-color:color(main);

    @include media-breakpoint-up(xl) {
      padding-bottom:25px;
    }

    .container {
      .row {
        .col-12 {
          justify-content:flex-start;
        }
      }
    }
    > .container {
      position:relative;
      z-index:2;
    }

    .header-logo {
      display:block; float:left;
      height:68px; width:calc(100% - 72px);
      margin-top:13px;
      flex-shrink:0;
      text-align:center; font-size:0;

      picture {
        display:inline-block; float:none;
      }
      img, picture {
        height:100%; width:auto;
      }

      @include media-breakpoint-up(xl) {
        height:49px; width:auto;
        margin-right:calc(30px + 20 * ((100vw - 1200px) / 240)); margin-top:25px;
      }
      @include media-breakpoint-up(xl-1440) {
        margin-right:50px;
      }
    }
    .header-content {
      .utility-menu {
        ul {
          li {
            a {
              font-size:13px; line-height:16px; font-weight:400; color:color(white); letter-spacing:0.65px;

              @include media-breakpoint-up(lg) {
                &:hover {
                  color:color(main-alt);
                }
              }
            }

            &.countries {
              a {
                padding:15px 45px;
                font-size:12px; letter-spacing:0.48px; font-weight:700; color:color(white);

                i {
                  color:color(white);
                }
                &:after {
                  content:none;
                }
              }
              &:before {
                background-color:color(grey-light);
              }
              > a {
                &:before {
                  content:$icon-ibsa-arrow-ext;
                  right:28px; top:12px;
                  width:10px; height:10px;
                  font-size:10px; line-height:10px; color:color(white);
                  @include prefix(transform, translateY(0)!important, webkit moz o ms);
                }
              }

              ul {
                li {
                  a {
                    font-size:11px; line-height:18px; color:color(main); font-weight:600; letter-spacing:0.33px;

                    @include media-breakpoint-up(lg) {
                      &:hover {
                        color:color(main-alt);
                      }
                    }
                  }
                }
              }
            }
            &.lang {
              a {
                font-size:12px; letter-spacing:0.6px; font-weight:600;

                &:after  {
                  content:none;
                }

                @include media-breakpoint-up(lg) {
                  &:hover {
                    color:color(main-light);
                  }
                }

                &.show {
                  color:color(white);
                  background-color:color(main-utility);

                  &:before {
                    content:$icon-ibsa-chevron-up;
                    color:color(white);
                  }
                }
              }
              > a {
                &:before {
                  content:$icon-ibsa-chevron-down;
                  position:absolute; right:8px; top:50%;
                  width:9px; height:9px;
                  font-size:9px; line-height:9px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
                  @include prefix(transform, translateY(-50%), webkit moz o ms);
                }
              }

              ul {
                background-color:color(main-utility);

                li {
                  a {
                    color:color(white);
                    background-color:color(main-utility)!important;
                  }
                }
              }
            }
          }
        }
      }
      .main-menu {
        margin-top:-5px;

        ul {
          @include media-breakpoint-up(xl) {
            margin-left:10px;
          }

          li {
            margin-left:24px;

            @include media-breakpoint-up(xl) {
              margin-left:calc(24px + 6 * ((100vw - 1200px) / 240));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-left:30px;
            }

            a {
              position:relative;
              display:block; float:left;
              width:auto;
              font-size:17px; line-height:23px; color:color(white); letter-spacing:0.95px;

              @include media-breakpoint-up(xl) {
                font-size:calc(17px + 2 * ((100vw - 1200px) / 240));
              }
              @include media-breakpoint-up(xl-1440) {
                font-size:19px;
              }
            }

            > a {
              &:before {
                bottom:-12px;
                @include prefix(transition, width .3s ease-out, webkit moz o ms);
              }
            }
            &.sub {
              &.focus {
                a {
                  &:before {
                    width:calc(100% + 12px);
                  }
                }
                .sub-layer {
                  top:99px;
                  opacity:1;
                }
              }
              &.active {
                > a {
                  &:before {
                    bottom:-35px!important;
                    width:calc(100% + 12px);
                    opacity:0.2;
                  }
                }
              }

              .sub-layer {
                position:fixed; left:0; top:-99999px;
                width:100vw; height:auto;
                padding:0 8px 36px 8px;
                background-color:color(white);
                opacity:0;
                @include prefix(transition, opacity .3s ease-out, webkit moz o ms);

                @include media-breakpoint-down(xl) {
                  display:none!important;
                }

                &:before {
                  content:'';
                  position:absolute; left:0; bottom:100%;
                  width:100%; height:35px;
                  background-color:transparent;
                }

                ul {
                  position:relative; left:0; top:0;
                  width:auto;
                  padding:0;
                  margin-top:28px; margin-bottom:2px;

                  li {
                    margin-bottom:13px;
                    padding:0; border:0 none;

                    a {
                      display:block; float:left;
                      width:auto;
                      font-size:14px; line-height:22px; font-weight:600; letter-spacing:0.28px; color:color(main);
                      @include prefix(transition, all .3s ease-out, webkit moz o ms);

                      @include media-breakpoint-up(lg) {
                        &:hover {
                          color:color(main-alt);
                        }
                      }
                      &:before {
                        content:none;
                      }
                    }

                    &.main {
                      a {
                        position:relative;
                        font-size:22px; line-height:32px;
                        padding-right:28px;

                        &:after {
                          content:$icon-ibsa-arrow-right;
                          position:absolute; right:0; top:12px;
                          width:13px; height:13px;
                          font-size:13px; line-height:13px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
                          @include prefix(transition, all .3s ease-out, webkit moz o ms);
                        }

                        @include media-breakpoint-up(lg) {
                          &:hover {
                            &:after {
                              color:color(main-alt);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .countries-mobile {
      a {
        color:color(white);

        &:before {
          content:$icon-ibsa-arrow-ext;
          color:color(white);
        }
      }
    }

    .mobile-menu {
      a {
        i {
          color:color(white);
        }
      }
    }

    .section-menu-wrapper {
      position:relative;
      float:left;
      width:calc(100% + 16px);
      padding:11px 8px;
      margin-left:-8px; margin-right:-8px;
      border-bottom:5px solid color(main);
      background-color:color(white);
      z-index:1;

      @include media-breakpoint-up(xl) {
        margin-top:25px;
      }
      &:after {
        content:'';
        position:absolute; left:0; top:calc(100% + 4px);
        width:100%; height:10px;
        background:rgb(0,0,0);
        background:linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 100%);
      }

      .row {
        > div {
          display:flex; align-items:center; justify-content:flex-start;
        }
      }

      h4, .h4 {
        position:relative;
        width:auto; min-width:85px;
        margin-right:20px; padding-right:20px;
        font-size:14px; line-height:17px; font-weight:600; letter-spacing:0.4px;
        flex-shrink:0;

        @include media-breakpoint-up(xs-375) {
          min-width:calc(85px + 70 * ((100vw - 375px) / 1065));
          margin-right:calc(20px + 6 * ((100vw - 375px) / 1065)); padding-right:calc(20px + 15 * ((100vw - 375px) / 1065));
          font-size:calc(14px + 6 * ((100vw - 375px) / 1065)); line-height:calc(17px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          min-width:155px;
          margin-right:26px; padding-right:35px;
          font-size:20px; line-height:25px;
        }

        &:after {
          content:'';
          position:absolute; right:0; top:50%;
          width:1px; height:32px;
          background-color:color(main);
          opacity:0.2;
          @include prefix(transform, translateY(-50%), webkit moz o ms);
        }
      }
      .section-menu {
        width:calc(100% + 20px);
        overflow:auto;
        margin-right:-20px;

        ul {
          display:flex;
          width:auto;
          white-space:nowrap;

          li {
            width:auto;
            margin-right:22px;

            a {
              display:block; float:left;
              width:auto;
              padding:10px 0;
              font-size:14px; line-height:20px; color:color(main); font-weight:600;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              @include media-breakpoint-up(xs-375) {
                font-size:calc(14px + 1 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(lg) {
                &:hover {
                  color:color(main-alt);
                }
              }
              @include media-breakpoint-up(xl-1440) {
                font-size:15px;
              }
            }
            &.active {
              a {
                color:color(main-alt);
              }
            }
          }
        }
      }
    }

    &.small {
      @include media-breakpoint-up(xl) {
        padding-bottom:17px;
      }
      .header-logo {
        @include media-breakpoint-up(xl) {
          height:35px;
          margin-right:5px; margin-top:21px;
        }
      }
      .header-content {
        .main-menu {
          margin-top:-18px;

          ul {
            li {
              margin-left:24px;

              @include media-breakpoint-up(xl) {
                margin-left:calc(24px + 1 * ((100vw - 1200px) / 240));
              }
              @include media-breakpoint-up(xl-1440) {
                margin-left:25px;
              }
              a {
                font-size:17px;
              }

              &.sub {
                &.focus {
                  .sub-layer {
                    top:73px;
                  }
                }
                &.active {
                  > a {
                    &:before {
                      bottom:-22px!important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .section-menu-wrapper {
        margin-top:10px;

        @include media-breakpoint-up(xl) {
          margin-top:17px;
        }
      }
    }
  }
  .footer {
    padding-top:24px; padding-bottom:10px; margin-top:40px;
    background-color:color(main);

    @include media-breakpoint-up(xs-375) {
      padding-top:calc(24px + 8 * ((100vw - 375px) / 1065)); padding-bottom:calc(18px + 24 * ((100vw - 375px) / 1065)); margin-top:calc(40px + 20 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-top:32px; padding-bottom:42px; margin-top:60px;
    }

    h6, .h6 {
      color:color(white); font-weight:400;
    }

    .row {
      margin-top:0;

      &.separator {
        position:relative;
        margin-top:20px; padding-top:20px;

        &:before {
          background-color:color(white);
        }
      }
    }

    .footer-logo {
      display:block; float:left;
      width:100%;
      margin-bottom:7px;
      text-align:center; font-size:0;

      @include media-breakpoint-up(lg) {
        margin-bottom:0;
      }

      picture {
        @include media-breakpoint-up(xl) {
          height:87px;

          img {
            width:auto; height:100%;
          }
        }
      }
    }

    .footer-block {
      margin-top:22px!important;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(22px + 16 * ((100vw - 375px) / 1065))!important;
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:38px!important;
      }

      h6, .h6 {
        button {
          &:before {
            color:color(white);
          }
        }
        a {
          color:color(white); font-weight:600;

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(main-alt);
            }
          }
        }
      }

      p {
        font-weight:400; color:color(white);

        a {
          color:color(white);

          &.icon {
            i {
              color:color(white);
            }
          }
        }
      }
      .btn {
        &:before {
          border-width:1px;
        }
      }
      .footer-menu {
        ul {
          li {
            border-bottom:1px solid color(white10);

            @include media-breakpoint-up(lg) {
              border-bottom:0 none;
            }

            a {
              font-weight:400; color:color(white); text-transform:inherit;

              @include media-breakpoint-up(lg) {
                font-size:14px; letter-spacing:0.7px;

                &:hover {
                  color:color(main-alt);
                }
              }
            }
          }
        }

        &.inline {
          @include media-breakpoint-up(lg) {
            ul {
              width:calc(100% + 12px);
              margin-right:-12px;

              li {
                &:after {
                  background-color:color(white);
                }
                &:last-child {
                  margin-right:0; padding-right:0;

                  &:after {
                    content:none;
                  }
                }
                a {
                  font-size:13px; letter-spacing:0.39px; color:color(white)!important; text-transform:inherit; text-decoration:none!important;

                  @include media-breakpoint-up(lg) {
                    font-size:14px; letter-spacing:0.7px;

                    &:hover {
                      color:color(main-alt)!important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .footer-social {
      margin-top:34px;

      @include media-breakpoint-up(lg) {
        margin-top:14px;
      }

      ul {
        li {
          a {
            i {
              color:color(white);
            }

            @include media-breakpoint-up(lg) {
              &:hover {
                i {
                  color:color(main-alt);
                }
              }
            }
          }
        }
      }
    }

    .copyright {
      color:color(white); font-weight:400;
    }

    .bottom-link {
      margin-top:15px;

      ul {
        li {
          a {
            font-weight:400; color:color(white);

            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(main-alt);
              }
            }
          }
        }
      }
    }
  }
  .section-intro {
    p {
      &.indent {
        @include media-breakpoint-up(lg) {
          padding-left:8.33%;
        }
      }
    }
  }
  .main-slider-wrapper {
    .swiper-slide {
      &[data-type="dark"] {
        .content {
          &:before {
            background:rgb(0,0,0);
            background:linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,1) 80%);

            @include media-breakpoint-up(md) {
              background:rgb(0,0,0);
              background:linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            }
            @include media-breakpoint-up(xxl) {
              background:rgb(0,0,0);
              background:linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 90%);
            }
            @include media-breakpoint-up(xxl-1920) {
              background:rgb(0,0,0);
              background:linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%);
            }
          }
        }
      }
    }
  }

  &.menu-open {
    .content-wrapper {
      &:before {
        height:100%;
        opacity:0.35;
      }
    }
  }

  .banner-wrapper {
    padding-top:26px!important; padding-bottom:26px!important;

    @include media-breakpoint-up(xs-375) {
      padding-top:calc(26px + 16 * ((100vw - 375px) / 1065))!important; padding-bottom:calc(26px + 16 * ((100vw - 375px) / 1065))!important;
    }
    @include media-breakpoint-up(xl-1440) {
      padding-top:42px!important; padding-bottom:42px!important;
    }

    &.small {
      padding-top:22px!important; padding-bottom:22px!important;

      @include media-breakpoint-up(xs-375) {
        padding-top:calc(22px + 6 * ((100vw - 375px) / 1065))!important; padding-bottom:calc(22px + 6 * ((100vw - 375px) / 1065))!important;
      }
      @include media-breakpoint-up(xl-1440) {
        padding-top:28px!important; padding-bottom:28px!important;
      }
    }

    .btn {
      @include media-breakpoint-up(lg) {
        margin-top:0!important;
      }
    }
  }

  .hero-section {
    min-height:0;
    padding:0!important;
    background-color:transparent;

    .image-full {
      margin-bottom:5px;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(5px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:9px;
      }

      &:after {
        content:'';
        position:absolute; left:50%; bottom:-5px;
        width:90%; height:5px; max-width:1206px;
        background-color:transparent;
        @include prefix(transform, translateX(-50%), webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          bottom:calc(-5px - 4 * ((100vw - 375px) / 1065));
          height:calc(5px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          bottom:-9px;
          height:9px;
        }
      }
    }
  }

  .text-article {
    h3, .h3 {
      margin-top:20px;
      font-weight:700; letter-spacing:1.2px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(20px + 8 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:28px;
      }
    }
    h4, .h4 {
      font-size:16px; line-height:24px; font-weight:700; letter-spacing:1px; text-transform:uppercase;

      @include media-breakpoint-up(xs-375) {
        font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(24px + 6 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:20px; line-height:30px;
      }
    }
    p {
      margin-top:12px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(12px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:14px;
      }
    }
    figure {
      margin-top:12px!important;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(12px + 2 * ((100vw - 375px) / 1065))!important;
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:14px!important;
      }
    }
  }
}
