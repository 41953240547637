aside {
  position:sticky; top:240px;
  float:left;
  width:100%;

  @include media-breakpoint-up(xl) {
    top:180px;
  }

  > * {
    float:left;
    width:100%;
    margin-top:30px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(30px + 30 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(lg) {
      &:first-child {
        margin-top:0;
      }
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:60px;
    }
  }

  .side-menu {
    position:relative;
    padding-left:3px;

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:3px; height:100%;
      background-color:color(main);
    }

    ul {
      li {
        position:relative;
        padding:10px 15px;

        @include media-breakpoint-up(xs-375) {
          padding:10px calc(12px + 3 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:10px 15px;
        }

        a {
          position:relative;
          display:block; float:left;
          width:100%;
          padding:5px;
          font-size:14px; line-height:22px; font-weight:600; letter-spacing:0.28px; color:color(main60);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(main);
            }
          }

          &:before {
            content:'';
            position:absolute; left:-15px; top:50%;
            width:5px; height:0;
            background-color:color(main-light);
            @include prefix(transform, translateY(-50%), webkit moz o ms);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }

          &.active {
            color:color(main);

            &:before {
              height:calc(100% + 20px);
            }
          }
        }
      }
    }
  }

  .side-related {
    position:relative;
    padding:20px 20px 2px 20px;

    @include media-breakpoint-up(xs-375) {
      padding:calc(20px + 6 * ((100vw - 375px) / 1065)) calc(20px + 3 * ((100vw - 375px) / 1065)) 2px calc(20px + 3 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding:26px 23px 2px 23px;
    }

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      border:2px solid color(grey-light);
    }

    h4, .h4 {
      position:relative;
      font-size:17px; line-height:26px; font-weight:600; letter-spacing:0;
      z-index:2;

      @include media-breakpoint-up(xs-375) {
        font-size:calc(17px + 3 * ((100vw - 375px) / 1065)); line-height:calc(26px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:20px; line-height:30px;
      }
    }
    ul {
      position:relative;
      z-index:2;
      margin-top:22px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(22px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:26px;
      }

      li {
        padding:12px 0;
        border-top:1px solid color(grey-light);

        @include media-breakpoint-up(xs-375) {
          padding:calc(12px + 6 * ((100vw - 375px) / 1065)) 0;
        }
        @include media-breakpoint-up(xl-1440) {
          padding:18px 0;
        }

        a {
          position:relative;
          display:block; float:left;
          width:100%;
          padding:0 30px 0 0;
          font-size:14px; line-height:24px; font-weight:600; letter-spacing:0.28px; color:color(main);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          &:after {
            content:$icon-ibsa-arrow-right;
            position:absolute; right:0; top:6px;
            width:13px; height:13px;
            font-size:13px; line-height:13px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              padding:0 20px 0 10px;
            }
          }
        }
      }
    }
  }
}
