$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/icomoon.ttf?uignk7') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?uignk7') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?uignk7##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-display: swap;
}

[class^="icon-ibsa-"], [class*=" icon-ibsa-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icon-ibsa-play: "\e91d";
$icon-ibsa-external: "\e91c";
$icon-ibsa-login: "\e91b";
$icon-ibsa-sound-on: "\e919";
$icon-ibsa-sound-off: "\e91a";
$icon-ibsa-checked: "\e918";
$icon-ibsa-time: "\e917";
$icon-ibsa-maximize: "\e915";
$icon-ibsa-breadcrumb-arrow: "\e916";
$icon-ibsa-arrow-down: "\e900";
$icon-ibsa-arrow-ext: "\e901";
$icon-ibsa-arrow-left: "\e902";
$icon-ibsa-arrow-right: "\e903";
$icon-ibsa-arrow-top: "\e904";
$icon-ibsa-chevron-down: "\e905";
$icon-ibsa-chevron-left: "\e906";
$icon-ibsa-chevron-right: "\e907";
$icon-ibsa-chevron-up: "\e908";
$icon-ibsa-close: "\e909";
$icon-ibsa-document: "\e90a";
$icon-ibsa-menu: "\e90b";
$icon-ibsa-minus: "\e90c";
$icon-ibsa-phone: "\e90d";
$icon-ibsa-plus: "\e90e";
$icon-ibsa-search: "\e90f";
$icon-ibsa-social-facebook: "\e910";
$icon-ibsa-social-instagram: "\e911";
$icon-ibsa-social-linkedin: "\e912";
$icon-ibsa-social-linkedin-full: "\e91e";
$icon-ibsa-social-youtube: "\e913";
$icon-ibsa-world: "\e914";

.icon-ibsa-play {
  &:before {
    content: $icon-ibsa-play;
  }
}
.icon-ibsa-external {
  &:before {
    content: $icon-ibsa-external;
  }
}
.icon-ibsa-login {
  &:before {
    content: $icon-ibsa-login;
  }
}
.icon-ibsa-sound-on {
  &:before {
    content: $icon-ibsa-sound-on;
  }
}
.icon-ibsa-sound-off {
  &:before {
    content: $icon-ibsa-sound-off;
  }
}
.icon-ibsa-checked {
  &:before {
    content: $icon-ibsa-checked;
  }
}
.icon-ibsa-time {
  &:before {
    content: $icon-ibsa-time;
  }
}
.icon-ibsa-maximize {
  &:before {
    content: $icon-ibsa-maximize;
  }
}
.icon-ibsa-breadcrumb-arrow {
  &:before {
    content: $icon-ibsa-breadcrumb-arrow;
  }
}
.icon-ibsa-arrow-down {
  &:before {
    content: $icon-ibsa-arrow-down;
  }
}
.icon-ibsa-arrow-ext {
  &:before {
    content: $icon-ibsa-arrow-ext;
  }
}
.icon-ibsa-arrow-left {
  &:before {
    content: $icon-ibsa-arrow-left;
  }
}
.icon-ibsa-arrow-right {
  &:before {
    content: $icon-ibsa-arrow-right;
  }
}
.icon-ibsa-arrow-top {
  &:before {
    content: $icon-ibsa-arrow-top;
  }
}
.icon-ibsa-chevron-down {
  &:before {
    content: $icon-ibsa-chevron-down;
  }
}
.icon-ibsa-chevron-left {
  &:before {
    content: $icon-ibsa-chevron-left;
  }
}
.icon-ibsa-chevron-right {
  &:before {
    content: $icon-ibsa-chevron-right;
  }
}
.icon-ibsa-chevron-up {
  &:before {
    content: $icon-ibsa-chevron-up;
  }
}
.icon-ibsa-close {
  &:before {
    content: $icon-ibsa-close;
  }
}
.icon-ibsa-document {
  &:before {
    content: $icon-ibsa-document;
  }
}
.icon-ibsa-menu {
  &:before {
    content: $icon-ibsa-menu;
  }
}
.icon-ibsa-minus {
  &:before {
    content: $icon-ibsa-minus;
  }
}
.icon-ibsa-phone {
  &:before {
    content: $icon-ibsa-phone;
  }
}
.icon-ibsa-plus {
  &:before {
    content: $icon-ibsa-plus;
  }
}
.icon-ibsa-search {
  &:before {
    content: $icon-ibsa-search;
  }
}
.icon-ibsa-social-facebook {
  &:before {
    content: $icon-ibsa-social-facebook;
  }
}
.icon-ibsa-social-instagram {
  &:before {
    content: $icon-ibsa-social-instagram;
  }
}
.icon-ibsa-social-linkedin {
  &:before {
    content: $icon-ibsa-social-linkedin;
  }
}
.icon-ibsa-social-linkedin-full {
  &:before {
    content: $icon-ibsa-social-linkedin-full;
  }
}
.icon-ibsa-social-youtube {
  &:before {
    content: $icon-ibsa-social-youtube;
  }
}
.icon-ibsa-world {
  &:before {
    content: $icon-ibsa-world;
  }
}
