.news-wrapper {
  margin-top:22px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(22px + 44 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:66px;
  }

  &.gutter-alt {
    margin-right:-44px; margin-left:-44px;

    > div {
      padding-right:44px; padding-left:44px;
    }
  }

  .news-side {
    position:relative;
    margin-top:56px;

    &:before {
      content:'';
      position:absolute; left:8px; top:-28px;
      width:calc(100% - 16px); height:1px;
      background-color:color(main);
      opacity:0.2;
    }

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(56px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(lg) {
      &:before {
        content:none;
      }
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:60px;
    }
  }

  &.internal {
    margin-top:0;

    .news-side {
      @include media-breakpoint-up(lg) {
        margin-top:0!important;
      }
    }
    .news-teaser {
      a {
        .content {
          margin-top:0;
        }
      }
      &.vertical, &.vertical-alt {
        a {
          .content {
            margin-top:10px;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(10px + 6 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:16px;
            }
          }
        }
      }
    }
  }
}

.news-teaser {
  display:block; float:left;
  width:100%;
  margin-top:34px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(34px + 26 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:60px;
  }

  a {
    display:flex; float:left; flex-direction:row; align-items:flex-start; justify-content:flex-start;
    width:100%;

    .image {
      position:relative;
      float:left;
      width:118px;
      flex-shrink:0;
      z-index:1;

      @include media-breakpoint-up(xs-375) {
        width:calc(118px + 30 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        width:148px;
      }
    }
    .content {
      position:relative;
      display:flex; flex-direction:column; align-items:flex-start; justify-content:flex-start;
      float:left;
      width:100%;
      padding-left:22px; margin-top:0;
      z-index:2;

      @include media-breakpoint-up(xs-375) {
        padding-left:calc(22px + 2 * ((100vw - 375px) / 1065)); margin-top:calc(0px + 56 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding-left:24px; margin-top:56px;
      }

      p {
        margin-top:13px;
        font-size:12px; line-height:20px; color:color(main); letter-spacing:0.42px; font-weight:500;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(13px + 5 * ((100vw - 375px) / 1065));
          font-size:calc(12px + 2 * ((100vw - 375px) / 1065)); line-height:calc(20px + 3 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:18px;
          font-size:14px; line-height:23px;
        }

        &.label {
          position:relative;
          width:auto;
          padding:6px 0;
          margin-left:12px;
          margin-bottom:10px; margin-top:0;
          font-size:11px; line-height:15px; color:color(main); letter-spacing:1.98px; font-weight:700; text-transform:uppercase;

          @include media-breakpoint-up(xs-375) {
            margin-bottom:calc(10px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(lg) {
            margin-left:24px;
          }
          @include media-breakpoint-up(xl-1440) {
            margin-bottom:18px;
          }
        }
      }
      .btn {
        margin-top:18px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(18px + 10 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:28px;
        }
      }
      time {
        display:block;
        width:100%;
        margin-bottom:14px;
        font-size:11px; line-height:16px; color:color(main); letter-spacing:1.2px; font-weight:500; text-align:left;

        @include media-breakpoint-up(xs-375) {
          margin-bottom:calc(14px + 2 * ((100vw - 375px) / 1065));
          font-size:calc(11px + 1 * ((100vw - 375px) / 1065)); line-height:calc(16px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-bottom:16px;
          font-size:12px; line-height:18px;
        }
      }
      h4, .h4 {
        font-size:15px; color:color(main);

        @include media-breakpoint-up(xs-375) {
          font-size:calc(15px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:17px;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
          text-decoration:underline;
        }
      }
    }
  }

  &:first-child {
    margin-top:0;
  }
  &.vertical, &.vertical-alt {
    a {
      flex-direction:column;

      .image {
        width:100%;

        @include media-breakpoint-up(lg) {
          padding-right:calc(0px + 50 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-right:50px;
        }
      }
      .content {
        margin-top:10px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(10px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-down(lg) {
          padding-left:0;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:16px;
        }

        p {
          &.label {
            margin-left:12px; margin-right:0;

            @include media-breakpoint-up(lg) {
              margin-right:calc(0px + 30 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(lg) {
              margin-left:24px;
            }
            @include media-breakpoint-up(xl-1440) {
              margin-right:30px;
            }
          }
        }
      }
    }
  }
  &.vertical-alt {
    a {
      .image {
        padding-right:0;
      }
      .content {
        padding-left:20px; padding-right:20px;

        @include media-breakpoint-up(xs-375) {
          padding-left:calc(20px + 28 * ((100vw - 375px) / 1065)); padding-right:calc(20px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-left:48px; padding-right:24px;
        }
        p {
          &.label {
            margin-left:auto; margin-right:0;
          }
        }
      }
    }

    &.md-side {
      a {
        @include media-breakpoint-up(sm) {
          flex-direction:row;
        }
        @include media-breakpoint-up(lg) {
          flex-direction:column;
        }
        ;
        .image {
          @include media-breakpoint-up(sm) {
            width:calc((100% - 24px) / 2);
          }
          @include media-breakpoint-up(lg) {
            width:100%;
          }
        }
        .content {
          @include media-breakpoint-up(sm) {
            width:calc(50% + 12px);
          }
          @include media-breakpoint-up(lg) {
            width:100%;
          }
        }
      }
    }
  }
  &.main, &.main-alt {
    @include media-breakpoint-up(lg) {
      padding-right:calc(6px + 40 * ((100vw - 992px) / 448));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-right:46px;
    }

    a {
      flex-direction:column-reverse; align-items:flex-start; justify-content:flex-end;

      @include media-breakpoint-up(lg) {
        flex-direction:row; justify-content:flex-start;
      }

      .image {
        width:85.5%;

        @include media-breakpoint-up(md) {
          width:80%;
        }
        @include media-breakpoint-up(lg) {
          width:65%;
          margin-top:calc(40px + 80 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:120px;
        }
      }
      .content {
        align-items:center;
        width:91%;
        margin-top:0; margin-left:auto; margin-bottom:-60px;
        padding:12px 16px 26px 16px;
        text-align:center;
        background-color:color(main);

        @include media-breakpoint-up(xs-375) {
          margin-bottom:calc(-60px - 36 * ((100vw - 375px) / 1065));
          padding:calc(12px + 3 * ((100vw - 375px) / 1065)) calc(16px + 56 * ((100vw - 375px) / 1065)) calc(26px + 58 * ((100vw - 375px) / 1065)) calc(16px + 33 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(md) {
          margin-bottom:-96px;
        }
        @include media-breakpoint-up(lg) {
          align-items:flex-start;
          width:60%;
          margin-left:-25%; margin-bottom:0;
          text-align:left;
        }
        @include media-breakpoint-up(xl-1440) {
          padding:15px 72px 84px 49px;
        }

        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, time {
          color:color(white)!important;
        }
        p {
          color:color(white);
          background-image:none;

          &.label {
            margin-left:12px; margin-right:0;

            @include media-breakpoint-up(lg) {
              margin-right:calc(-26px - 20 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(lg) {
              margin-left:24px;
            }
            @include media-breakpoint-up(xl-1440) {
              margin-right:-46px;
            }

            &:before {
              background-image:url('../images/line-news-white.svg');
            }
          }
        }
        time {
          margin-top:-8px;
          color:color(white);

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(-8px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:0;
          }
        }
      }
    }
  }
  &.main-alt {
    padding-right:0;

    a {
      .image {
        @include media-breakpoint-up(lg) {
          width:61.5%;
        }
      }
      .content {
        @include media-breakpoint-up(lg) {
          width:61.5%;
          margin-left:-23%;
        }
        p {
          &.label {
            margin-left:auto;

            @include media-breakpoint-up(lg) {
              margin-right:calc(-16px - 10 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-right:-26px;
            }
          }
        }
      }
    }
  }
  &.wide {
    a {
      .image {
        width:118px;

        @include media-breakpoint-up(xs-375) {
          width:calc(118px + 30 * ((100vw - 375px) / 617));
        }
        @include media-breakpoint-up(lg) {
          width:calc((100% - 24px) / 2);
        }
      }
      .content {
        width:100%;
        padding-left:22px;

        @include media-breakpoint-up(xs-375) {
          padding-left:calc(22px + 2 * ((100vw - 375px) / 617));
        }
        @include media-breakpoint-up(lg) {
          width:calc(50% + 12px);
          padding-left:24px; padding-top:calc(2px + 30 * ((100vw - 992px) / 448)); padding-right:calc(8px + 50 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-top:32px; padding-right:58px;
        }
      }
    }
  }
}

.bkg-main {
  .news-side {
    &:before {
      background-color:color(white);
    }
  }
  .news-teaser {
    a {
      .content {
        p {
          color:color(white);

          &.label {
            color:color(white);

            &:before {
              background-image:url('../images/line-news-white.svg');
            }
          }
        }
        time {
          color:color(white);
        }
        h4, .h4 {
          color:color(white);
        }
      }
    }

    &.main, &.main-alt {
      a {
        .content {
          background-color:color(white);

          h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, time {
            color:color(main)!important;
          }
          p {
            color:color(main);

            &.label {
              &:before {
                background-image:url('../images/line-news-dark.svg');
              }
            }
          }
          time {
            color:color(main);
          }
        }
      }
    }
  }
}

.news-teaser-list {
  > div {
    margin-top:38px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(38px + 20 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:58px;
    }
  }

  .news-teaser {
    a {
      .content {
        margin-top:0;
      }
    }
  }
}
