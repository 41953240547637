.accordion-wrapper {
  float:left;
  width:calc(100% + 40px);
  margin-left:-20px; margin-right:-20px; margin-top:-18px;

  @include media-breakpoint-up(md) {
    width:100%;
    margin-left:0; margin-right:0;
  }
  @include media-breakpoint-up(xs-375) {
    margin-top:calc(-18px - 8 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-26px;
  }

  .accordion-block {
    float:left;
    width:100%;
    border-bottom:2px solid color(azure);

    h3, .h3 {
      padding:18px 0; margin-top:0!important;
      background-color:color(white);
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      @include media-breakpoint-up(xs-375) {
        padding:calc(18px + 8 * ((100vw - 375px) / 1065)) 0;
      }
      @include media-breakpoint-up(xl-1440) {
        padding:26px 0;
      }

      button {
        position:relative;
        width:100%;
        padding:0 0 0 46px; border:0 none;
        font-weight:600; color:color(main); text-align:left;
        background-color:transparent;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          padding:0 0 0 calc(46px + 14 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:0 0 0 60px;
        }

        &:before {
          content:$icon-ibsa-plus;
          position:absolute; left:20px; top:7px;
          width:10px; height:10px;
          font-size:10px; line-height:10px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            left:calc(20px + 2 * ((100vw - 375px) / 1065)); top:calc(7px + 3 * ((100vw - 375px) / 1065));
            width:calc(10px + 2 * ((100vw - 375px) / 1065)); height:calc(10px + 2 * ((100vw - 375px) / 1065));
            font-size:calc(10px + 2 * ((100vw - 375px) / 1065)); line-height:calc(10px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            left:22px; top:10px;
            width:12px; height:12px;
            font-size:12px; line-height:12px;
          }
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(main-med);

            &:before {
              color:color(main-med);
            }
          }
        }
      }
    }

    .accordion-content {
      display:none; float:left;
      width:100%;

      blockquote {
        margin-top:-8px; margin-bottom:0;
        font-size:14px; line-height:24px; color:color(main-med); font-style:italic; font-weight:500; letter-spacing:0.45px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(14px + 1 * ((100vw - 375px) / 1065)); line-height:calc(24px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:15px; line-height:26px;
        }
      }
      p {
        &:first-child {
          margin-top:0;
        }
      }
    }

    &.open {
      h3, .h3 {
        background-color:color(main);

        button {
          color:color(white);

          &:before {
            content:$icon-ibsa-minus;
            color:color(white);
          }
        }
      }
    }
  }

  &.small {
    width:100%;
    margin-left:0; margin-right:0; margin-top:0;
    border-top:2px solid color(azure);

    .accordion-block {
      h3, .h3 {
        padding:12px 0;
        font-size:16px; line-height:22px; letter-spacing:0.4px;

        @include media-breakpoint-up(xs-375) {
          padding:calc(12px + 2 * ((100vw - 375px) / 1065)) 0;
          font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(22px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:14px 0;
          font-size:20px; line-height:28px;
        }

        button {
          padding:0 0 0 26px;

          @include media-breakpoint-up(xs-375) {
            padding:0 0 0 calc(26px + 34 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding:0 0 0 60px;
          }

          &:before {
            left:0; top:6px;

            @include media-breakpoint-up(xs-375) {
              top:calc(6px + 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              top:8px;
            }
          }
        }
      }

      .accordion-content {
        padding-left:26px; padding-bottom:14px;

        @include media-breakpoint-up(xs-375) {
          padding-left:calc(26px + 34 * ((100vw - 375px) / 1065)); padding-bottom:calc(14px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-left:60px; padding-bottom:18px;
        }
      }

      &.open {
        h3, .h3 {
          background-color:color(white);

          button {
            color:color(main-med);

            &:before {
              color:color(main-med);
            }
          }
        }
      }
    }
  }
}
