html, body {
  position:relative;
  background-color:color(white);
}
.container {
  width:100%; max-width:1344px;
  padding-right:12px; padding-left:12px;

  .row {
    position:relative;
    margin-right:-12px; margin-left:-12px;
    z-index:2;

    > div {
      padding-right:12px; padding-left:12px;
    }
  }
}

.content-wrapper {
  padding-top:139px;

  @include media-breakpoint-up(xl) {
    padding-top:115px;
  }
}
.main-content {
  float:left;
  width:100%;
}

section {
  position:relative;
  float:left;
  width:100%;
  margin-top:40px;
  padding-left:8px; padding-right:8px;
  z-index:1;
  overflow:hidden;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(40px + 30 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:70px;
  }

  &[class^='bkg-'], &[class*=' bkg-']{
    padding-top:40px; padding-bottom:40px;

    @include media-breakpoint-up(xs-375) {
      padding-top:calc(40px + 30 * ((100vw - 375px) / 1065)); padding-bottom:calc(40px + 30 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-top:70px; padding-bottom:70px;
    }
  }

  &.mt-med {
    margin-top:36px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(36px + 14 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:50px;
    }
  }
  &.mt-team {
    margin-top:36px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(36px + 22 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:58px;
    }
  }
  &.mt-small {
    margin-top:30px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(30px + 12 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:42px;
    }
  }

  &.mb-mobile {
    @include media-breakpoint-down(sm) {
      margin-bottom:20px;
    }
  }

  &.gradient-right {
    position:relative;

    > div {
      position:relative;
      z-index:2;
    }
    &:after {
      content:'';
      position:absolute; right:0; bottom:0;
      width:100%; height:30%;
      background:rgb(0,47,108);
      background:linear-gradient(180deg, rgba(0,47,108,1) 0%, rgba(70,105,164,1) 100%);
      z-index:1;

      @include media-breakpoint-up(lg) {
        width:40%; height:100%;
        background:rgb(0,47,108);
        background:linear-gradient(90deg, rgba(0,47,108,1) 0%, rgba(70,105,164,1) 100%);
      }
    }
  }
  &.b-stripe {
    padding-bottom:0;

    .container {
      position:relative;
      z-index:2;
    }
    &:before {
      content:'';
      position:absolute; left:0; bottom:0;
      width:100%; height:18.5%;
      background-color:color(white);
      z-index:1;

      @include media-breakpoint-up(md) {
        height:28.5%;
      }
      @include media-breakpoint-up(lg) {
        height:38.5%;
      }
    }
  }
  &.overlaying {
    z-index:3;
    margin-top:-38px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(-38px - 62 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:-100px;
    }
  }
}

.overflow-visible {
  overflow:visible;
}
.overflow-hidden {
  overflow:hidden;
}

.over-l {
  @include media-breakpoint-up(xl) {
    width:calc(100% + 63px)!important;
    margin-left:-63px!important;
  }
}
.over-x {
  @include media-breakpoint-up(xl) {
    width:calc(100% + 126px)!important;
    margin-left:-63px!important; margin-right:-63px!important;
  }
}

.front {
  position:relative!important;
  z-index:3!important;
}

ul, ol {
  display:block; float:left;
  width:100%;
  padding:0; margin:0;
  list-style:none;

  li {
    display:block; float:left;
    width:100%;
  }
}

a {
  text-decoration:none!important; color:inherit;
}

picture, img {
  display:block; float:left;
  width:100%; height:auto;
}
