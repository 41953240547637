.infographic-wrapper {
  float:left;
  width:100%;
  margin-top:60px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(60px + 3 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:63px;
  }

  .infographic-layer {
    position:relative;
    display:flex; flex-direction:row; justify-content:flex-start;
    //z-index:1;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    @include media-breakpoint-up(lg) {
      &:hover {
        //z-index:2;

        .graphic {
          img {
            opacity:1!important;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            &.hover {
              opacity:0.8!important;
            }
            &.active {
              opacity:0!important;
            }
            &.icon {
              opacity:1!important;
            }
          }
        }
      }
    }

    &.open {
      //z-index:3!important;
      margin-bottom:0!important;

      .graphic {
        img {
          opacity:1!important;

          &.icon {
            opacity:0!important;
          }
          &.hover {
            opacity:0!important;
          }
        }
      }
      .content {
        //width:calc(64% - 128px)!important;

        .value {
          justify-content:flex-start;

          img {
            opacity:1;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          .graphic {
            img {
              opacity:1!important;

              &.hover {
                opacity:1!important;
              }
              &.icon {
                opacity:0!important;
              }
            }
          }
        }
      }
    }

    .graphic {
      position:relative;
      width:327px;
      margin-left:0;
      z-index:1;

      @include media-breakpoint-up(lg) {
        width:calc(327px + 180 * ((100vw - 992px) / 448));
        margin-left:calc(0px + 75 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        width:507px;
        margin-left:75px;
      }

      img {
        position:absolute; left:50%; bottom:0;
        height:100%; width:auto;
        opacity:1;
        @include prefix(transform, translateX(-50%), webkit moz o ms);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
        z-index:1;

        &.hover {
          bottom:inherit; top:0;
          opacity:0;
          z-index:2;
        }
        &.active {
          bottom:inherit; top:0;
          opacity:0;
          z-index:2;
        }
        &.icon {
          z-index:3;
        }
      }
    }
    .content {
      position:relative;
      float:left;
      z-index:2;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      .value {
        position:relative;
        display:flex; flex-direction:row; align-items:flex-start; justify-content:flex-end;
        float:left;
        width:100%;
        padding-bottom:15px; padding-left:132px;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          padding-left:calc(132px + 40 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-left:172px;
        }

        span {
          display:flex; flex-direction:row; align-items:flex-start; justify-content:flex-end;
          font-size:18px; line-height:22px; letter-spacing:0.9px; font-weight:500; color:color(main);

          @include media-breakpoint-up(lg) {
            font-size:calc(18px + 12 * ((100vw - 992px) / 448)); line-height:calc(22px + 12 * ((100vw - 992px) / 448));
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:30px; line-height:34px;
          }

          strong {
            margin-right:5px;
            font-size:44px; line-height:60px; letter-spacing:2.4px; font-weight:400; color:color(main-alt); font-weight:normal;

            @include media-breakpoint-up(lg) {
              line-height:calc(5px + 4 * ((100vw - 992px) / 448));
              font-size:calc(44px + 36 * ((100vw - 992px) / 448)); line-height:calc(30px + 30 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-right:9px;
              font-size:80px; line-height:60px;
            }
          }
        }

        &:before {
          content:'';
          position:absolute; left:0; bottom:0;
          width:14px; height:14px;
          border:2px solid color(main); border-radius:50%;
          background-color:color(white);
          @include prefix(transform, translate(-50%, 50%), webkit moz o ms);
          z-index:2;
        }
        &:after {
          content:'';
          position:absolute; left:0; bottom:0;
          width:88%; height:3px;
          background-color:color(azure);
          @include prefix(transform, translateY(50%), webkit moz o ms);
        }

        img {
          position:absolute; left:calc(88% + 22px); top:-15px;
          width:106px; height:106px;
          opacity:0;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(lg) {
            top:calc(-15px + 30 * ((100vw - 992px) / 448));
          }
          @include media-breakpoint-up(xl-1440) {
            top:15px;
          }
        }
      }
      .text {
        display:none; float:left;
        width:100%;
        padding-left:132px; padding-top:10px; padding-bottom:50px; padding-right:12%;

        @include media-breakpoint-up(lg) {
          padding-left:calc(132px + 40 * ((100vw - 992px) / 448)); padding-bottom:calc(50px + 30 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-left:172px; padding-bottom:80px;
        }
      }
    }

    &.layer-1 {
      margin-bottom:-130px;
      z-index:1;

      .graphic {
        min-height:254px;

        img {
          height:254px;

          &.icon {
            bottom:118px;
            width:106px; height:106px;
          }
        }
      }
      .content {
        width:68%;
        margin-top:44px; margin-left:-50px;

        @include media-breakpoint-up(lg) {
          margin-top:calc(44px - 34 * ((100vw - 992px) / 448)); margin-left:calc(-50px + 12 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl) {
          width:65%;
        }
        @include media-breakpoint-up(xl-1440) {
          width:64%;
          margin-top:10px; margin-left:-120px;
        }
      }

      &.open {
        .graphic {
          img {
            &:not(.icon), &:not(.hover) {
              margin-bottom:-130px;
            }
          }
        }
      }
    }
    &.layer-2 {
      margin-bottom:-74px;
      z-index:2;

      .graphic {
        min-height:202px;

        img {
          height:202px;

          &.icon {
            bottom:80px;
            width:106px; height:106px;
          }
        }
      }
      .content {
        width:64%;
        margin-top:28px; margin-left:-82px;

        @include media-breakpoint-up(lg) {
          margin-top:calc(28px - 38 * ((100vw - 992px) / 448)); margin-left:calc(-82px - 70 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl) {
          width:58%;
        }
        @include media-breakpoint-up(xl-1440) {
          width:53%;
          margin-top:-10px; margin-left:-152px;
        }
      }

      &.open {
        .graphic {
          img {
            &:not(.icon), &:not(.hover) {
              margin-bottom:-74px;
            }
          }
        }
      }
    }
    &.layer-3 {
      margin-bottom:-50px;
      z-index:3;

      .graphic {
        min-height:153px;

        img {
          height:153px;

          &.icon {
            bottom:59px;
            width:100px; height:100px;
          }
        }
      }
      .content {
        width:60%;
        margin-top:8px; margin-left:-92px;

        @include media-breakpoint-up(lg) {
          margin-top:calc(8px - 34 * ((100vw - 992px) / 448)); margin-left:calc(-92px - 90 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl) {
          width:56%;
        }
        @include media-breakpoint-up(xl-1440) {
          width:50%;
          margin-top:-26px; margin-left:-182px;
        }
      }

      &.open {
        .graphic {
          img {
            &:not(.icon), &:not(.hover) {
              margin-bottom:-50px;
            }
          }
        }
      }
    }
    &.layer-4 {
      z-index:4;

      .graphic {
        min-height:99px;

        img {
          height:99px;

          &.icon {
            bottom:-13px;
            width:100px; height:100px;
          }
        }
      }
      .content {
        width:56%;
        margin-top:0; margin-left:-112px;

        @include media-breakpoint-up(lg) {
          margin-top:calc(0px - 17 * ((100vw - 992px) / 448)); margin-left:calc(-112px - 92 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl) {
          width:50%;
        }
        @include media-breakpoint-up(xl-1440) {
          width:42%;
          margin-top:-17px; margin-left:-204px;
        }

        .text {
          padding-bottom:0;
        }
      }
    }
  }
}

.infographic-mobile-wrapper {
  float:left;
  width:calc(100% + 40px);
  margin:24px -20px 18px -20px;

  .infographic-mobile-layer {
    position:relative;
    display:flex; flex-direction:row; justify-content:flex-start; flex-wrap:wrap;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    .graphic {
      position:relative;
      width:50%;
      z-index:1;

      img {
        position:absolute; left:50%; top:0;
        height:100%; width:auto;
        opacity:1;
        @include prefix(transform, translateX(-50%), webkit moz o ms);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
        z-index:1;

        &.icon {
          top:inherit;
          z-index:3;
        }
      }
    }
    .value {
      position:relative;
      float:left;
      width:50%;
      padding-left:10px;
      z-index:2;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      &:before {
        content:'';
        position:absolute; left:-15%; top:32px;
        width:6px; height:6px;
        border:1px solid color(main); border-radius:50%;
        background-color:color(white);
        z-index:2;
      }
      &:after {
        content:'';
        position:absolute; left:-15%; top:34px;
        width:15%; height:2px;
        background-color:color(azure);
        z-index:1;
      }

      span {
        position:relative;
        display:flex; flex-direction:column; align-items:flex-start; justify-content:flex-start;
        font-size:15px; line-height:20px; letter-spacing:0.45px; font-weight:500; color:color(main);

        strong {
          display:block; float:left;
          width:100%;
          font-size:48px; line-height:58px; letter-spacing:1.44px; font-weight:400; color:color(main-alt); font-weight:normal;
        }

        &:before {
          content:'';
          position:absolute; left:-11px; top:34px;
          height:0%; width:2px;
          background-color:color(azure);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }
      }
    }

    .content {
      position:relative;
      display:none; float:left;
      width:100%;
      padding:24px;
      margin:20px 0 0 0;
      background-image:url('../images/infographic/infographic-mobile.svg'); background-position:center top; background-repeat:no-repeat; background-size:contain;
      z-index:3;

      @include media-breakpoint-up(xs-395) {
        width:80%;
        margin:20px 10% 0 10%;
      }

      .text {
        float:left;
        width:100%;
        padding:8px 16px 16px 16px;
        background-color:color(main);

        p {
          font-size:12px; line-height:20px; color:color(white); letter-spacing:0.6px;
        }
      }
    }

    &.open {
      .value {
        span {
          &:before {
            height:100%; width:2px;
          }
        }
      }
    }

    &.layer-1 {
      margin-bottom:-8px;
      z-index:1;

      .graphic {
        img {
          height:254px;

          &.icon {
            top:30px;
            width:106px; height:106px;
          }
        }
      }
      .value {
        margin-top:56px;
      }
    }
    &.layer-2 {
      margin-bottom:14px;
      z-index:2;

      .graphic {
        img {
          height:202px;

          &.icon {
            top:12px;
            width:106px; height:106px;
          }
        }
      }
      .value {
        margin-top:34px;
      }
    }
    &.layer-3 {
      margin-bottom:10px;
      z-index:3;

      .graphic {
        img {
          height:153px;

          &.icon {
            top:-4px;
            width:100px; height:100px;
          }
        }
      }
      .value {
        margin-top:16px;
      }
    }
    &.layer-4 {
      margin-bottom:26px;
      z-index:4;

      .graphic {
        img {
          height:99px;

          &.icon {
            top:12px;
            width:100px; height:100px;
          }
        }
      }
      .value {
        margin-top:38px;
      }
    }
  }
}
