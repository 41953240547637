.main-slider-wrapper {
  margin-top:0; padding:0;

  .swiper-slide {
    position:relative;
    height:auto;
    overflow:hidden;

    .content {
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      padding:24px 8px 0 8px;
      z-index:2;

      @include media-breakpoint-up(md) {
        padding:calc(28px + 20 * ((100vw - 768px) / 672)) 8px calc(106px + 20 * ((100vw - 768px) / 672)) 8px;
      }
      @include media-breakpoint-up(xl-1440) {
        padding:48px 8px 126px 8px;
      }

      .line-group {
        position:absolute; bottom:0; right:0;
        width:40px; height:100%;
        z-index:1;

        @include media-breakpoint-up(xs-375) {
          width:calc(40px + 120 * ((100vw - 375px) / 393));
        }
        @include media-breakpoint-up(md) {
          width:160px;
        }

        img {
          position:absolute; left:0;
          width:804px;

          &:first-child {
            top:-260px;

            @include media-breakpoint-up(md) {
              bottom:105px; top:inherit;
            }
          }
          &:last-child {
            display:none;
            bottom:0;

            @include media-breakpoint-up(md) {
              display:block;
            }
          }
        }
      }

      .container {
        position:relative;
        z-index:2;

        .row {
          div {
            position:relative;
            text-align:center;

            @include media-breakpoint-up(lg) {
              text-align:left;
            }

            .line {
              position:absolute; top:0; right:calc(100% - 40px);
              display:block;
              width:804px;
              z-index:1;

              @include media-breakpoint-up(lg) {
                top:-50px; right:calc(100% - 160px);
              }
            }
          }
        }
      }

      &:before {
        content:'';
        position:absolute; left:50%; top:0;
        width:100%; height:100%;
        @include prefix(transform, translateX(-50%), webkit moz o ms);
        z-index:1;
      }

      h1, .h1 {
        color:color(main);

        @include media-breakpoint-up(xl) {
          padding-left:49px;
        }
      }
      p {
        position:relative;
        display:none;
        margin-top:16px;
        font-size:14px; line-height:24px; letter-spacing:0.34px; font-weight:600; color:color(main);

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(16px + 10 * ((100vw - 375px) / 1065));
          font-size:calc(14px + 3 * ((100vw - 375px) / 1065)); line-height:calc(24px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(lg) {
          display:block;
        }
        @include media-breakpoint-up(xl) {
          padding-left:49px;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:26px;
          font-size:17px; line-height:28px;
        }

        &.label {
          margin-top:0; margin-bottom:10px;
          font-size:16px; line-height:26px; letter-spacing:0.4px; text-transform:uppercase;

          @include media-breakpoint-up(xs-375) {
            margin-bottom:calc(10px + 10 * ((100vw - 375px) / 1065));
            font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(26px + 6 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-bottom:20px;
            font-size:20px; line-height:32px;
          }
        }

        .line {
          top:16px!important; right:calc(100% - 80px)!important;

          @include media-breakpoint-up(lg) {
            right:calc(100% - (55px + 25 * ((100vw - 992px) / 448)))!important;
          }
          @include media-breakpoint-up(xl-1440) {
            right:calc(100% - 80px)!important;
          }
        }
      }
      .btn {
        position:relative;
        margin-top:20px;
        padding:16px 54px;
        font-size:13px; line-height:16px; letter-spacing:0.65px;
        z-index:5;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(20px + 22 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(md) {
          padding:19px 44px;
          font-size:15px; line-height:17px; letter-spacing:0.75px;
        }
        @include media-breakpoint-up(xl) {
          margin-left:49px;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:42px;
        }

        &:before {
          border:3px solid color(main);
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(white)!important;
            background-color:color(main);
          }
        }

        &.btn-white {
          &:before {
            border:3px solid color(white);
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(main)!important;
              background-color:color(white);
            }
          }
        }
      }
    }
    .image {
      position:relative;
      width:100%; height:482px;
      background-position:center; background-repeat:no-repeat; background-size:cover;
      z-index:1;

      @include media-breakpoint-up(lg) {
        height:calc(482px + 108 * ((100vw - 992px) / 408));
        background-position:center right;
      }
      @include media-breakpoint-up(xl-1440) {
        height:590px;
      }
    }
    .video, iframe, .side-image {
      position:relative;
      display:block; float:right;
      width:100%; height:222px;
      background-position:center; background-repeat:no-repeat; background-size:cover;
      z-index:3;

      /*@include media-breakpoint-up(md) {
        width:50%;
      }*/
      @include media-breakpoint-up(lg) {
        width:58.6%; height:calc(482px + 108 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        height:590px;
      }

      span {
        position:absolute; left:50%; top:50%;
        width:50px; height:50px;
        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);

        @include media-breakpoint-up(md) {
          width:calc(50px + 12 * ((100vw - 768px) / 672)); height:calc(50px + 12 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
          width:62px; height:62px;
        }
      }
    }
    iframe {
      position:absolute; right:0; bottom:0;
      z-index:1;
      opacity:0;

      &.full {
        width:100%;
      }
    }

    &.play {
      iframe {
        z-index:4;
        opacity:1;
      }
    }

    &[data-type="dark"] {
      .content {
        h1, .h1, p {
          color:color(white);
        }
        &:before {
          background:rgb(0,47,108);
          background:linear-gradient(0deg, rgba(0,47,108,0.2) 0%, rgba(0,47,108,1) 80%);

          @include media-breakpoint-up(md) {
            background:rgb(0,47,108);
            background:linear-gradient(270deg, rgba(0,47,108,0.2) 0%, rgba(0,47,108,1) 100%);
          }
          @include media-breakpoint-up(xxl) {
            background:rgb(0,47,108);
            background:linear-gradient(270deg, rgba(0,47,108,0.2) 0%, rgba(0,47,108,1) 90%);
          }
          @include media-breakpoint-up(xxl-1920) {
            background:rgb(0,47,108);
            background:linear-gradient(270deg, rgba(0,47,108,0.2) 0%, rgba(0,47,108,1) 80%);
          }
        }
      }
    }
    &[data-type="light"] {
      .content {
        h1, .h1, p {
          color:color(main);
        }
        &:before {
          background:rgb(232,235,242);
          background:linear-gradient(0deg, rgba(232,235,242,0.2) 0%, rgba(232,235,242,1) 80%);

          @include media-breakpoint-up(md) {
            background:rgb(232,235,242);
            background:linear-gradient(270deg, rgba(232,235,242,0.2) 0%, rgba(232,235,242,1) 100%);
          }
          @include media-breakpoint-up(xxl) {
            background:rgb(232,235,242);
            background:linear-gradient(270deg, rgba(232,235,242,0.2) 0%, rgba(232,235,242,1) 90%);
          }
          @include media-breakpoint-up(xxl-1920) {
            background:rgb(232,235,242);
            background:linear-gradient(270deg, rgba(232,235,242,0.2) 0%, rgba(232,235,242,1) 80%);
          }
        }
      }
    }
    &[data-type="video"] {
      .content {
        position:relative;
        height:260px;
        background-color:color(azure);

        @include media-breakpoint-up(lg) {
          position:absolute;
          height:100%;
        }

        h1, .h1, p {
          color:color(main);
        }
      }
    }
    &[data-type="side-image"] {
      .content {
        position:relative;
        height:260px;
        background-color:color(azure);

        @include media-breakpoint-up(lg) {
          position:absolute;
          height:100%;
        }

        h1, .h1, p {
          color:color(main);
        }
      }
    }
    &[data-type="video-full"] {
      .video-wrapper {
        position:relative;
        height:482px;
        background-color:color(main-dark);

        @include media-breakpoint-up(lg) {
          height:calc(482px + 108 * ((100vw - 992px) / 408));
        }
        @include media-breakpoint-up(xl-1440) {
          height:590px;
        }

        .toggle-mute {
          position:absolute; right:10px; top:10px;
          width:44px; height:44px;
          z-index:2;

          &:before {
            content:$icon-ibsa-sound-on;
            position:absolute; left:50%; top:50%;
            width:24px; height:24px;
            font-size:24px; line-height:24px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
          }

          &.active {
            &:before {
              content:$icon-ibsa-sound-off;
            }
          }
        }

        video {
          position:absolute; left:50%; top:50%;
          width:auto; height:100%;
          opacity:0;
          z-index:1;
          @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @media only screen and (min-width: 854px) {
            width:100%; height:auto;
          }
        }
      }
    }

    &.no-layer {
      .content {
        &:before {
          content:none;
        }
      }
    }

    &.swiper-slide-active {
      &[data-type="video-full"] {
        .video-wrapper {
          video {
            opacity:1;
          }
        }
      }
    }
  }
}

.news-slider {
  position:relative;
  float:left;
  width:calc(100% + 24px);
  margin-left:-12px; margin-right:-12px;
  margin-top:56px;

  &.gutter-alt {
    width:calc(100% + 24px);
    margin-right:-12px; margin-left:-12px;
    margin-top:56px;

    @include media-breakpoint-up(xs-375) {
      width:calc(100% + (24px + 64 * ((100vw - 375px) / 1065)));
      margin-right:calc(-12px - 32 * ((100vw - 375px) / 1065)); margin-left:calc(-12px - 32 * ((100vw - 375px) / 1065));
      margin-top:calc(56px + 18 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      width:calc(100% + 88px);
      margin-right:-44px; margin-left:-44px;
      margin-top:74px;
    }

    .swiper-slide {
      padding-right:12px; padding-left:12px;

      @include media-breakpoint-up(xs-375) {
        padding-right:calc(12px + 32 * ((100vw - 375px) / 1065)); padding-left:calc(12px + 32 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding-right:44px; padding-left:44px;
      }
    }
  }

  &:before {
    content:'';
    position:absolute; left:8px; top:-28px;
    width:calc(100% - 16px); height:1px;
    background-color:color(white);
    opacity:0.2;
  }

  @include media-breakpoint-up(lg) {
    margin-top:52px;

    &:before {
      content:none;
    }
  }

  .swiper-slide {
    padding-left:12px; padding-right:12px;
  }

  .swiper-scrollbar-wrapper {
    margin:22px 0 38px 0;

    @include media-breakpoint-up(lg) {
      display:none;
      margin:48px 0;
    }
  }

  &.wide {
    width:calc(100% + 48px);
    margin-left:-24px; margin-right:-24px;
    margin-top:32px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(32px + 20 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:52px;
    }

    &:before {
      content:none;
    }

    .swiper {
      overflow:visible;
    }

    .swiper-slide {
      padding-left:24px; padding-right:24px;
    }

    .swiper-scrollbar-wrapper {
      @include media-breakpoint-up(lg) {
        display:block;
        margin:38px 0;
      }
    }
  }

  &.multiline {
    @include media-breakpoint-up(lg) {
      margin-top:0;
    }
    .swiper-wrapper {
      @include media-breakpoint-up(lg) {
        flex-wrap:wrap;
      }
    }
    .swiper-slide {
      @include media-breakpoint-up(lg) {
        width:33.33%!important;
        margin-top:calc(66px + 8 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:74px;
      }
    }
  }
}

.news-slider, .area-teaser-slider {
  .swiper-scrollbar-wrapper {
    .swiper-scrollbar {
      width:56%; height:5px;
      margin:0 22%;
      background-color:transparent;

      &:before {
        content:'';
        position:absolute; left:0; top:50%;
        width:100%; height:2px;
        border-radius:2px;
        background-color:color(white);
        @include prefix(transform, translateY(-50%), webkit moz o ms);
        z-index:1;
      }
      .swiper-scrollbar-drag {
        background:color(main-scroller);
        opacity:0.3;
        z-index:2;
      }

      @include media-breakpoint-up(md) {
        width:76%;
        margin:0 12%;
      }
    }
  }
}

.bkg-main {
  .news-slider {
    .swiper-scrollbar-wrapper {
      .swiper-scrollbar {
        &:before {
          background-color:color(main-alt);
        }
        .swiper-scrollbar-drag {
          background:color(white);
          opacity:1;
        }
      }
    }
  }
}

.swiper-pagination-wrapper {
  position:absolute; left:50%; bottom:248px;
  width:100%;
  @include prefix(transform, translateX(-50%), webkit moz o ms);
  z-index:2;

  @include media-breakpoint-up(xs-375) {
    bottom:calc(248px - 24 * ((100vw - 375px) / 393));
  }
  @include media-breakpoint-up(lg) {
    bottom:calc(20px + 30 * ((100vw - 992px) / 448));
  }
  @include media-breakpoint-up(xl-1440) {
    bottom:50px;
  }

  .swiper-pagination {
    position:relative;
    padding-left:0;
    text-align:center;

    @include media-breakpoint-up(lg) {
      text-align:left;
      counter-reset:olli;
    }
    @include media-breakpoint-up(xl) {
      padding-left:49px;
    }

    .swiper-pagination-bullet {
      position:relative;
      width:20px; height:20px;
      margin:0;
      border-radius:0;
      font-size:12px; line-height:24px; color:color(main); font-weight:600; text-align:center;
      background-color:transparent;
      opacity:1;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      &:before {
        content:'';
        position:absolute; left:50%; top:50%;
        width:8px; height:8px;
        border-radius:50%;
        border:1px solid color(main);
        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
      }
      &.swiper-pagination-bullet-active {
        font-size:20px; font-weight:700;

        &:before {
          background-color:color(white);
        }
      }

      &:first-child {
        margin-left:0;
      }
      &:last-child {
        margin-right:0;
      }

      @include media-breakpoint-up(lg) {
        width:24px; height:24px;
        margin:0 3px;

        &:before {
          content:counter(olli, decimal);
          counter-increment:olli;
          width:auto; height:auto;
          border-radius:0;
          border:0 none;
          @include prefix(transform, translate(0%,0%), webkit moz o ms);
        }

        &.swiper-pagination-bullet-active {
          &:before {
            background-color:transparent;
          }
        }
      }
    }
  }

  &[data-type="dark"] {
    .swiper-pagination {
      .swiper-pagination-bullet {
        color:color(white);

        &:before {
          border:1px solid color(white);

          @include media-breakpoint-up(lg) {
            border:0 none;
          }
        }

        &.swiper-pagination-bullet-active {
          &:before {
            background-color:color(white);

            @include media-breakpoint-up(lg) {
              background-color:transparent;
            }
          }
        }
      }
    }
  }
  &[data-type="light"], &[data-type="video"] {
    .swiper-pagination {
      .swiper-pagination-bullet {
        color:color(main);

        &:before {
          border:1px solid color(main);

          @include media-breakpoint-up(lg) {
            border:0 none;
          }
        }

        &.swiper-pagination-bullet-active {
          &:before {
            background-color:color(main);

            @include media-breakpoint-up(lg) {
              background-color:transparent;
            }
          }
        }
      }
    }
  }
}

.gallery-slider {
  position:relative;
  float:left;
  width:100%;

  .swiper-wrapper {
    z-index:1;
  }

  .swiper-slide {
    figure {
      margin:0;

      figcaption {
        display:block; float:left;
        width:100%;
        margin:10px 0 0 0;
        font-size:10px; line-height:16px; font-weight:500; color:color(main); letter-spacing:0.39px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(10px + 3 * ((100vw - 375px) / 1065)); line-height:calc(16px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-down(lg) {
          padding-bottom:40px;
        }
        @include media-breakpoint-up(lg) {
          width:86.5%;
          margin:13px 0 0 0; padding-right:18px;
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:13px; line-height:20px;
        }
      }
    }
  }

  .swiper-navigation-wrapper {
    position:absolute; left:0; top:0;
    display:none;
    width:100%;
    z-index:2;
    @include prefix(transform, translateY(-50%), webkit moz o ms);
    @include prefix(transition, opacity .3s ease-out, webkit moz o ms);

    @include media-breakpoint-up(lg) {
      display:block;
      opacity:0;
    }

    .swiper-button-prev, .swiper-button-next {
      width:38px; height:38px;
      background-image:none;

      @include media-breakpoint-up(xs-375) {
        width:calc(38px + 20 * ((100vw - 375px) / 1065)); height:calc(38px + 20 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        width:58px; height:58px;
      }

      &:before {
        content:'';
        position:absolute; top:50%;
        width:0; height:0;
        border-style:solid;
        z-index:1;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
      }
      &:after {
        position:absolute; top:50%;
        width:10px; height:10px;
        font-size:10px; line-height:10px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        z-index:2;
        @include prefix(transform, translateY(-50%), webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          width:calc(10px + 6 * ((100vw - 375px) / 1065)); height:calc(10px + 6 * ((100vw - 375px) / 1065));
          font-size:calc(10px + 6 * ((100vw - 375px) / 1065)); line-height:calc(10px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          width:16px; height:16px;
          font-size:16px; line-height:16px;
        }
      }

      &.swiper-button-disabled {
        display:none;
      }
    }

    .swiper-button-prev {
      left:10px;

      &:before {
        border-width:19px 0 19px 38px;
        border-color:transparent transparent transparent color(white);

        @include media-breakpoint-up(xs-375) {
          border-width:calc(19px + 10 * ((100vw - 375px) / 1065)) 0 calc(19px + 10 * ((100vw - 375px) / 1065)) calc(38px + 20 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          border-width:29px 0 29px 58px;
        }
      }
      &:after {
        content:$icon-ibsa-chevron-left;
        left:6px;

        @include media-breakpoint-up(xs-375) {
          left:calc(6px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          left:10px;
        }
      }
    }

    .swiper-button-next {
      right:10px;

      &:before {
        right:0;
        border-width:19px 38px 19px;
        border-color:transparent color(white) transparent transparent;

        @include media-breakpoint-up(xs-375) {
          border-width:calc(19px + 10 * ((100vw - 375px) / 1065)) calc(38px + 20 * ((100vw - 375px) / 1065)) calc(19px + 10 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          border-width:29px 58px 29px;
        }
      }
      &:after {
        content:$icon-ibsa-chevron-right;
        right:6px;

        @include media-breakpoint-up(xs-375) {
          right:calc(6px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          right:10px;
        }
      }
    }
  }

  .swiper-counter-wrapper {
    position:absolute; right:-3px; top:0;
    width:auto;
    padding:14px 32px 0 32px;
    background-color:color(white);
    z-index:2;
    @include prefix(transition, opacity .3s ease-out, webkit moz o ms);

    @include media-breakpoint-down(lg) {
      bottom:0!important; top:inherit!important;
    }
    @include media-breakpoint-up(lg) {
      max-width:13.5%;
      opacity:0;
    }

    &:before {
      content:'';
      position:absolute; right:100%; top:0;
      width:20px; height:100%;
      background:rgb(255,255,255);
      background:linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%);
    }

    .swiper-button-prev, .swiper-button-next {
      position:absolute; top:18px;
      width:16px; height:16px;
      margin:0; padding:0;

      &:after {
        content:none;
      }
      &:before {
        position:absolute; left:0; top:0;
        width:16px; height:16px;
        font-size:16px; line-height:16px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
      }
    }
    .swiper-button-prev {
      left:0;

      &:before {
        content:$icon-ibsa-chevron-left;
      }
    }
    .swiper-button-next {
      right:0;

      &:before {
        content:$icon-ibsa-chevron-right;
      }
    }

    .counter {
      font-size:12px; line-height:20px; font-weight:600; letter-spacing:0.96px; color:color(main-med); white-space:nowrap;

      span {
        width:auto;

        &.current {
          font-size:20px; font-weight:700; letter-spacing:1.6px;
        }
      }
    }
  }

  &.init {
    .swiper-navigation-wrapper, .swiper-counter-wrapper {
      opacity:1;
    }
  }
}

.gallery-slider-nav {
  position:relative;
  float:left;
  width:calc(100% + 8px);
  margin-left:-4px; margin-right:-4px;
  margin-top:26px;
  overflow:auto;
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(26px + 14 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:40px;
  }

  ul {
    display:flex; flex-direction:row; align-items:flex-start; justify-content:flex-start;
    width:auto;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    li {
      flex-shrink:0;
      width:120px;
      padding-left:4px; padding-right:4px;

      @include media-breakpoint-up(xs-375) {
        width:calc(120px + 64 * ((100vw - 375px) / 393));
      }
      @include media-breakpoint-up(md) {
        width:calc(184px + 36 * ((100vw - 768px) / 672));
      }
      @include media-breakpoint-up(xl-1440) {
        width:220px;
      }
    }
  }
}

.mCSB_horizontal.mCSB_inside>.mCSB_container {
  margin-bottom:16px!important;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  height:6px;
  opacity:1;

  .mCSB_dragger {
    background-color:color(main-med);
    opacity:0.3;
    border-radius:6px;
  }
}

.area-teaser-slider {
  position:relative;
  float:left;
  width:calc(100% + 24px);
  margin-left:-12px; margin-right:-12px;

  .swiper-slide {
    height:auto;
    padding-left:12px; padding-right:12px;
  }

  .swiper-scrollbar-wrapper {
    margin-top:28px;
  }
}

.side-gallery {
  position:relative;
  float:left;
  width:100%;
  padding-bottom:28px; margin-top:36px;

  @include media-breakpoint-up(md) {
    margin-top:0;
  }

  .swiper-button-prev, .swiper-button-next {
    width:44px; height:44px;
    padding:0; margin:0;
    @include prefix(transform, translateY(-50%), webkit moz o ms);

    &:after {
      content:none;
    }
    &:before {
      position:absolute; left:50%; top:50%;
      width:16px; height:16px;
      font-size:16px; line-height:16px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
      @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
    }
  }
  .swiper-button-prev {
    left:-10px;

    @include media-breakpoint-up(xs-375) {
      left:calc(-10px + 20 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      left:10px;
    }

    &:before {
      content:$icon-ibsa-chevron-left;
    }
  }
  .swiper-button-next {
    right:-10px;

    @include media-breakpoint-up(xs-375) {
      right:calc(-10px + 20 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      right:10px;
    }

    &:before {
      content:$icon-ibsa-chevron-right;
    }
  }
  .swiper-pagination {
    display:flex; justify-content:center; align-items:flex-start;
    bottom:0;
    height:6px;

    .swiper-pagination-bullet {
      width:18px; height:6px;
      margin:0 2px;
      border-radius:6px;
      background-color:color(main-alt2);
      opacity:0.4;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      &.swiper-pagination-bullet-active {
        background-color:color(main);
        opacity:1;
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          &:before {
            opacity:1;
          }
        }
      }
    }
  }

  .swiper-slide {
    height:auto;

    a {
      position:relative;
      display:flex; float:left; align-items:center;
      width:100%; height:100%;
      padding-left:calc((100% - 96px) / 5); padding-right:calc((100% - 96px) / 5);

      &:before {
        content:'';
        position:absolute; right:0; top:10px; left:inherit;
        width:34px; height:34px;
        border-radius:3px;
        background-color:color(black);
        opacity:0.7;
        z-index:2;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          right:calc(0px + 28 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          right:28px;
        }
      }
      &:after {
        content:$icon-ibsa-maximize;
        position:absolute; right:0; top:10px;
        width:34px; height:34px;
        font-size:24px; line-height:34px; color:color(white); text-align:center; font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        z-index:3;

        @include media-breakpoint-up(xs-375) {
          right:calc(0px + 28 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          right:28px;
        }
      }
      figure {
        position:relative;
        margin:0;
        z-index:1;
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          &:before {
            opacity:0.9;
          }
        }
      }
    }
  }
}

.product-teaser-slider {
  .swiper {
    overflow:visible;

    .swiper-slide {
      height:auto;
    }
  }

  .swiper-scrollbar-wrapper {
    position:relative;
    float:left; height:6px;
    width:100%;
    margin-top:25px;

    .swiper-scrollbar {
      left:0; top:0;
      width:100%; height:100%;
      background-color:transparent;

      .swiper-scrollbar-drag {
        background-color:color(main-alt2);
        opacity:0.2;
      }
    }
  }
}

.hero-slider {
  position:relative;
  float:left;
  width:calc(100% + 16px);
  margin-left:-8px; margin-right:-8px;

  .swiper-slide {
    position:relative;
    display:flex; align-items:center;
    height:auto; min-height:388px;
    padding-top:70px; padding-bottom:70px;

    .content {
      position:relative;
      float:left;
      width:100%;
      padding:0 0 14px 0;
      z-index:2;

      .container {
        position:relative;
        z-index:2;

        .row {
          div {
            position:relative;
            text-align:center;

            @include media-breakpoint-up(lg) {
              text-align:left;
            }
          }
        }
      }

      h2, .h2 {
        color:color(white);

        @include media-breakpoint-up(xl) {
          padding-left:49px;
        }
      }
      p {
        position:relative;
        display:none;
        margin-top:12px;
        font-size:16px; line-height:26px; letter-spacing:0.34px; font-weight:400; color:color(white);

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(12px + 4 * ((100vw - 375px) / 1065));
          font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(26px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(lg) {
          display:block;
        }
        @include media-breakpoint-up(xl) {
          padding-left:49px;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:16px;
          font-size:20px; line-height:32px;
        }
      }
      .btn {
        position:relative;
        margin-top:20px;
        padding:16px 54px;
        font-size:13px; line-height:16px; letter-spacing:0.65px;
        z-index:5;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(20px + 22 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(md) {
          padding:19px 44px;
          font-size:15px; line-height:17px; letter-spacing:0.75px;
        }
        @include media-breakpoint-up(xl) {
          margin-left:49px;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:42px;
        }

        &:before {
          border:3px solid color(main);
        }

        &.btn-white {
          &:before {
            border:3px solid color(white);
          }
        }
      }
    }
    .image {
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      background-position:center top; background-repeat:no-repeat; background-size:cover;

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        background:rgb(0,0,0);
        background:linear-gradient(104deg, rgba(0,0,0,1) 40%, rgba(0,0,0,0.2) 140%);
        opacity:0.7;
        backdrop-filter:blur(5px);
      }
    }
  }

  .swiper-pagination-wrapper {
    bottom:22px;

    .swiper-pagination-bullet {
      color:color(white);

      &:before {
        @include media-breakpoint-down(lg) {
          border:1px solid color(white);
        }
      }
    }
  }
}

.related-teaser-slider {
  position:relative;
  float:left;
  width:calc(100% + 26px);
  margin-left:-13px; margin-right:-13px;
  margin-top:30px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(30px + 5 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:35px;
  }

  .swiper {
    overflow:visible;
  }
  .swiper-slide {
    padding-left:13px; padding-right:13px;
  }
}
