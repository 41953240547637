.footer {
  display:block; float:left;
  width:100%;
  padding-top:24px; padding-bottom:10px;
  padding-left:8px; padding-right:8px;
  background-color:color(white);

  @include media-breakpoint-up(xs-375) {
    padding-top:calc(24px + 106 * ((100vw - 375px) / 1065)); padding-bottom:calc(10px + 8 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    padding-top:130px; padding-bottom:18px;
  }

  .row {
    margin-top:15px;

    &:first-child {
      margin-top:0;
    }

    &.separator {
      position:relative;
      margin-top:30px; padding-top:20px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(30px + 7 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:37px;
      }

      &:before {
        content:'';
        position:absolute; left:50%; top:0;
        width:calc(100% - 24px); height:1px;
        background-color:color(main);
        opacity:0.1;
        @include prefix(transform, translateX(-50%), webkit moz o ms);
      }
    }
  }

  .footer-logo {
    display:block; float:left;
    width:100%;
    margin-bottom:7px;
    text-align:center; font-size:0;

    @include media-breakpoint-up(lg) {
      margin-bottom:0;
    }

    picture {
      display:inline-block; float:none;
      height:68px; width:auto;

      img {
        height:100%; width:auto;
      }

      @include media-breakpoint-up(xl) {
        float:left;
        width:98px; height:auto;

        img {
          width:100%; height:auto;
        }
      }
    }
  }

  .footer-block {
    float:left;
    width:100%;
    margin-top:22px;
    text-align:center;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(22px + 16 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(lg) {
      text-align:left;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:38px;
    }

    &:first-child {
      @include media-breakpoint-up(lg) {
        margin-top:0;
      }
    }

    h6, .h6 {
      display:flex; flex-direction:row; justify-content:center; align-items:center;

      @include media-breakpoint-up(lg) {
        justify-content:flex-start;
      }

      button {
        position:relative;
        display:block;
        width:30px; height:30px;
        margin:-5px -9px -5px 0; padding:0;
        border:0 none;
        background-color:transparent;

        &:before {
          content:$icon-ibsa-plus;
          position:absolute; left:50%; top:50%;
          width:12px; height:12px;
          font-size:12px; line-height:12px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
        }

        @include media-breakpoint-up(lg) {
          display:none!important;
        }
      }
      a {
        padding:0 9px;
        color:color(main);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          padding:0;

          &:hover {
            color:color(main-alt);
          }
        }
      }
    }

    p {
      margin-top:8px;
      font-size:12px; line-height:22px; letter-spacing:0.65px; font-weight:600;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(8px + 10 * ((100vw - 375px) / 1065));
        font-size:calc(12px + 1 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:18px;
        font-size:13px;
      }

      a {
        @include media-breakpoint-up(lg) {
          text-decoration:none!important; font-weight:500; color:color(main)
        }

        &.icon {
          position:relative;
          padding-left:23px;

          i {
            position:absolute; left:0; top:2px;
            width:16px; height:16px;
            font-size:16px; line-height:16px;
          }
        }
      }
    }
    .btn {
      padding:10px 44px; margin-top:18px; margin-bottom:16px;
      font-size:10px; line-height:13px; letter-spacing:0.8px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(18px + 8 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(lg) {
        margin-bottom:0;
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:26px;
      }
    }
    .footer-menu {
      display:none; float:left;
      width:100%;

      @include media-breakpoint-up(lg) {
        display:block!important;
      }

      ul {
        margin-top:8px; margin-bottom:-10px;
        padding:0 15px;

        @include media-breakpoint-up(xs-360) {
          padding:0 calc(15px + 45 * ((100vw - 360px) / 15));
        }
        @include media-breakpoint-up(xs-375) {
          margin-top:calc(8px + 2 * ((100vw - 375px) / 1065));
          padding:0 60px;
        }
        @include media-breakpoint-up(lg) {
          padding:0; margin-bottom:0;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:10px;
        }

        li {
          border-bottom:1px solid color(main-section);

          &:last-child {
            border-bottom:0 none;
          }

          @include media-breakpoint-up(lg) {
            margin-top:8px;
            border-bottom:0 none;
          }

          a {
            display:block; float:left;
            width:100%;
            padding:11px 0;
            font-size:13px; line-height:22px; letter-spacing:0.39px; text-transform:uppercase; font-weight:500; color:color(main); text-align:center;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(lg) {
              width:auto;
              padding:0;
              font-size:11px; text-align:left; font-weight:600; letter-spacing:0.55px;

              &:hover {
                color:color(main-alt);
              }
            }
          }
        }
      }

      &.inline {
        @include media-breakpoint-up(lg) {
          ul {
            li {
              position:relative;
              width:auto;
              margin-right:12px; padding-right:12px;

              &:after {
                content:'';
                position:absolute; right:0; top:2px;
                width:1px; height:18px;
                background-color:color(main-alt);
                opacity:0.2;
              }
              a {
                font-size:13px; letter-spacing:0.39px; color:color(main-med)!important; text-transform:inherit;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                @include media-breakpoint-up(lg) {
                  &:hover {
                    text-decoration:underline!important;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.open {
      h6, .h6 {
        button {
          &:before {
            content:$icon-ibsa-minus;
          }
        }
      }
    }
  }
  .footer-social {
    display:flex; flex-direction:row; align-items:center; justify-content:center;
    margin-top:19px;

    @include media-breakpoint-up(lg) {
      justify-content:flex-start;
    }

    h6, .h6 {
      width:auto;
      margin-right:10px;

      @include media-breakpoint-up(xs-375) {
        margin-right:calc(12px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-right:16px;
      }
    }
    ul {
      width:auto;

      li {
        width:auto;
        margin-left:16px;

        @include media-breakpoint-up(xs-375) {
          margin-left:calc(20px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-left:28px;
        }

        a {
          position:relative;
          display:block; float:left;
          width:28px; height:28px;

          i {
            position:absolute; left:50%; top:50%;
            width:18px; height:18px;
            font-size:18px; line-height:18px; color:color(main-med);
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              i {
                color:color(main);
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    margin-top:8px;
    font-size:10px; line-height:22px; color:color(main-alt); letter-spacing:0.5px; font-weight:600; text-align:center;

    @include media-breakpoint-up(lg) {
      margin-top:0;
      text-align:left; font-size:11px;
    }
  }

  .bottom-link {
    display:block; float:left;
    width:100%;

    ul {
      display:flex; flex-direction:row; justify-content:center; align-items:flex-start; flex-wrap:wrap;

      li {
        width:auto;
        margin:8px 9px 0 9px;

        @include media-breakpoint-up(xs-375) {
          margin:8px calc(9px + 2 * ((100vw - 375px) / 1065)) 0 calc(9px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin:8px 11px 0 11px;
        }

        a {
          display:block; float:left;
          width:auto;
          font-size:10px; line-height:22px; letter-spacing:1.35px; text-transform:uppercase; font-weight:700; color:color(main);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(lg) {
            font-size:9px;

            &:hover {
              color:color(main-alt);
            }
          }
        }
      }
    }
  }
}
