form {
  position:relative;
  display:block; float:left;
  width:100%;

  fieldset {
    position:relative;
    display:block; float:left;
    width:100%;
  }
}

.form-wrapper {
  float:left;
  width:100%;
  padding:26px 20px 32px 20px;
  background-color:color(grey-light60);

  @include media-breakpoint-up(lg) {
    padding:42px calc(((100% - 216px) / 10) + 24px) 50px calc(((100% - 216px) / 10) + 24px);
  }

  .form-field {
    margin-top:28px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(28px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:32px;
    }

    input, textarea {
      display:block; float:left;
      width:100%;
      border:0 none; border-radius:0;
      font-size:16px; line-height:26px; font-weight:700; color:color(main); letter-spacing:0.45px;
      background-color:transparent;

      &::-webkit-input-placeholder { color:color(main-med); font-weight:500; }
      &::-moz-placeholder { color:color(main-med); font-weight:500; }
      &:-ms-input-placeholder { color:color(main-med); font-weight:500; }
      &:-moz-placeholder { color:color(main-med); font-weight:500; }

      @include media-breakpoint-up(lg) {
        font-size:15px;
      }
    }
    input {
      padding:8px 12px; margin:4px 0;
      background-image:url('../images/form-underline.png'); background-position:left bottom; background-repeat:repeat-x; background-size:auto;
    }
    textarea {
      height:168px;
      padding:0 12px;
      line-height:42px;
      resize:none;
      background-image:url('../images/form-underline.png'); background-position:left -1px; background-repeat:repeat; background-size:auto;

      @include media-breakpoint-up(md) {
        height:126px;
      }
    }

    [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
      position:absolute; left:-9999px;
      padding:0; border:0 none;
      width:0; height:0;
    }
    [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
      position:relative;
      display:inline-block;
      padding-left:40px; margin:0;
      cursor:pointer;
      font-size:12px; line-height:21px; font-weight:500; color:color(main);

      a {
        color:color(main-med); font-weight:700;
        text-decoration:underline!important;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(main);
          }
        }
      }
    }
    [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
      content:'';
      position:absolute; left:0; top:0;
      width:21px; height:21px;
      border-radius:2px; border:1px solid color(main-light);
      background-color:color(white);
      @include prefix(transition, all .3s ease-out, webkit moz o ms);
    }
    [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
      content:$icon-ibsa-checked;
      position:absolute; left:9px; top:0;
      width:21px; height:21px;
      font-size:21px; line-height:21px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
      opacity:0;
      @include prefix(transition, all .15s ease-out, webkit moz o ms);
    }
    [type="checkbox"]:checked + label:after {
      opacity:1;
    }

    .select2-container {
      width:100% !important;

      &.select2-container--default {
        .select2-selection--single {
          height:50px;
          padding:12px 45px 12px 13px;
          border:0 none; border-radius:0;
          box-shadow:none;
          font-size:16px; line-height:26px; font-weight:700; color:color(main); letter-spacing:0.45px;
          background-color:color(main-lightest);

          @include media-breakpoint-up(lg) {
            font-size:15px;
          }

          .select2-selection__arrow {
            position:absolute; top:0; right:0;
            width:45px; height:50px;
            background:none;

            &:before {
              content:$icon-ibsa-chevron-down;
              position:absolute; left:50%; top:50%;
              width:13px; height:13px;
              font-size:13px; line-height:13px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
              @include prefix(transform, translate(-50%, -50%), webkit moz o ms);
            }
            b {
              display:none;
            }
          }
          .select2-selection__rendered {
            color:color(main);
            padding:0;
          }
        }
        &.select2-container--open .select2-selection--single .select2-selection__arrow:before {
          transform:translate(-50%, -50%) rotate(180deg);
        }
      }
      &.select2-container--open {
        .select2-dropdown--above {
          margin-top:0;
          border:0 none;
          background-color:color(main-lightest);
        }
        .select2-dropdown--below {
          margin-top:0;
          border:0 none;
          background-color:color(main-lightest);
        }
      }

      .select2-results {
        .select2-results__options {
          .select2-results__option {
            padding:12px 13px;
            font-size:14px; line-height:26px; font-weight:500; color:color(main); letter-spacing:0.45px;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            &.select2-results__option--selected {
              display:none;
            }
            &.select2-results__option--highlighted {
              background-color:color(main-light);
            }
          }
        }
      }
    }

    .upload {
      display:flex; flex-direction:row; align-items:center; justify-content:flex-start;
      float:left;
      width:auto;

      .btn {
        width:auto;
        padding:10px 44px;
        margin-right:18px; margin-bottom:0;
        font-size:10px; line-height:14px;
      }
      input {
        width:0; height:0;
        padding:0; margin:0;
        visibility:hidden;
      }
    }
    .upload-files {
      position:relative;
      display:none; float:left;
      width:100%;
      margin-bottom:12px; padding-left:34px;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(12px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:16px;
      }

      p {
        margin-bottom:0;
        font-size:14px; line-height:25px; font-weight:700; color:color(main); letter-spacing:0.45px; word-wrap:break-word;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(14px + 1 * ((100vw - 375px) / 1065)); line-height:calc(25px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:15px; line-height:27px;
        }
      }

      a {
        position:absolute; left:0; top:0;
        width:24px; height:24px;
        border-radius:50%; border:1px solid color(main-light);
        background-color:color(white60);

        i {
          position:absolute; left:50%; top:50%;
          width:8px; height:8px;
          font-size:8px; line-height:8px; color:color(main); font-weight:bold;
          @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
        }
      }

      &.fill {
        display:block;
      }
    }
  }
  .form-button {
    margin-top:32px;
    text-align:center; font-size:0;
  }

  label, p {
    display:block; float:left;
    width:100%;
    margin-bottom:8px;
    font-size:14px; line-height:24px; font-weight:700; color:color(main); letter-spacing:0.45px;

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(8px + 4 * ((100vw - 375px) / 1065));
      font-size:calc(14px + 1 * ((100vw - 375px) / 1065)); line-height:calc(24px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:12px;
      font-size:15px; line-height:26px;
    }

    &.note {
      width:auto;
      margin-bottom:0;
      font-size:11px; line-height:18px; font-weight:500;

      @include media-breakpoint-up(xs-375) {
        font-size:calc(11px + 1 * ((100vw - 375px) / 1065)); line-height:calc(18px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:12px; line-height:20px;
      }
    }
  }

  .captcha-wrapper {
    img {
      width:auto;
    }
  }
}
