.pillars-wrapper {
  position:relative;
  display:flex; justify-content:center; align-items:center;
  float:left;
  width:100%;
  margin-top:30px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(30px + 6 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:36px;
  }

  ul {
    position:absolute; left:0; top:50%;
    width:calc(100% + 40px); height:100%;
    margin-left:-20px; margin-right:-20px;
    @include prefix(transform, translateY(-50%), webkit moz o ms);
    z-index:2;

    @include media-breakpoint-up(lg) {
      width:100%; height:100%;
      margin-left:0; margin-right:0;
    }
    @include media-breakpoint-up(xl-1440) {
      height:72%;
      margin-top:-12px;
    }

    li {
      position:absolute;
      width:48.2%;
      padding:14px;
      background-color:color(white);

      @include media-breakpoint-up(xs-375) {
        padding:16px;
      }
      @include media-breakpoint-up(lg) {
        width:230px;
        padding:0;
        background-color:transparent;
      }

      &:before {
        content:'';
        position:absolute; left:14px; bottom:14px;
        width:calc(100% - 28px); height:2px;
        background-color:color(main-light);
        z-index:1;

        @include media-breakpoint-up(xs-375) {
          left:16px;
          width:calc(100% - 36px);
        }
        @include media-breakpoint-up(lg) {
          left:0; bottom:0;
          width:100%;
          height:3px;
        }
      }

      &:nth-child(1) {
        left:0; top:0;

        @include media-breakpoint-between(xs-375,lg) {
          padding-left:20px; margin-right:1.8%;

          &:before {
            left:20px;
          }
        }
      }
      &:nth-child(2) {
        right:0; top:0;

        @include media-breakpoint-between(xs-375,lg) {
          padding-right:20px; margin-left:1.8%;

          &:before {
            right:20px;
          }
        }
      }
      &:nth-child(3) {
        left:0; bottom:0;

        @include media-breakpoint-between(xs-375,lg) {
          padding-left:20px; margin-right:1.8%;

          &:before {
            left:20px;
          }
        }
      }
      &:nth-child(4) {
        right:0; bottom:0;

        @include media-breakpoint-between(xs-375,lg) {
          padding-right:20px; margin-left:1.8%;

          &:before {
            right:20px;
          }
        }
      }

      a {
        position:relative;
        display:block; float:left;
        width:100%;
        padding:4px 45px 4px 0;
        font-size:12px; line-height:20px; color:color(main); letter-spacing:0.46px; font-weight:600;
        z-index:2;

        @include media-breakpoint-up(xs-375) {
          font-size:14px;
        }
        @include media-breakpoint-up(xs-395) {
          font-size:16px;
        }
        @include media-breakpoint-up(lg) {
          padding:15px 45px 15px 0;
          font-size:23px; line-height:28px; color:color(main); letter-spacing:0.46px; font-weight:600;
        }

        &:before {
          content:'';
          position:absolute; left:0; bottom:0;
          width:0%; height:3px;
          background-color:color(main-med);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }
        &:after {
          content:$icon-ibsa-arrow-right;
          position:absolute; right:0; top:7px;
          width:12px; height:12px;
          font-size:12px; line-height:12px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;

          @include media-breakpoint-up(xs-395) {
            width:16px; height:16px;
            font-size:16px; line-height:16px;
          }
          @include media-breakpoint-up(lg) {
            top:21px;
            width:17px; height:17px;
            font-size:17px; line-height:17px;
          }
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            &:before {
              width:100%;
            }
          }
        }
      }
    }
  }
  .image {
    position:relative;
    width:100%; height:auto;
    padding:80px 0;
    z-index:1;

    img {
      width:100%; height:auto;
    }

    @include media-breakpoint-up(lg) {
      width:auto; height:478px;
      padding:0;

      img {
        width:auto; height:100%;
      }
    }
  }

  &.internal {
    margin-top:80px; margin-bottom:50px;

    @include media-breakpoint-up(lg) {
      margin-bottom:40px;
    }

    ul {
      top:-50px;
      height:calc(100% + 100px);
      @include prefix(transform, translateY(0%), webkit moz o ms);

      @include media-breakpoint-up(lg) {
        height:calc(100% + 90px);
      }
      @include media-breakpoint-up(xl-1440) {
        height:calc(100% + 90px);
        margin-top:0;
      }

      li {
        background-color:color(grey-light60);

        @include media-breakpoint-up(lg) {
          width:calc(278px + 100 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          width:378px;
        }

        &:before {
          content:none;
        }

        &:nth-child(1) {
          a {
            @include media-breakpoint-up(lg) {
              padding-left:calc(24px + 14 * ((100vw - 992px) / 448)); padding-right:calc(52px + 60 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(xl-1440) {
              padding-left:38px; padding-right:112px;
            }
          }
        }
        &:nth-child(2) {
          a {
            @include media-breakpoint-up(lg) {
              padding-left:calc(52px + 60 * ((100vw - 992px) / 448)); padding-right:calc(24px + 14 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(xl-1440) {
              padding-left:112px; padding-right:38px;
            }
          }
        }
        &:nth-child(3) {
          a {
            @include media-breakpoint-up(lg) {
              padding-left:calc(24px + 14 * ((100vw - 992px) / 448)); padding-right:calc(52px + 60 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(xl-1440) {
              padding-left:38px; padding-right:112px;
            }
          }
        }
        &:nth-child(4) {
          a {
            @include media-breakpoint-up(lg) {
              padding-left:calc(52px + 60 * ((100vw - 992px) / 448)); padding-right:calc(24px + 14 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(xl-1440) {
              padding-left:112px; padding-right:38px;
            }
          }
        }

        a {
          padding:5px 0;

          @include media-breakpoint-up(xs-375) {
            padding-top:calc(5px + 10 * ((100vw - 375px) / 617)); padding-bottom:calc(5px + 50 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(lg) {
            padding-top:15px;
          }
          @include media-breakpoint-up(xl-1440) {
            padding-bottom:55px;
          }

          h5, .h5 {
            position:relative;
            padding-right:38px;
            margin-bottom:8px;

            @include media-breakpoint-up(xs-375) {
              margin-bottom:calc(8px + 7 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-bottom:15px;
            }

            &:after {
              content:$icon-ibsa-arrow-right;
              position:absolute; right:0; top:4px;
              width:12px; height:12px;
              font-size:12px; line-height:12px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;

              @include media-breakpoint-up(xs-375) {
                top:calc(2px + 4 * ((100vw - 375px) / 1065));
                width:calc(16px + 1 * ((100vw - 375px) / 1065)); height:calc(16px + 1 * ((100vw - 375px) / 1065));
                font-size:calc(16px + 1 * ((100vw - 375px) / 1065)); line-height:calc(16px + 1 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                top:6px;
                width:17px; height:17px;
                font-size:17px; line-height:17px;
              }
            }
          }
          p {
            position:relative;
            padding-top:10px;
            font-size:10px; line-height:16px; letter-spacing:0; font-weight:500; color:color(main);

            @include media-breakpoint-up(xs-375) {
              padding-top:calc(10px + 5 * ((100vw - 375px) / 1065));
              font-size:calc(10px + 4 * ((100vw - 375px) / 1065)); line-height:calc(16px + 7 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              padding-top:15px;
              font-size:14px; line-height:23px;
            }

            &:before {
              content:'';
              position:absolute; left:0; top:0;
              width:100%; height:2px;
              background-color:color(main-light);
              z-index:1;

              @include media-breakpoint-up(lg) {
                height:3px;
              }
            }
            &:after {
              content:'';
              position:absolute; left:0; top:0;
              width:0%; height:2px;
              background-color:color(main-med);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
              z-index:2;

              @include media-breakpoint-up(lg) {
                height:3px;
              }
            }
          }

          &:before {
            content:none;
          }
          &:after {
            content:none;
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              p {
                &:after {
                  width:100%;
                }
              }
            }
          }
        }
      }
    }
    .image {
      padding:80px 0;

      @include media-breakpoint-up(lg) {
        height:520px;
        padding:0;
      }
    }
  }
}
