.pagination-wrapper {
  display:block; float:left;
  width:100%;
  margin-top:32px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(32px + 24 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:56px;
  }

  ul {
    display:flex; flex-direction:row; align-items:flex-end; justify-content:center;
    font-size:0;

    li {
      width:auto;

      a, span {
        display:block; float:left;
        width:auto;
        padding:0 9px;
        font-size:12px; line-height:20px; font-weight:500; letter-spacing:0.96px; color:color(main);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }
      span {
        font-size:20px; line-height:24px; font-weight:800; letter-spacing:1.6px;
      }
      
      &:first-child, &:last-child {
        a {
          font-size:16px; line-height:16px;

          i {
            font-size:16px; line-height:16px;
          }
        }
      }
      &:first-child {
        margin-right:12px;

        @include media-breakpoint-up(xs-375) {
          margin-right:calc(12px + 18 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-right:30px;
        }
      }
      &:last-child {
        margin-left:12px;

        @include media-breakpoint-up(xs-375) {
          margin-left:calc(12px + 18 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-left:30px;
        }
      }
      &.disabled {
        opacity:0.2;

        a {
          cursor:default;
          pointer-events:none;
        }
      }
    }
  }
}
