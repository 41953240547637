.video-teaser-wrapper {
  position:relative;
  margin-top:-30px;
  z-index:1!important;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(-30px - 40 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-70px;
  }

  > div {
    margin-top:30px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(30px + 40 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:70px;
    }
  }
}

.video-teaser {
  display:block; float:left;
  width:100%;

  > a, > div {
    display:block; float:left;
    width:100%;

    .video-16x9, .video-4x3 {
      position:relative;
      float:left;
      width:100%;

      iframe {
        position:absolute; left:0; top:0;
        width:100%; height:100%;
      }
    }
    .video-4x3 {
      padding-bottom:75%;
    }
    .video-16x9 {
      padding-bottom:56.25%;
    }
    .content {
      display:flex; flex-direction:column; align-items:flex-start;
      float:left;
      width:100%;
      margin-top:16px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(16px + 14 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:30px;
      }

      > div {
        float:left;
        width:100%;
      }

      p {
        margin-top:5px;
        font-size:14px; line-height:24px; letter-spacing:0.51px; font-weight:500;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(5px + 4 * ((100vw - 375px) / 1065));
          font-size:calc(14px + 3 * ((100vw - 375px) / 1065)); line-height:calc(24px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:9px;
          font-size:17px; line-height:28px;
        }
      }
      a {
        margin-top:9px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(9px + 3 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:12px;
        }
      }
    }
  }

  &.thumb {
    text-align:center;
    font-size:0;

    > a, > div {
      display:inline-block; float:none;
      width:80%; max-width:382px;

      @include media-breakpoint-up(sm) {
        max-width:242px;
      }
    }
  }
}

.team-teaser-wrapper {
  position:relative;
  margin-top:-30px;
  z-index:1!important;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(-30px - 40 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-70px;
  }

  > div {
    margin-top:30px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(30px + 40 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:70px;
    }
  }
}

.team-teaser {
  display:block; float:left;
  width:100%;

  @include media-breakpoint-up(lg) {
    padding-right:calc(0px + 55 * ((100vw - 992px) / 448));
  }
  @include media-breakpoint-up(xl-1440) {
    padding-right:55px;
  }

  > a, > div {
    display:block; float:left;
    width:100%;

    @include media-breakpoint-up(md) {
    }

    .image {
      position:relative;
      float:left;
      width:100%;
      margin-bottom:0; padding-right:33.33%;

      @include media-breakpoint-up(md) {
        padding-right:0;
      }
    }
    .content {
      display:flex; flex-direction:column; align-items:flex-start;
      float:left;
      width:100%;
      margin-top:16px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(16px + 14 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:30px;
      }

      > div {
        float:left;
        width:100%;
      }

      p {
        margin-top:5px;
        font-size:14px; line-height:24px; letter-spacing:0.51px; font-weight:500;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(5px + 4 * ((100vw - 375px) / 1065));
          font-size:calc(14px + 3 * ((100vw - 375px) / 1065)); line-height:calc(24px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:9px;
          font-size:17px; line-height:28px;
        }
      }
      a {
        margin-top:9px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(9px + 3 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:12px;
        }

        &.btn {
          padding:16px 32px;
          margin-top:18px;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(18px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(md) {
            padding:16px calc(32px + 12 * ((100vw - 768px) / 672));
          }
          @include media-breakpoint-up(xl-1440) {
            padding:16px 44px;
            margin-top:26px;
          }
        }
      }
    }
  }

  &.highlight {
    padding-right:0;

    > a, > div {
      .image {
        padding-right:0;

        @include media-breakpoint-up(md) {
          width:60%;
          padding-right:12px;
        }
      }
      .content {
        margin-top:16px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(16px + 39 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(md) {
          width:40%;
          padding-left:12px;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:55px;
        }
      }
    }
  }

  &.main {
    position:relative;
    display:flex; align-items:flex-end;
    padding:0;
    background:rgb(241,243,255);
    background:linear-gradient(90deg, rgba(241,243,255,1) 0%, rgba(255,255,255,1) 82.4%);
    @include prefix(box-shadow, 0px 4px 40px 0px rgba(0,0,0,0.07), webkit moz o ms);

    .image {
      position:absolute; right:0; bottom:0;
      width:60%; max-width:165px;
      flex-shrink:0;

      /*@include media-breakpoint-up(xs-395) {
        //width:44.6%;
      }*/
      @include media-breakpoint-up(lg) {
        position:relative; right:inherit; bottom:inherit;
        width:44%; max-width:none;
        margin-top:-18px;
      }
      @include media-breakpoint-up(xl) {
        width:34%;
      }
    }
    .content {
      display:flex; flex-direction:column-reverse;
      width:100%;
      padding:24px 36px 32px 16px;
      flex-shrink:0;

      @include media-breakpoint-up(xs-375) {
        padding:calc(24px + 4 * ((100vw - 375px) / 1065)) 36px calc(32px + 20 * ((100vw - 375px) / 1065)) calc(16px + 16 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(md) {
        flex-direction:column;
      }
      @include media-breakpoint-up(lg) {
        width:56%;
      }
      @include media-breakpoint-up(xl) {
        width:66%;
      }
      @include media-breakpoint-up(xl-1440) {
        padding:28px 36px 52px 32px;
      }

      .head {
        display:flex; align-items:flex-start; flex-wrap:wrap; flex-direction:column;
        width:100%;

        @include media-breakpoint-up(sm) {
          padding-left:38px;
        }
        @include media-breakpoint-up(md) {
          align-items:flex-end; flex-direction:row;
          padding-left:0;
        }

        h4, .h4 {
          font-size:15px; line-height:23px; font-weight:700; letter-spacing:0.09px;

          @include media-breakpoint-up(xs-375) {
            font-size:calc(15px + 3 * ((100vw - 375px) / 1065)); line-height:calc(23px + 5 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:18px; line-height:28px;
          }
        }
        p {
          width:auto;
          margin-top:2px; padding-right:100px;
          font-size:13px; line-height:23px; font-weight:500; letter-spacing:0.09px;

          @include media-breakpoint-up(xs-375) {
            font-size:calc(13px + 2 * ((100vw - 375px) / 1065)); line-height:calc(23px + 3 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xs-395) {
            padding-right:140px;
          }
          @include media-breakpoint-up(sm) {
            padding-right:180px;
          }
          @include media-breakpoint-up(md) {
            padding-right:20px;
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:15px; line-height:26px;
          }
        }
        .btn-link {
          margin-top:20px;

          @include media-breakpoint-up(md) {
            margin-left:auto; margin-top:0;
          }
        }
      }
      blockquote {
        position:relative;
        display:block; float:left;
        width:100%;
        margin:0;
        margin-bottom:25px;
        padding-left:38px;
        font-size:14px; line-height:24px; font-weight:400; letter-spacing:0.054px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(14px + 4 * ((100vw - 375px) / 1065)); line-height:calc(24px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(md) {
          width:76.6%;
          margin-top:calc(25px + 20 * ((100vw - 768px) / 672)); padding-left:calc(38px + 20 * ((100vw - 768px) / 672)); margin-bottom:0;
        }
        @include media-breakpoint-up(lg) {
          margin-left:10.2%; margin-right:5.2%;
        }
        @include media-breakpoint-up(xl) {
          margin-left:14.2%; margin-right:9.2%;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:45px; padding-left:58px;
          font-size:18px; line-height:32px;
        }

        &:before {
          content:'';
          position:absolute; left:0; top:5px;
          width:22px; height:20px;
          background-image:url('../images/quote.svg');

          @include media-breakpoint-up(xs-375) {
            width:calc(22px + 10 * ((100vw - 375px) / 1065)); height:calc(20px + 9 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            width:32px; height:29px;
          }
        }
      }
    }
  }
}

.team-teaser-list {
  display:flex; justify-content:center; flex-wrap:wrap;
  width:calc(100% + 16px);
  margin-left:-8px; margin-right:-8px;
  margin-top:-32px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(-32px - 26 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-58px;
  }

  > div:not(.swiper-wrapper) {
    width:100%;
    margin-top:32px;
    padding-left:8px; padding-right:8px;

    @include media-breakpoint-up(xs-360) {
      width:50%;
    }
    @include media-breakpoint-up(xs-375) {
      margin-top:calc(32px + 26 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(md) {
      width:33.33%;
    }
    @include media-breakpoint-up(lg) {
      width:25%;
    }
    @include media-breakpoint-up(xl) {
      width:20%;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:58px;
    }
  }
  .team-teaser {
    padding:0;

    .image {
      position:relative;
      float:left;
      width:100%;

      &:has(.team-video) {
        &:before {
          content:'';
          position:absolute; left:0; bottom:0;
          width:100%; height:74px;
          background:rgb(0,47,108);
          background:linear-gradient(180deg, rgba(0,47,108,0) 0%, rgba(0,47,108,0.9) 100%);
          z-index:1;
        }
      }
      .team-video {
        position:absolute; left:4px; bottom:12px;
        width:auto; max-width:calc(100% - 8px);
        padding:5px 0 5px 38px;
        font-size:11px; line-height:20px; letter-spacing:0.06px; font-weight:700; color:color(white); text-transform:uppercase;
        z-index:2;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(11px + 1 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:12px;
        }

        &:before {
          content:''; position:absolute; left:0; top:0;
          width:30px; height:30px;
          background-color:color(white);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
          z-index:1;
        }
        &:after {
          content:$icon-ibsa-play;
          position:absolute; left:10px; top:10px;
          width:10px; height:10px;
          margin-left:1px;
          font-size:10px; line-height:10px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          z-index:2;
        }
        @include media-breakpoint-up(lg) {
          &:hover {
            &:before {
              background-color:color(main-lightest)!important;
            }
          }
        }
      }
    }
    .content {
      margin-top:12px;
      text-align:center; font-size:0;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(12px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:16px;
      }

      h4, .h4 {
        font-size:14px; line-height:22px; font-weight:700; letter-spacing:0.09px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(14px + 4 * ((100vw - 375px) / 1065)); line-height:calc(22px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:18px; line-height:28px;
        }
      }
      p {
        font-size:13px; line-height:20px; font-weight:500; letter-spacing:0.09px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(13px + 2 * ((100vw - 375px) / 1065)); line-height:calc(20px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:15px; line-height:26px;
        }
      }
      a {
        display:inline-block; float:none;
        width:auto;
        margin:10px 11px 0 11px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(10px + 6 * ((100vw - 375px) / 1065)) 11px 0 11px;
        }
        @include media-breakpoint-up(xl-1440) {
          margin:16px 11px 0 11px;
        }

        i {
          width:18px; height:18px;
          font-size:18px; line-height:18px; color:color(main);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            i {
              color:color(main-med)!important;
            }
          }
        }
      }
    }
  }

  &.wide {
    > div:not(.swiper-wrapper) {
      width:100%;

      @include media-breakpoint-up(xs-360) {
        width:50%;
      }
      @include media-breakpoint-up(lg) {
        width:33.33%;
      }
      @include media-breakpoint-up(xl) {
        width:25%;
      }
    }
  }
  &.swiper {
    margin-top:0;

    .swiper-slide {
      padding-left:8px; padding-right:8px;
    }
  }

  .swiper-scrollbar {
    position:relative;
    width:56%; height:4px;
    margin:26px 22% 0 22%!important; padding:0!important;
    background-color:transparent;

    &:before {
      content:'';
      position:absolute; left:0; top:50%;
      width:100%; height:2px;
      border-radius:0;
      background-color:color(grey);
      @include prefix(transform, translateY(-50%), webkit moz o ms);
      z-index:1;
    }
    .swiper-scrollbar-drag {
      border-radius:0;
      background:color(main-med);
      opacity:0.3;
      z-index:2;
    }

    @include media-breakpoint-up(md) {
      width:76%;
      margin:36px 22% 0 22%!important;
    }
  }
}

.box-teaser-wrapper {
  margin-top:-30px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(-30px - 20 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-50px;
  }

  > div {
    > .row {
      > div {
        margin-top:30px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(30px + 20 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:50px;
        }
      }
    }
  }

  .box-teaser {
    display:block; float:left;
    width:100%;

    a {
      display:flex; flex-direction:column;

      .content {
        float:left;
        width:100%;
        padding:20px 28px 20px 20px;
        background-color:color(main-lightest);

        @include media-breakpoint-up(xs-375) {
          padding:calc(20px + 14 * ((100vw - 375px) / 1065)) calc(28px + 44 * ((100vw - 375px) / 1065)) calc(20px + 14 * ((100vw - 375px) / 1065)) calc(20px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:34px 72px 34px 24px;
        }

        h4, .h4 {
          position:relative;
          padding-right:22px;
          line-height:26px; font-weight:600;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            padding-right:calc(22px + 12 * ((100vw - 375px) / 1065));
            line-height:calc(26px + 6 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding-right:34px;
            line-height:32px;
          }

          &:after {
            content:$icon-ibsa-arrow-right;
            position:absolute; right:0; top:8px;
            width:10px; height:10px;
            font-size:10px; line-height:10px; color:color(main-med); font-family:'icomoon'!important; font-weight:normal; font-style:normal;

            @include media-breakpoint-up(xs-375) {
              top:calc(8px + 1 * ((100vw - 375px) / 1065));
              width:calc(10px + 4 * ((100vw - 375px) / 1065)); height:calc(10px + 4 * ((100vw - 375px) / 1065));
              font-size:calc(10px + 4 * ((100vw - 375px) / 1065)); line-height:calc(10px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              top:9px;
              width:14px; height:14px;
              font-size:14px; line-height:14px;
            }
          }
        }
      }
      .image {
        float:left;
        width:100%;
        padding-bottom:56.35%;
        background-position:center; background-repeat:no-repeat; background-size:cover;
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          .content {
            h4, .h4 {
              @include prefix(transform, translateX(10px), webkit moz o ms);
            }
          }
        }
      }
    }

    &.high {
      a {
        flex-direction:row-reverse;

        .content {
          width:50%;
          padding:20px;

          @include media-breakpoint-up(xs-375) {
            padding:calc(20px + 14 * ((100vw - 375px) / 1065)) calc(20px + 16 * ((100vw - 375px) / 1065)) calc(20px + 14 * ((100vw - 375px) / 1065)) calc(20px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding:34px 36px 34px 24px;
          }

          h4, .h4 {
            padding-right:0; padding-bottom:22px;

            @include media-breakpoint-up(xs-375) {
              padding-bottom:calc(22px + 22 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              padding-bottom:44px;
            }

            &:after {
              content:$icon-ibsa-arrow-right;
              position:absolute; right:6px; top:inherit; bottom:0;

              @include media-breakpoint-up(xs-375) {
                right:calc(6px + 30 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                right:36px;
              }
            }
          }
        }
        .image {
          width:50%;
          padding-bottom:75%;
        }
      }
    }
  }
}

.download-teaser-wrapper {
  margin-top:-24px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(-24px - 14 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-38px;
  }

  > div {
    > .row {
      > div {
        margin-top:24px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(24px + 14 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:38px;
        }
      }
    }
  }

  .download-teaser {
    display:block; float:left;
    width:100%;

    a {
      display:flex; flex-direction:row; align-items:flex-start; justify-content:flex-start; float:left;
      width:100%;

      .icon {
        position:relative;
        float:left;
        width:104px; height:126px;
        background-color:color(main-med);
        flex-shrink:0;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          width:calc(104px + 44 * ((100vw - 375px) / 1065)); height:calc(126px + 64 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          width:148px; height:190px;
        }

        i {
          position:absolute; left:50%; top:50%;
          width:52px; height:52px;
          font-size:52px; line-height:52px; color:color(white);
          @include prefix(transform, translate(-50%,-50%), webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            width:calc(52px + 20 * ((100vw - 375px) / 1065)); height:calc(52px + 20 * ((100vw - 375px) / 1065));
            font-size:calc(52px + 20 * ((100vw - 375px) / 1065)); line-height:calc(52px + 20 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            width:72px; height:72px;
            font-size:72px; line-height:72px;
          }
        }
      }
      .content {
        float:left;
        width:100%;
        padding:16px;

        @include media-breakpoint-up(xs-375) {
          padding:calc(16px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:24px;
        }

        p {
          margin-top:5px;
          font-size:12px; line-height:24px; letter-spacing:0.42px; font-weight:500;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(5px + 4 * ((100vw - 375px) / 1065));
            font-size:calc(12px + 2 * ((100vw - 375px) / 1065)); line-height:calc(24px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:9px;
            font-size:14px; line-height:28px;
          }
        }
        span {
          margin-top:12px;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(12px + 12 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:24px;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          .icon {
            background-color:color(main);
          }
        }
      }
    }
  }
}

.area-teaser-wrapper {
  position:relative;
  margin-top:-24px;
  z-index:1;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(-24px - 20 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-44px;
  }

  > div {
    margin-top:24px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(24px + 20 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:44px;
    }
  }
}

.area-teaser {
  display:block; float:left;
  width:100%; height:100%;
  background-color:color(grey-light);

  > a {
    display:block; float:left;
    width:100%; height:100%;

    .image {
      position:relative;
      display:block; float:left;
      width:100%;

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        z-index:1;
        opacity:0.5;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }
    }
    .content {
      float:left;
      width:100%;
      padding:14px 18px 18px 18px;

      @include media-breakpoint-up(xs-375) {
        padding:calc(14px + 2 * ((100vw - 375px) / 1065)) calc(18px + 47 * ((100vw - 375px) / 1065)) calc(18px + 3 * ((100vw - 375px) / 1065)) calc(18px + 3 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding:16px 65px 21px 21px;
      }

      span {
        position:relative;
        display:block; float:left;
        width:100%;
        padding:0 35px 5px 0;
        border-bottom:2px solid color(main-light);
        font-size:16px; line-height:21px; font-weight:600; letter-spacing:0.4px; color:color(main);

        @include media-breakpoint-up(xs-375) {
          padding:0 calc(35px + 10 * ((100vw - 375px) / 1065)) calc(5px + 2 * ((100vw - 375px) / 1065)) 0;
          border-bottom:calc(2px + 1 * ((100vw - 375px) / 1065)) solid color(main-light);
          font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(21px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:0 45px 7px 0;
          border-bottom:3px solid color(main-light);
          font-size:20px; line-height:25px;
        }
        &:before {
          content:$icon-ibsa-arrow-right;
          position:absolute; right:0; top:3px;
          width:15px; height:15px;
          font-size:15px; line-height:15px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            top:calc(3px + 1 * ((100vw - 375px) / 1065));
            width:calc(15px + 2 * ((100vw - 375px) / 1065)); height:calc(15px + 2 * ((100vw - 375px) / 1065));
            font-size:calc(15px + 2 * ((100vw - 375px) / 1065)); line-height:calc(15px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            top:4px;
            width:17px; height:17px;
            font-size:17px; line-height:17px;
          }
        }
        &:after {
          content:'';
          position:absolute; left:0; bottom:-2px;
          width:0%; height:2px;
          background-color:color(main);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            bottom:calc(-2px - 1 * ((100vw - 375px) / 1065));
            height:calc(2px + 1 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            bottom:-3px;
            height:3px;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        .image {
          &:before {
            opacity:0.8;
          }
        }
        .content {
          span {
            &:after {
              width:100%;
            }
          }
        }
      }
    }
  }

  &.v2 {
    background-color:color(white);

    > a {
      .image {
        &:before {
          content:none;
        }
      }
      .content {
        float:left;
        width:100%; min-height:82px;
        padding:20px 18px;
        border:2px solid color(azure); border-top:0 none;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          min-height:calc(82px + 16 * ((100vw - 375px) / 1065));
          padding:calc(20px + 4 * ((100vw - 375px) / 1065)) calc(18px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          min-height:98px;
          padding:24px 20px;
        }
        span {
          width:100%;
          padding-bottom:0; border:0 none;
          letter-spacing:0.6px;

          &:before {
            opacity:0;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }
          &:after {
            bottom:-10px;
            width:0;
            background-color:color(azure);

            @include media-breakpoint-up(xs-375) {
              bottom:calc(-10px - 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              bottom:-12px;
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          .content {
            span {
              &:before {
                opacity:1;
              }
              &:after {
                width:100%;
              }
            }
          }
        }
      }
    }
  }
}

.product-teaser {
  display:block; float:left;
  width:100%; height:100%;

  > a {
    display:block; float:left;
    width:100%; height:100%;

    .image {
      position:relative;
      display:block; float:left;
      width:100%;

      &:before {
        content:none;
      }
    }
    .content {
      float:left;
      width:100%; min-height:82px;
      padding:20px 18px;
      background-color:color(grey-light);

      @include media-breakpoint-up(xs-375) {
        min-height:calc(82px + 16 * ((100vw - 375px) / 1065));
        padding:calc(20px + 4 * ((100vw - 375px) / 1065)) calc(18px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        min-height:98px;
        padding:24px 20px;
      }

      span {
        position:relative;
        display:block; float:left;
        width:100%;
        padding-right:35px;
        font-size:16px; line-height:21px; font-weight:600; letter-spacing:0.6px; color:color(main);

        @include media-breakpoint-up(xs-375) {
          padding-right:calc(35px + 10 * ((100vw - 375px) / 1065));
          font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(21px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-right:45px;
          font-size:20px; line-height:25px;
        }

        &:before {
          content:$icon-ibsa-arrow-right;
          position:absolute; right:0; top:3px;
          width:15px; height:15px;
          opacity:0;
          font-size:15px; line-height:15px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            top:calc(3px + 1 * ((100vw - 375px) / 1065));
            width:calc(15px + 2 * ((100vw - 375px) / 1065)); height:calc(15px + 2 * ((100vw - 375px) / 1065));
            font-size:calc(15px + 2 * ((100vw - 375px) / 1065)); line-height:calc(15px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            top:4px;
            width:17px; height:17px;
            font-size:17px; line-height:17px;
          }
        }
        &:after {
          content:'';
          position:absolute; left:0; bottom:-10px;
          width:0; height:3px;
          background-color:color(azure);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            bottom:calc(-10px - 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            bottom:-12px;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        .content {
          span {
            &:before {
              opacity:1;
            }
            &:after {
              width:100%;
            }
          }
        }
      }
    }
  }
}

.inline-teaser-wrapper {
  margin-top:-26px;

  > div {
    margin-top:26px;

    .inline-teaser {
      display:block; float:left;
      width:100%;

      a {
        display:flex; flex-direction:column;
        width:100%;

        @include media-breakpoint-up(md) {
          flex-direction:row;
        }
      }

      .image {
        float:left;
        width:100%;
        flex-shrink:0;

        @include media-breakpoint-up(md) {
          width:34%;
        }
      }
      .content {
        float:left;
        width:100%;
        padding-top:16px;

        @include media-breakpoint-up(xs-375) {
          padding-top:calc(16px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(md) {
          padding-left:calc(20px + 4 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-top:24px; padding-left:24px;
        }

        p {
          margin-top:5px;
          font-size:14px; line-height:24px; letter-spacing:0.28px;
        }
        .btn-link {
          margin-top:12px;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(12px + 3 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:15px;
          }
        }
      }
    }
  }
}

.product-box-wrapper {
  margin-top:-26px;

  > div {
    margin-top:26px;
    
    .product-box {
      display:block; float:left;
      width:100%; height:100%;

      > a, > div {
        display:block; float:left;
        width:100%; height:100%;
        background-color:color(grey-light);

        .image {
          display:flex; justify-content:center; align-items:center;
          float:left;
          width:100%;
          padding:29px;

          img {
            height:210px; width:auto;
          }
        }
        .content {
          position:relative;
          float:left;
          width:100%;
          padding:16px 20px 26px 20px;
          text-align:center;

          @include media-breakpoint-up(xs-375) {
            padding:calc(16px + 12 * ((100vw - 375px) / 1065)) calc(20px + 4 * ((100vw - 375px) / 1065)) calc(26px + 16 * ((100vw - 375px) / 1065)) calc(20px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding:28px 24px 42px 24px;
          }

          > div {
            float:left;
            width:100%;
          }

          h3, .h3 {
            text-align:left;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }
          p {
            margin-top:12px;
            line-height:22px; letter-spacing:0.02em; text-align:left;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(12px + 3 * ((100vw - 375px) / 1065));
              line-height:calc(22px + 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:15px;
              line-height:24px;
            }

            strong {
              font-weight:600;
            }
          }
          ul {
            li {
              position:relative;
              padding-left:28px;
              margin-top:22px;

              @include media-breakpoint-up(xs-375) {
                padding-left:calc(28px + 4 * ((100vw - 375px) / 1065));
                margin-top:calc(22px + 2 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                padding-left:32px;
                margin-top:24px;
              }
              &:before {
                content:$icon-ibsa-checked;
                position:absolute; left:0; top:4px;
                width:15px; height:15px;
                font-size:15px; line-height:15px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
              }

              p {
                margin-top:0;
              }
            }
          }
          .btn-link {
            display:inline-block; float:none;
            margin-top:18px;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(18px + 10 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:28px;
            }
          }

          &.line {
            > div {
              position:relative;

              &:before {
                content:'';
                position:absolute; left:-20px; bottom:0;
                width:2px; height:calc(100% + 16px);
                background-color:transparent;

                @include media-breakpoint-up(xs-375) {
                  left:calc(-20px - 4 * ((100vw - 375px) / 1065));
                  height:calc(100% + (16px + 12 * ((100vw - 375px) / 1065)));
                }
                @include media-breakpoint-up(xl-1440) {
                  left:-24px;
                }
              }
            }
          }
        }
        @include media-breakpoint-up(lg) {
          &:hover {
            .content {
              h3, .h3 {
                color:color(main-alt);
              }
            }
          }
        }
      }
    }
  }
}

.related-teaser {
  display:block; float:left;
  width:100%; height:100%;

  a {
    display:block; float:left;
    width:100%; height:100%;

    .image {
      float:left;
      width:100%;
    }
    .content {
      position:relative;
      float:left;
      width:100%;
      padding-top:16px;

      @include media-breakpoint-up(xs-375) {
        padding-top:calc(16px + 12 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding-top:28px;
      }

      > div {
        float:left;
        width:100%;
      }

      p {
        margin-top:12px;
        font-size:14px; line-height:24px; letter-spacing:0.28px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(12px + 3 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:15px;
        }
      }
      .btn-link {
        margin-top:18px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(18px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:26px;
        }
      }
    }
  }
}

.links-box {
  position:relative;
  display:flex; flex-direction:column; float:left;
  width:100%;

  @include media-breakpoint-up(md) {
    flex-direction:row; align-items:center;
  }

  .image {
    position:relative;
    float:left;
    width:100%;
    padding-bottom:56.25%;
    background-position:center; background-repeat:no-repeat; background-size:cover;
    flex-shrink:0;
    z-index:3;

    @include media-breakpoint-up(md) {
      width:28.5%; height:100%; min-height:308px;
      padding-bottom:0;
    }

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      background-color:color(black);
      opacity:0.16;
    }
  }
  .content {
    position:relative;
    float:left;
    width:100%;
    padding:24px 20px;
    z-index:2;

    @include media-breakpoint-up(xs-375) {
      padding:calc(24px + 10 * ((100vw - 375px) / 1065)) calc(20px + 92 * ((100vw - 375px) / 1065)) calc(24px + 10 * ((100vw - 375px) / 1065)) calc(20px + 44 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding:34px 112px 34px 64px;
    }

    ul {
      margin-top:4px;

      li {
        padding:7px 0;
        border-bottom:1px solid color(grey-light);

        @include media-breakpoint-up(xs-375) {
          padding:calc(7px + 6 * ((100vw - 375px) / 1065)) 0;
        }
        @include media-breakpoint-up(xl-1440) {
          padding:13px 0;
        }

        &:last-child {
          border-bottom:0 none;
        }

        a {
          position:relative;
          display:block; float:left;
          width:100%;
          padding:5px 36px 5px 0;
          font-size:14px; line-height:22px; font-weight:600; letter-spacing:0.28px; color:color(main);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          &:after {
            content:$icon-ibsa-arrow-right;
            position:absolute; right:3px; top:6px;
            width:13px; height:13px;
            font-size:13px; line-height:13px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          }

          @include media-breakpoint-up(lg) {
            padding:5px calc(36px + 26 * ((100vw - 992px) / 448)) 5px 0;

            &:after {
              right:calc(3px + 16 * ((100vw - 992px) / 448));
            }
          }
          @include media-breakpoint-up(xl-1440) {
            padding:5px 62px 5px 0;

            &:after {
              right:19px;
            }
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              padding:5px 26px 5px 10px;

              @include media-breakpoint-up(lg) {
                padding:5px calc(26px + 24 * ((100vw - 992px) / 448)) 5px calc(10px + 2 * ((100vw - 992px) / 448));
              }
              @include media-breakpoint-up(xl-1440) {
                padding:5px 50px 5px 12px;
              }
            }
          }
        }
      }
    }
  }
  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    border:2px solid color(azure);
    z-index:1;
  }
}
