/* Prefixes */

@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
}

/* --- */

$font_montserrat: 'Montserrat', sans-serif;
// Regular: 400 | Medium: 500 | Semi-bold: 600 | Bold: 700

.font_montserrat { font-family:$font_montserrat!important; }
.font-regular { font-weight:400!important; }
.font-medium { font-weight:500!important; }
.font-semibold { font-weight:600!important; }
.font-bold { font-weight:700!important; }

html, body {
  color:color(main);
  font-family:$font_montserrat; font-weight:500;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  display:block; float:left;
  width:100%;
  margin-bottom:0;
  color:color(main); letter-spacing:0;
  font-family:$font_montserrat; font-weight:600;
}

h1, .h1 {
  font-size:34px; line-height:40px; font-weight:600; letter-spacing:0.68px; color:color(white);

  @include media-breakpoint-up(xs-375) {
    font-size:calc(34px + 18 * ((100vw - 375px) / 1065)); line-height:calc(40px + 20 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(lg) {
    letter-spacing:1.04px;
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:52px; line-height:60px;
  }
}
h2, .h2 {
  font-size:26px; line-height:35px; letter-spacing:1.14px;

  @include media-breakpoint-up(xs-375) {
    font-size:calc(26px + 12 * ((100vw - 375px) / 1065)); line-height:calc(35px + 15 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:38px; line-height:50px;
  }
}
h3, .h3 {
  font-size:18px; line-height:25px; letter-spacing:0.48px;

  @include media-breakpoint-up(xs-375) {
    font-size:calc(18px + 6 * ((100vw - 375px) / 1065)); line-height:calc(25px + 7 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:24px; line-height:32px;
  }
}
h4, .h4 {
  font-size:15px; line-height:23px; font-weight:500; letter-spacing:0.51px;

  @include media-breakpoint-up(xs-375) {
    font-size:calc(15px + 3 * ((100vw - 375px) / 1065)); line-height:calc(23px + 5 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:18px; line-height:28px;
  }
}
h5, .h5 {
  font-size:14px; line-height:20px; font-weight:600;

  @include media-breakpoint-up(xs-375) {
    font-size:calc(14px + 9 * ((100vw - 375px) / 1065)); line-height:calc(20px + 8 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:23px; line-height:28px;
  }
}
h6, .h6 {
  font-size:16px; line-height:20px; letter-spacing:0.54px;

  @include media-breakpoint-up(xs-375) {
    font-size:calc(16px + 2 * ((100vw - 375px) / 1065)); line-height:calc(20px + 2 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:18px; line-height:22px;
  }
}

p {
  display:block; float:left;
  width:100%;
  margin-bottom:0;
  font-size:14px; line-height:24px; letter-spacing:0.45px; font-weight:500; color:color(main);

  @include media-breakpoint-up(xs-375) {
    font-size:calc(14px + 1 * ((100vw - 375px) / 1065)); line-height:calc(24px + 2 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:15px; line-height:26px;
  }

  strong {
    font-weight:700;
  }
  a {
    color:color(main-med); font-weight:700;
    text-decoration:underline!important;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    @include media-breakpoint-up(lg) {
      &:hover {
        color:color(main);
      }
    }

    &.phone {
      position:relative;
      padding-left:23px;

      &:before {
        content:$icon-ibsa-phone;
        position:absolute; left:0; top:4px;
        width:16px; height:16px;
        font-size:16px; line-height:16px; color:color(main); font-family:'icomoon'!important; font-weight:normal; font-style:normal;

        @include media-breakpoint-up(xs-375) {
          top:calc(4px + 1 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          top:5px;
        }
      }

      @include media-breakpoint-up(lg) {
        color:color(main); font-weight:500;
        text-decoration:none!important;
      }
    }
  }
}
a {
  &.phone {
    @include media-breakpoint-up(lg) {
      cursor:default;
      pointer-events:none;
    }
  }
}
